import axios from 'axios';

const token = localStorage.getItem('accessToken');
let ticker = ""

if ( localStorage.getItem('instrumentTicker') === "" || localStorage.getItem('instrumentTicker') === null ) {
    ticker = "DNEEUR"
} else {
    ticker = localStorage.getItem('instrumentTicker')
}

const apiPay = axios.create({
    baseURL: process.env.VUE_APP_PAY_API,
    headers: { 'Authorization': `bearer ${token}`, 'Instrument-Ticker': ticker, 'X-Client-Identifier': "DnePay" }
});

export default apiPay;