<template>
    <div></div>
    <!-- <div class="banner-full" v-if="dataSource.image">
        <div class="container-fluid">
            <a :href="dataSource.link" :target="dataSource.target">
                <img :src="`/images/banners/${dataSource.image}`">
            </a>
        </div>
    </div> -->
</template>


<script>
    export default{
        props: {
            dataSource: {}
        },

        methods: {
            
        }
    }
</script>

<style lang="scss" scoped>
    .banner-full{
        @media (max-width: 576px){
            margin-bottom: 1rem;
        }

        @media (min-width: 576px){
            margin-bottom: 2rem;
        }
        
        img{
            width: 100%;
        }
    }
</style>