const $ = require('jquery')
window.$ = $

export default {
    data(){
        return {
            errorForm: {
                // companyProfile: {},
                // merchantProfile: {},
                // nationalityProfile: {},
                contactProfile01: {},
                // contactProfile02: {},
                // dockProfile: {},
                // acceptsInvite: {
                //     acceptTerms: false,
                //     acceptNotify: false,
                //     acceptGdpr: false
                // },

                company: {
                    legal_name: false,
                    fantasy_name: false,
                    type: false,
                    industry: false,
                    asset_id: false,
                    language_id: false,
                    tax_number: false,
                    country_id: false,
                    phone_number: false,
                    address: {
                        address_1: false,
                        number: false,
                        postal_code: false,
                        state: false,
                        city: false,
                    }
                },

                merchant: {
                    full_name: false,
                    nationality_id: false,
                    type: false,
                    dob: false,
                    gender_id: false,
                    tax_number: false,
                    asset_id: false,
                    language_id: false,
                    country_id: false,
                    phone_number: false,
                    address: {
                        address_1: false,
                        number: false,
                        postal_code: false,
                        state: false,
                        city: false,
                    }
                },

                store: {
                    name: false,
                    slug: false,
                },

                addAddress: {
                    country_id: false,
                    state: false,
                    city: false,
                    address_1: false,
                    number: false,
                    address_2: false,
                    postal_code: false
                },

                staff: {},



                // seller: false,
                contact01: false,
                contact02: false,
                dock: false,
                accepts: false,

            },

            validateEmail: {
                company: {
                    regex: '',
                    status: '',
                    val: ''
                },

                person: {
                    regex: '',
                    status: '',
                    val: ''
                },

                staff: {
                    regex: '',
                    status: '',
                    val: ''
                }
            },
            
            validatePhone: {
                company: {
                    regex: '',
                    status: '',
                    val: ''
                },
                
                seller: {
                    regex: '',
                    status: '',
                    val: ''
                },

                contact01: {
                    regex: '',
                    status: '',
                    val: ''
                },

                contact02: {
                    regex: '',
                    status: '',
                    val: ''
                },

                dock: {
                    regex: '',
                    status: '',
                    val: ''
                },

                staff: {
                    regex: '',
                    status: '',
                    val: ''
                }
            }
        }
    },

    methods: {
        
        // VALIDATE FORM
        validateForm(value){
            this.flowSave.load = true

            if ( value == "layout" ){
                if ( !this.dataMerchant.store.name ){ this.errorForm.store.name = true } else { this.errorForm.company.fantasy_name = false }
                if ( !this.dataMerchant.store.slug ){ this.errorForm.store.slug = true } else { this.errorForm.company.fantasy_slug = false }

                if ( !this.errorForm.store.name && !this.errorForm.store.slug ){
                    this.flowSave.load = false
                    this.flowSave.modalConfirm = true
                } else {
                    this.flowSave.load = false

                    setTimeout(() => {
                        this.scrollToFirstError();
                    }, 600);
                }
            }
        },


        // VALIDADE STAFF
        validateStaff(value){
            this.staff.flowStaff.load = true

            if ( !this.staff.user.full_name ){ this.errorForm.staff.full_name = true } else { this.errorForm.staff.full_name = false }
            if ( !this.staff.user.email ){ this.errorForm.staff.email = true } else { this.errorForm.staff.email = false }
            if ( !this.staff.user.merchant_type_id ){ this.errorForm.staff.merchant_type_id = true } else { this.errorForm.staff.merchant_type_id = false }
            if ( !this.staff.user.country_id ){ this.errorForm.staff.country_id = true } else { this.errorForm.staff.country_id = false }
            if ( !this.staff.user.phone_country_id ){ this.errorForm.staff.phone_country_id = true } else { this.errorForm.staff.phone_country_id = false }
            if ( !this.staff.user.phone_number ){ this.errorForm.staff.phone_number = true } else { this.errorForm.staff.phone_number = false }

            console.log(this.errorForm.staff)
            console.log(this.validateEmail.staff.regex)

            if ( !this.errorForm.staff.full_name && !this.errorForm.staff.email && !this.errorForm.staff.merchant_type_id && !this.errorForm.staff.country_id && !this.errorForm.staff.phone_country_id && !this.errorForm.staff.phone_number && this.validateEmail.staff.regex && !this.errorForm.staff.countryId ){
                this.saveStaff(value)
            } else {
                setTimeout(() => {
                    this.staff.flowStaff.load = false
                    this.scrollToFirstError();
                }, 600);
            }
        },


        // VALIDATE NEW ADDRESS
        validateNewAddress(){
            if ( !this.flowAddressAdd.address.country_id ){ this.errorForm.addAddress.country_id = true } else { this.errorForm.addAddress.country_id = false }
            if ( !this.flowAddressAdd.address.state ){ this.errorForm.addAddress.state = true } else { this.errorForm.addAddress.state = false }
            if ( !this.flowAddressAdd.address.city ){ this.errorForm.addAddress.city = true } else { this.errorForm.addAddress.city = false }
            if ( !this.flowAddressAdd.address.address_1 ){ this.errorForm.addAddress.address_1 = true } else { this.errorForm.addAddress.address_1 = false }
            if ( !this.flowAddressAdd.address.number ){ this.errorForm.addAddress.number = true } else { this.errorForm.addAddress.number = false }
            if ( !this.flowAddressAdd.address.postal_code ){ this.errorForm.addAddress.postal_code = true } else { this.errorForm.addAddress.postal_code = false }

            if ( !this.errorForm.addAddress.country_id && !this.errorForm.addAddress.state && !this.errorForm.addAddress.city && !this.errorForm.addAddress.address_1 && !this.errorForm.addAddress.number && !this.errorForm.addAddress.postal_code ){
                this.saveAddress()                
            }
        },


        // SCROLL TO FIRST ERROR
        scrollToFirstError() {
            const errorField = document.querySelector('.form.error');
            if (errorField) {
              const HeaderPage = document.querySelector('header');
              const headerHeight = HeaderPage.offsetHeight;
        
              const errorFieldPosition = errorField.getBoundingClientRect().top;
              const scrollPosition = window.scrollY + errorFieldPosition - headerHeight - 50;
        
              window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
            }
        },


        // VALIDATE REGEX
        regexEmail(emailCheck){
            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if ( emailCheck === "company"){
                if (regex.test(this.validateEmail.company.val)) {
                    this.validateEmail.company.regex = true
                } else{
                    this.validateEmail.company.regex = false
                }

            } else if ( emailCheck === "person"){
                if (regex.test(this.validateEmail.person.val)) {
                    this.validateEmail.person.regex = true
                } else{
                    this.validateEmail.person.regex = false
                }

            } else if ( emailCheck === "staff"){
                if (regex.test(this.staff.user.email)) {
                    this.validateEmail.staff.regex = true
                } else{
                    this.validateEmail.staff.regex = false
                }
            }            
        },


        // REGEX PHONE
        regexPhone(phoneCheck){
            const regex = /^\+[1-9]\d{8,14}$/;

            if ( phoneCheck === "company"){
                let phoneE164Company = this.selectedCountryDDICompany.ddi + this.validatePhone.company.val
                phoneE164Company = "+" + phoneE164Company.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Company)) {
                    this.validatePhone.company.regex = true
                } else{
                    this.validatePhone.company.regex = false
                }

            } else if ( phoneCheck === "seller"){
                let phoneE164Seller = this.selectedCountryDDISeller.ddi + this.validatePhone.seller.val
                phoneE164Seller = "+" + phoneE164Seller.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Seller)) {
                    this.validatePhone.seller.regex = true
                } else{
                    this.validatePhone.seller.regex = false
                }

            } else if ( phoneCheck === "contact01"){
                let phoneE164Contact01 = this.selectedCountryDDIContact.ddi + this.newAddress.phoneNumber
                phoneE164Contact01 = "+" + phoneE164Contact01.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Contact01)) {
                    this.validatePhone.contact01.regex = true
                } else{
                    this.validatePhone.contact01.regex = false
                }

            } else if ( phoneCheck === "contact02"){
                let phoneE164Contact02 = this.selectedCountryDDIContact.ddi + this.newAddress.phoneNumberSecondary
                phoneE164Contact02 = "+" + phoneE164Contact02.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Contact02)) {
                    this.validatePhone.contact02.regex = true
                } else{
                    this.validatePhone.contact02.regex = false
                }

            } else if ( phoneCheck === "dock"){
                let phoneE164Dock = this.selectedCountryDDIDock.ddi + this.validatePhone.dock.val
                phoneE164Dock = "+" + phoneE164Dock.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Dock)) {
                    this.validatePhone.dock.regex = true
                } else{
                    this.validatePhone.dock.regex = false
                }

            } else if ( phoneCheck === "staff"){
                // let phoneE164Staff = this.selectedCountryDDIStaff.ddi + this.staff.user.phone_number
                let phoneE164Staff = this.validatePhone.staff.val
                phoneE164Staff = "+" + phoneE164Staff.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Staff)) {
                    this.validatePhone.staff.regex = true
                    this.staff.user.phone_number = this.validatePhone.staff.val
                } else{
                    this.validatePhone.staff.regex = false
                }
            }
        },

        
        // CHECK EMAIL
        checkEmail(emailCheck){
            if ( emailCheck === "company" ){
                if ( this.validateEmail.company.val === this.dataMerchant.companyProfile.email ){
                    this.validateEmail.company.status = true
                } else{
                    this.validateEmail.company.status = false
                }
            } else if ( emailCheck === "person" ){
                if ( this.validateEmail.person.val === this.dataMerchant.email ){
                    this.validateEmail.person.status = true
                } else{
                    this.validateEmail.person.status = false
                }
                
            } else if ( emailCheck === "staff" ){
                if ( this.validateEmail.staff.val === this.staff.user.email ){
                    this.validateEmail.staff.status = true
                } else{
                    this.validateEmail.staff.status = false
                }
            }
        },


        // validationFormInvite(){

        //     // VALIDATION COMPLIANCE
        //     if ( this.dataMerchant.AccountType === "company_invite" ){

        //         // COMPANY NAME
        //         if ( this.dataMerchant.companyProfile.fullName != "" ){
        //             var companyName = this.dataMerchant.companyProfile.fullName
        //             companyName = companyName.trim().split(' ').length
                    
        //             if ( companyName < 2 ){
        //                 this.errorForm.companyProfile.fullName = true
        //             } else {
        //                 this.errorForm.companyProfile.fullName = false
        //             }
        //         } else {
        //             this.errorForm.companyProfile.fullName = true
        //         }


        //         // COMPANY EMAIL
        //         if ( this.dataMerchant.companyProfile.email === "" ){
        //             this.errorForm.companyProfile.email = true
        //         } else {
        //             this.errorForm.companyProfile.email = false
        //         }


        //         // COMPANY LANGUAGE
        //         if ( this.dataMerchant.companyProfile.languageId === "" ){
        //             this.errorForm.companyProfile.languageId = true
        //         } else {
        //             this.errorForm.companyProfile.languageId = false
        //         }


        //         // COMPANY COUNTRY
        //         if ( this.dataMerchant.companyProfile.countryId === "" ){
        //             this.errorForm.companyProfile.countryId = true
        //         } else {
        //             this.errorForm.companyProfile.countryId = false
        //         }


        //         // COMPANY PHONE
        //         if ( this.validatePhone.company.val === "" ){
        //             this.errorForm.companyProfile.phoneNumber = true
        //         } else if ( this.validatePhone.company.val.length < this.selectedCountryDDICompany.phoneMask.length ) {
        //             this.errorForm.companyProfile.phoneNumber = true
        //         } else {
        //             this.errorForm.companyProfile.phoneNumber = false
        //         }

        //         // COMPANY TAXNUMBER
        //         if ( this.dataMerchant.companyProfile.taxNumber ==="" ){
        //             this.errorForm.companyProfile.taxNumber = true
        //         } else {
        //             this.errorForm.companyProfile.taxNumber = false
        //         }


        //         // CKECK VALIDATION COMPLIANCE
        //         if ( this.errorForm.companyProfile.fullName || this.errorForm.companyProfile.email || this.errorForm.companyProfile.languageId || this.errorForm.companyProfile.countryId || this.errorForm.companyProfile.phoneNumber || this.errorForm.companyProfile.taxNumber ){
        //             window.location.href = "#!company"
        //             this.errorForm.company = true
        //         } else {
        //             this.errorForm.company = false
        //         }
        //     }



            
        //     // PERSON NAME
        //     if ( this.dataMerchant.fullName != "" ){
        //         var personName = this.dataMerchant.fullName
        //         personName = personName.trim().split(' ').length
                
        //         if ( personName < 2 ){
        //             this.errorForm.merchantProfile.fullName = true
        //         } else {
        //             this.errorForm.merchantProfile.fullName = false
        //         }
        //     } else {
        //         this.errorForm.merchantProfile.fullName = true
        //     }


        //     // EMAIL
        //     if ( this.dataMerchant.email === "" ){
        //         this.errorForm.merchantProfile.email = true
        //     } else {
        //         this.errorForm.merchantProfile.email = false
        //     }


        //     // LANGUAGE
        //     if ( this.dataMerchant.languageId === "" ){
        //         this.errorForm.merchantProfile.languageId = true
        //     } else {
        //         this.errorForm.merchantProfile.languageId = false
        //     }


        //     // COUNTRY
        //     if ( this.dataMerchant.countryId === "" ){
        //         this.errorForm.merchantProfile.countryId = true
        //     } else {
        //         this.errorForm.merchantProfile.countryId = false
        //     }


        //     // PHONE
        //     if ( this.validatePhone.person.val === "" ){
        //         this.errorForm.merchantProfile.phoneNumber = true
        //     } else if ( this.validatePhone.person.val.length < this.selectedCountryDDI.phoneMask.length ) {
        //         this.errorForm.merchantProfile.phoneNumber = true
        //     } else {
        //         this.errorForm.merchantProfile.phoneNumber = false
        //     }


        //     // ACCEPTS
        //     if ( !this.accept.terms ){
        //         this.errorForm.acceptsInvite.acceptTerms = true
        //     } else{
        //         this.errorForm.acceptsInvite.acceptTerms = false
        //     }
            
        //     if ( !this.accept.notify ){
        //         this.errorForm.acceptsInvite.acceptNotify = true
        //     } else{
        //         this.errorForm.acceptsInvite.acceptNotify = false
        //     }
            
        //     if ( !this.accept.gdpr ){
        //         this.errorForm.acceptsInvite.acceptGdpr = true
        //     } else{
        //         this.errorForm.acceptsInvite.acceptGdpr = false
        //     }

        //     if ( this.errorForm.acceptsInvite.acceptTerms || this.errorForm.acceptsInvite.acceptNotify || this.errorForm.acceptsInvite.acceptGdpr ){
        //         this.errorForm.accepts = true
        //     } else {
        //         this.errorForm.accepts = false
        //     }


        //     // CKECK VALIDATION COMPLIANCE
        //     if ( !this.errorForm.company && (this.errorForm.merchantProfile.fullName || this.errorForm.merchantProfile.email || this.errorForm.merchantProfile.languageId || this.errorForm.merchantProfile.countryId || this.errorForm.merchantProfile.phoneNumber) ){
        //         window.location.href = "#!person"
        //         this.errorForm.seller = true
        //     } else {
        //         this.errorForm.seller = false
        //     }


        //     // LAST CKECK
        //     if ( !this.errorForm.company && !this.errorForm.accepts && !this.errorForm.seller ){
        //         // this.saveStep()
        //     }
        // },
    }
}