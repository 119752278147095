<template>
    <header class="header" :class="{develop: VUE_APP_DEVELOP == 'develop'}">
        <div class="container-fluid">
            <div class="col-logo">
                <a :href="`/${flagSelected}/`" class="logo">
                    <img src="@/assets/images/header/logo-dne-market.svg" alt="DNE Market" class="anime">
                </a>
            </div>

            <div class="col-header"
            :class='{search: dataSource == "search",
                menus: dataSource == "menus",
                categories: dataSource.type_code == "categories",
                headerAccount: dataSource == "account",
                headerMerchant: dataSource == "merchant"}'>

                <Search v-if="dataSource == 'search'" />
                <Menu :dataSource="dataSource" v-if="dataSource != 'search'" />
                <!-- <Categories :dataSource="dataSource.data" v-if='dataSource.type_code == "categories"' /> -->
            </div>
            
            <div class="col-tools">
                <BecomeSeller :dataSource="dataSourceHeader.isMerchant" :dataOrigin="origin" v-if="!dataSourceHeader.isMerchant" />
                <Login :dataSource="dataSourceHeader.isMerchant" />
                <Wishlist />
                <Minicart />
                
                <div><div class="openSidebar" @click="openSidebar('menu')"></div></div>

                <div class="openSettings" @click="openSidebar('settings')">
                    <div class="flag">
                        <div class="load-flag" v-if="!settings.flagShow"></div>
                        <img v-if="settings.flagShow" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${settings.flagShipping}/flags`">
                    </div>
                    
                    <small v-if="settings.languageName">
                        {{settings.languageName.slice(0, 3)}}<br>{{settings.currencyShort}}
                    </small>
                </div>
                
                <!-- <div class="openSidebar" v-if="dataSource.type_code == 'categories' || dataSource == 'menus'"></div> -->
            </div>
        </div>
    </header>


    <!-- SIDEBAR MENU -->
    <div class="sidebar" id="SidebarMarket" v-if="sidebar.menu">
        <div class="closeSidebar" @click="closeSidebar()"></div>
        <!-- <Categories :dataSource="dataSource.data" v-if="dataSource.type_code == 'categories'" /> -->
        <Sidebar :dataSource="dataSourceHeader" />
    </div>


    <!-- SIDEBAR SETTINGS -->
    <div class="sidebar" :class="{hide: sidebar.login}" v-if="sidebar.settings || sidebar.login">
        <div class="closeSidebar" @click="closeSidebar()"></div>
        <Settings />
    </div>
    <div class="overlay" v-if="sidebar.menu || sidebar.settings" @click="closeSidebar()"></div>
</template>


<script>
    const $ = require("jquery");
    window.$ = $;

    $(document).ready(function () {
        var animaHeader = $("body");
        $(window).scroll(function () {
            if ($(this).scrollTop() > 120) {
                animaHeader.addClass("headerFixed");
            } else {
                animaHeader.removeClass("headerFixed");
            }
        });
    });
   
    import Search from '@/components/Essentials/Search.vue';
    import Menu from '@/components/Essentials/Menu.vue';
    // import Categories from '@/components/Essentials/Categories.vue';
    import BecomeSeller from '@/components/Essentials/BecomeSeller.vue';
    import Wishlist from '@/components/Essentials/Wishlist.vue';
    import Minicart from '@/components/Essentials/Minicart.vue';
    import Login from '@/components/Essentials/Login.vue';
    import Sidebar from '@/components/Essentials/Sidebar.vue';
    import Settings from '@/components/Essentials/Settings.vue';

    import bus from '@/eventBus';

    export default {
        props: {
            dataSource: {}
        },

        components: {
            Search,
            Menu,
            // Categories,
            BecomeSeller,
            Wishlist,
            Minicart,
            Login,
            Sidebar,
            Settings
        },

        data (){
            return {
                flagSelected: "en",
                VUE_APP_DEVELOP: process.env.VUE_APP_DEVELOP,

                origin: "Header",

                sidebar: {
                    menu: false,
                    settings: false,
                    login: false
                },

                settings: {
                    flagShow: false,
                    flagShipping: "",
                    languageName: "",
                    currencyShort: ""
                },

                dataSourceHeader: {
                    isMerchant: false,
                    headerTemplate: ""
                },

                token: {
                    hasToken: "",
                    id: "",
                    permissions: ""
                }
            }
        },

        async mounted(){
            this.checkToken()

            this.dataSourceHeader.headerTemplate = this.dataSource

            // this.flagSelected = localStorage.getItem('codeLanguage')
            // this.settings.flagShow = false

            setTimeout(() => {
                this.checkSettings()

                if ( !localStorage.getItem("shippingFlag") ){
                    localStorage.setItem("shippingFlag","58bfc699-7e06-4362-3445-c598e9232900")
                } else {
                    this.settings.flagShipping = localStorage.getItem('shippingFlag')
                }                
            }, 1000);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
                this.settings.languageName = localStorage.getItem("Language")

                setTimeout(() => {
                    this.settings.flagShow = true
                }, 1500);
            },

            openSidebar(value, profile){
                if ( value == "menu" ){
                    this.sidebar.menu = true
                    document.body.classList.add('body-sidebar-opened')
                }

                if ( value == "settings" ){
                    this.sidebar.settings = true
                    document.body.classList.add('body-settings-opened')
                }

                if ( value == "login" ){
                    this.sidebar.login = true
                    
                    setTimeout(() => {
                        bus.emit('settings-profile',profile);
                    }, 100);
                }
            },
            
            closeSidebar(){
                this.sidebar.menu = false
                this.sidebar.settings = false
                this.sidebar.login = false
                
                document.body.classList.remove('body-sidebar-opened')
                document.body.classList.remove('body-settings-opened')
            },

            checkToken(){
                // this.dataSourceHeader.isMerchant = localStorage.getItem("isMerchant")
                this.dataSourceHeader.isMerchant = (localStorage.getItem("isMerchant") === "true");


                // this.token.hasToken = localStorage.getItem("accessToken")

                // if ( this.token.hasToken ){
                //     var base64Url = localStorage.getItem("accessToken").split('.')[1]
                //     var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
                //     var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                //         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                //     }).join(''));
    
                //     this.token = JSON.parse(jsonPayload)
    
                //     if ( this.token.permissions.filter(access => access == "dne:market:merchant").length ){
                //         this.dataSourceHeader.isMerchant = true
                //         localStorage.setItem("isMerchant",true)
                //     }
                // }
                
                // setTimeout(() => {
                //     this.show = true
                // }, 1000)
            },

            checkSettings(){
                setTimeout(() => {
                    this.settings.flagShipping = localStorage.getItem("shippingFlag")
                    this.settings.languageName = localStorage.getItem("Language")
                    this.settings.currencyShort = localStorage.getItem("instrumentTickerShort")
                    this.settings.flagShow = true
                }, 200);
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            bus.on('open-sidebar', (value) => {
                this.openSidebar(value.source,value)
            });

            bus.on('close-sidebar', () => {
                this.closeSidebar()
            });
        }
   }
</script>

<style src="@/assets/scss/_header.scss" lang="scss" scoped />