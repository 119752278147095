<template>
    <div class="account">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />

        
        <!-- CONTENT -->
        <section class="content-purchases">
            <div class="container-fluid">
                <h2 class="title-secondary">
                    {{getMyLanguage("Account","purchases.title")}}
                </h2>

                <div class="load" v-if="load"></div>

                <div class="row" v-if="errorsGet.dataPurchases != false">
                    <div class="col-12">
                        <div class="box box-error">
                            <h3 class="title-secondary">
                                {{getMyLanguage("box-error","title-error")}}
                            </h3>

                            <p v-if='errorsGet.dataPurchases != "401"'>
                                {{getMyLanguage("box-error","msg-error")}}
                            </p>

                            <p v-if='errorsGet.dataPurchases == "401"'>
                                {{getMyLanguage("box-error","401")}}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="!load && !errorsGet.dataPurchases && !dataPurchases.length">
                    <div class="col-12">
                        <div class="box box-pending">
                            <p>Faça já a sua primeira compra!</p>
                        </div>
                    </div>
                </div>

                <div class="row" data-aos="fade-up" data-aos-offset="-100" v-if="!load && !errorsGet.dataPurchases">
                    <div class="col-12">
                        <div class="purchases">

                            <div class="purchase"
                            v-for="(purchase, indexPurchase) in dataPurchases"
                            :key="indexPurchase"
                            :id="`purchase${indexPurchase}`">

                                <div class="order">
                                    <div class="header-print">
                                        <div class="logo"></div>

                                        <div class="content">
                                            <div class="dne-market">
                                                <p><strong>{{getMyLanguage("Account","purchases.purchase.company-name")}}</strong></p>
                                                <p>{{purchase.store.name}}</p>
                                            </div>

                                            <div class="shipping">
                                                <p>
                                                    <strong>{{getMyLanguage("Account","purchases.purchase.buyers-name")}}</strong>
                                                </p>

                                                <p>{{purchase.customer.full_name}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="header-purchase">
                                        <div class="reference">
                                            <small>{{getMyLanguage("Account","purchases.purchase.reference")}}</small>
                                            <strong>{{purchase.reference}}</strong>
                                        </div>

                                        <div class="date">
                                            <small>{{getMyLanguage("Account","purchases.purchase.purchase-date")}}</small>
                                            <strong>{{dateTimeFormat(purchase.created_at)}}</strong>
                                        </div>

                                        <div class="status" :class="purchase.status_extra" v-if="purchase.status_extra">
                                            <small>{{getMyLanguage("Account","purchases.purchase.status")}}</small>
                                            <strong>{{ getMyLanguage("Account","purchases.status." + purchase.status_extra) }}</strong>
                                        </div>

                                        <a :href="`/${purchase.store.slug}`" class="seller">
                                            <small>{{getMyLanguage("Account","purchases.purchase.sold-by")}}</small>
                                            <strong>{{purchase.store.name}}</strong>
                                        </a>
                                    </div>

                                    <div class="items">
                                        <a class="item" target="_blank"
                                        :href="`/${item.product.slug}`"
                                        v-for="(item, indexItem) in purchase.items"
                                        :key="indexItem">

                                            <div class="photo">
                                                <img :src="item.product.attachment">
                                            </div>

                                            <div class="details">
                                                <p class="name">
                                                    <strong>{{item.product.name}}</strong>
                                                    <span>
                                                        <small class="status" :class="item.status_extra">
                                                            {{ getMyLanguage("Account","purchases.status." + item.status_extra) }}
                                                        </small>

                                                        <small class="ico refund" v-if="item.refund"></small>
                                                        <small class="ico return" v-if="item.return"></small>
                                                    </span>
                                                </p>

                                                <div class="price">
                                                    <span>
                                                        <strong>{{item.price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong>
                                                        <i></i>
                                                    </span>

                                                    <small>{{(item.price * purchase.instrument.price).toLocaleString(tickerShort, {style:"currency", currency:purchase.instrument.asset_short})}}</small>
                                                </div>
                                                
                                                <small class="qtd" v-if="(item.quantity = 1)">{{item.quantity}} {{getMyLanguage("Account","purchases.purchase.item")}}</small>
                                                <small class="qtd" v-if="(item.quantity > 1)">{{item.quantity}} {{getMyLanguage("Account","purchases.purchase.items")}}</small>
                                            </div>
                                        </a>
                                    </div>
                                </div>


                                <div class="resume-purchase">
                                    <table class="box-summary">
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.products")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    <strong>{{purchase.summary.items_price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong>
                                                    <i></i>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.discount")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    <strong>{{purchase.summary.discount.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong>
                                                    <i></i>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.subtotal")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    <strong>{{purchase.summary.subtotal.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong>
                                                    <i></i>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.shipping")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    <strong>{{purchase.summary.shipping.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong>
                                                    <i></i>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.tax")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    <strong>{{purchase.summary.tax.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong>
                                                    <i></i>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.fee")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    <strong>{{purchase.summary.fee.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong>
                                                    <i></i>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr class="show">
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.total")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    <strong>{{purchase.summary.total_price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong>
                                                    <i></i>
                                                </span>

                                                <small>{{(purchase.summary.total_amount).toLocaleString(this.tickerShort, {style:"currency", currency:purchase.instrument.asset_short})}}</small>
                                            </td>
                                        </tr>
                                        <tr class="show">
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.cashback")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    <strong>{{purchase.summary.cashback.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</strong>
                                                    <i></i>
                                                </span>
                                            </td>
                                        </tr>
                                    </table>

                                    <div class="bts">
                                        <div class="btn-primary" @click="pdfPurchaseSingle(purchase.reference, indexPurchase)" v-html='getMyLanguage("Btn","download-pdf")'></div>

                                        <a :href="`/${flagSelected}/account/purchases/return/${purchase.Id}`" class="btn btn-return">
                                            <span>{{getMyLanguage("Account","purchases-returns.title")}}</span>
                                        </a>
                                        <!-- <div class="btn btn-cancel"><span>Cancelar</span></div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import Breadcrumb from "@/components/Account/Breadcrumb.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMarket from '@/config/apiMarket.js'

    import moment from 'moment';

    const html2pdf = require('html2pdf.js')

    export default {
        components: {
            Header,
            Breadcrumb
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "account",
                load: true,
                breadcrumb: [],

                dataPurchases: {},

                tickerShort: "EUR",

                errorsGet: {
                    dataPurchases: false
                }
            }
        },

        async mounted() {
            this.languageSelected()

            if ( localStorage.getItem("instrumentTickerShort") ){
                this.tickerShort = localStorage.getItem("instrumentTickerShort")
            } else {
                this.tickerShort = "EUR"
                localStorage.setItem("instrumentTickerShort","EUR")
            }
            

            await apiMarket.get('/api/v1/market/order/filter')
            .then(response => {
                this.dataPurchases = response.data
                
                setTimeout(() => {
                    this.load = false
                }, 500);
            })
            .catch(error => {
                this.errorsGet.dataPurchases = error.response.status

                setTimeout(() => {
                    this.load = false
                }, 500);

                if ( error.response.status == "401" ){
                    setTimeout(() => {
                        window.location.href = `/${this.flagSelected}/`
                    }, 2000);
                }
            })


            // BREADCRUMB
            const newArray = [
                {
                    slug: null,
                    name: this.getMyLanguage("Account","purchases.title")
                }
            ];

            this.breadcrumb = newArray;
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateFormat(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('DD/MM/YY');
            },

            timeFormat(value) {
                const formattedTime = moment.utc(value).format('HH:mm');
                return formattedTime;
            },

            dateTimeFormat(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                const dateTimeString = moment.utc(value).locale(format).format('DD/MM/YY HH:mm');
                const atTranslation = this.getMyLanguage("Account", "dateTimeFormat.at");
                return dateTimeString.replace(' ', ` ${atTranslation} `);
            },

            pdfPurchaseSingle (value, indexPurchase) {
                html2pdf(document.getElementById("purchase" + indexPurchase), {
                    filename: `Receipt ${value} DNE Market`,
                    margin: 15
                });
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            
            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
                document.title = `${this.getMyLanguage("Account","purchases.title")} | DNE Market`
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="./custom-purchases.scss" lang="scss" scoped />

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';

    .header-print{
        margin-bottom: 20px;
        
        .logo{
            width: 100%; height: 70px; margin-bottom: 10px; background: url('~@/assets/images/header/logo-dne-market.svg') no-repeat center; background-size: auto 100%;
        }
        
        .content{
            display: flex; justify-content: space-between; max-width: none; width: 100%; margin: 0;
            
            div{
                width: calc(50% - 10px); padding: 5px 10px; border: 1px solid #ccc; min-height: auto;
                
                p{
                    font-size: 12px; line-height: 18px;

                    &:first-child{
                        margin: -10px 0 0 0;
                    }
                    
                    &:last-child{
                        margin: 0 0 10px 0;
                    }
                }
            }
        }
    }

    .header-purchase{
        display: flex; justify-content: space-between; flex-wrap: wrap; border: 1px solid #ccc; padding: 5px 10px; margin-bottom: 30px;

        div, a{
            margin: -7px 0 13px 0;
        }

        small{
            display: block; margin-bottom: 5px; color: var(--color-text-primary); font-size: 11px; line-height: 11px;
        }

        strong{
            display: block; color: #666; font-size: 13px; line-height: 13px;
        }

        .seller{
            margin-bottom: 0;
            
            strong{
                font-family: var(--font-primary-semibold);
            }
        }
    }

    .items{
        .item{
            position: relative; z-index: 5; display: flex; padding: 5px 10px; border: 1px solid #ccc;
        
            // &:last-child{
            //     border-top: none;
            // }
            
            .photo{
                display: none;
            }

            .details{
                display: flex; flex-grow: 1; justify-content: space-between;
                
                .name{
                    color: var(--color-text-primary); font-family: var(--font-primary-semibold); font-size: 12px; max-width: 470px; margin: -10px 0 0 0; margin-right: 30px;

                    span{
                        display: block;
                        margin: -10px 0 10px 0;
                    }
                }

                .price{
                    display: flex; flex-direction: column; justify-content: center; width: auto;
                    
                    span{
                        display: flex; align-items: flex-start; justify-content: center; border-color: #ccc;
                        
                        strong{
                            font-family: var(--font-primary-semibold); color: var(--color-text-primary); font-size: 13px; line-height: 16px; margin-top: -10px;
                        }
                        
                        i{
                            display: block; width: 16px; height: 16px; background: url('~@/assets/images/icons/dne-symbol-pdf.png') no-repeat center right; background-size: auto 10px; margin-top: -1px;
                        }
                    }

                    small{
                        display: block; white-space: nowrap; width: auto; font-size: 11px; line-height: 13px; margin: -10px 0 10px 0;
                    }
                }

                .qtd{
                    white-space: nowrap; display: flex; align-items: center; justify-content: flex-end; width: 80px; margin: -10px 0 10px 0;
                }
            }
        }
    }
    
    .resume-purchase{
        margin-top: 40px; display: flex; justify-content: flex-end;
        
        .box-summary{
            width: calc(50% - 10px); max-width: 100%; padding: 6px; background: var(--color-text-tertiary); border: 1px solid #ccc; border-collapse: separate;

            tr{
                &:last-child td{border-bottom: none;}

                td{
                    border-bottom: 1px solid #ddd; padding: 5px 0;
                    
                    &:nth-of-type(2){
                        text-align: right;
                    }

                    strong{
                        display: block; padding-left: 5px; font-family: var(--font-primary-semibold); font-size: 12px; line-height: 14px; height: 14px; color: var(--color-text-primary); margin: -10px 0 10px 0;
                    }

                    span{
                        display: flex; padding-right: 5px;
                        
                        strong{
                            display: block; width: calc(100% - 14px); font-family: var(--font-primary-semibold); color: var(--color-text-primary); font-size: 12px; line-height: 14px; margin: -10px 0 10px 0;
                        }
                        
                        i{
                            display: block; width: 14px; height: 14px; background: url('~@/assets/images/icons/dne-symbol-pdf.png') no-repeat center right; background-size: auto 9px; margin-top: -1px;
                        }
                    }
                    
                    small{
                        display: block; font-size: 11px; line-height: 12px; padding-right: 19px;  margin: -10px 0 10px 0;
                    }
                }
            }
        }

        .bts{
            display: none;
        }
    }
</style>

<style>
    .bts .btn-primary small{display: block; margin-left: 10px; font-size: 12px;}
</style>