<template>
    <section class="mosaic-home">
        <div class="container-fluid">
            <!-- <h2 class="title-secondary">{{dataSource.placeholder_name}}</h2> -->
            <h2 class="title-secondary">{{getMyLanguage("MosaicHighlights","mosaic.title")}}</h2>

            <div class="row">
                <div class="col-6">
                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic full" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-01.jpg" class="banner-mosaic" alt='Tudo para o meu carro' />
                            
                            <figcaption>
                                <h2>Tudo para o meu carro</h2>
                            </figcaption>
                        </figure>
                    </a>
                </div>

                <div class="col-6 col-lg-3">
                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic high" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-02.jpg" class="banner-mosaic" alt='Trocar de telemóvel' />
                            
                            <figcaption>
                                <h2>Trocar de telemóvel</h2>
                            </figcaption>
                        </figure>
                    </a>

                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic low" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-03.jpg" class="banner-mosaic" alt='Viagens' />
                            
                            <figcaption>
                                <h2>Viagens</h2>
                            </figcaption>
                        </figure>
                    </a>
                </div>

                <div class="col-12 col-lg-3 d-flex d-lg-block">
                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic low" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-04.jpg" class="banner-mosaic" alt='Roupas de verão' />
                            
                            <figcaption>
                                <h2>Roupas de verão</h2>
                            </figcaption>
                        </figure>
                    </a>
                    
                    <a :href="`/${flagSelected}/store/1000047381/dnemarket`" class="mosaic high" data-aos="fade-up" data-aos-offset="-100">
                        <figure>
                            <img src="/images/mosaic/mosaic-05.jpg" class="banner-mosaic" alt='Manutenção e reparos' />
                            
                            <figcaption>
                                <h2>Manutenção e reparos</h2>
                            </figcaption>
                        </figure>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        props: {
            dataSource: {}
        },

        data (){
            return {
                flagSelected: "en",
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="@/assets/scss/_mosaic-home.scss" lang="scss" scoped />