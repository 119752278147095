<template>
    <div class="merchant page-store">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />
        
        <!-- CONTENT -->
        <section class="profile">
            <div class="load load-white load-200" v-if="load"></div>
            

            <!-- ERROR RESTRICTED -->
            <div class="container" v-if="!load && errorsGetGeneral.dataMerchant != false">
                <div class="box box-error" :class="{error403: errorsGetGeneral.dataMerchant == '403'}">
                    <h3 class="title-secondary">
                        {{getMyLanguage("box-error","title-error")}}
                    </h3>

                    <p v-if='errorsGetGeneral.dataMerchant == "401"'>
                        {{getMyLanguage("box-error","401")}}
                    </p>
                    
                    <div class="error-403" v-else-if='errorsGetGeneral.dataMerchant == "403"'>
                        <img src="~@/assets/images/icons/403.svg" alt="Error 403">

                        <p>
                            {{getMyLanguage("box-error","403")}}
                        </p>
                    </div>

                    <p v-else>
                        {{getMyLanguage("box-error","msg-error")}}
                    </p>
                </div>
            </div>

            
            <!-- BOX REGISTRATION -->
            <div class="container" v-if='!load && !errorsGetGeneral.dataMerchant'>
                
                <!-- SECTIONS -->
                <div class="sections">
                    <div class="cont">
                        <div class="btn-section manage-store-layout" :class="{active: sectionManage.btn === 'layout'}" @click='navSection("layout")'>
                            <small>{{getMyLanguage("Store","merchant.store.layout.guide-title")}}</small>
                        </div>

                        <div class="btn-section manage-store-staff" :class="{active: sectionManage.btn === 'staff'}" @click='navSection("staff")'>
                            <small>{{getMyLanguage("Store","merchant.store.staff.guide-title")}}</small>
                        </div>
                    </div>
                </div>

                <div class="box-profile radius">
                    <div class="load" v-if="sectionManage.load"></div>

                    <!-- STORE MANAGE > LAYOUT -->
                    <div class="cont-section manage-layout" v-if='sectionManage.btn === "layout" && !sectionManage.load'>

                        <h2 class="title-secondary">{{getMyLanguage("Store","merchant.store.layout.title")}}</h2>

                        <div class="box-registration">
                            <h3 class="subtitle">{{getMyLanguage("Store","merchant.store.subtitle.headerStore")}}</h3>

                            <div class="row formBox">

                                <!-- HEADER STORE -->
                                <div class="col-12">
                                    <div class="header-store"
                                    :class="{preview: selectedHeaderStore.preview}"
                                    :style="{ background: `url(${dataMerchant.store.banner_attachment})` }">

                                        <img v-if="!uploadFileLogo.previewURLImage"
                                        :src="dataMerchant.store.logo_attachment"
                                        class="store-logo"
                                        :class="{storeLogoBox: dataMerchant.store.logo_attachment_box}">
                                        
                                        <img v-if="uploadFileLogo.previewURLImage"
                                        :src="uploadFileLogo.previewURLImage"
                                        class="store-logo"
                                        :class="{storeLogoBox: dataMerchant.store.logo_attachment_box}">
                                        
                                        <div class="preview-image" v-if="dataSendLogo.logo">
                                            <div>
                                            </div>
                                        </div>
                                        
                                        <h2 class="store-name">{{dataMerchant.store.name}}</h2>
                                    </div>
                                </div>

                                <!-- LOGO -->
                                <div class="col-12 col-lg-6">
                                    <label for="logoStore" class="upload upload-logo">
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.sendLogo")}}

                                            <i><span>Selecione seu logotipo com uma boa qualidade</span></i>
                                        </span>

                                        <small>{{getMyLanguage("Store","merchant.store.lbl.sendLogo.inf")}}</small>
                                        
                                        <em>{{getMyLanguage("Store","merchant.store.btn.selectImage")}}</em>
                                        <input type="file" id="logoStore" ref="fileLogo" accept="image/*" @change="uploadLogo()">
                                    </label>

                                    <div class="form-group-check">
                                        <input type="checkbox" id="showBoxLogo" class="form-check" v-model="dataMerchant.store.logo_attachment_box">
                                        
                                        <label for="showBoxLogo" class="form-check-label">
                                            <span>{{getMyLanguage("Store","merchant.store.btn.add-bg-white")}}</span>
                                        </label>
                                    </div>
                                </div>

                                <!-- STORE NAME -->
                                <div class="col-12 col-lg-6">
                                    <label>
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.storeName")}}

                                            <i><span>Informe o nome comercial da sua loja</span></i>
                                        </span>

                                        <small>{{getMyLanguage("Store","merchant.store.lbl.storeName.inf")}}</small>
                                        
                                        <div class="store-slug">
                                            <input type="text" class="form"
                                            :class="{error: errorForm.store.name}"
                                            v-model="dataMerchant.store.name"
                                            @keyup="generateSlugStore(dataMerchant.store.name), errorForm.store.name = false">

                                            <input type="text" class="form"
                                            :class="{error: errorForm.store.slug}"
                                            v-model="dataMerchant.store.slug"
                                            @keyup="generateSlugStore(dataMerchant.store.slug), errorForm.store.slug = false">
                                        </div>
                                    </label>
                                </div>

                                <!-- BACKGROUND HEADER STORE -->
                                <div class="col-12 col-lg-6">
                                    <label>
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.imageHeaderStore")}}
                                            
                                            <i><span>Selecione uma imagem para ser aplicada no topo da sua loja</span></i>
                                        </span>

                                        <small>{{getMyLanguage("Store","merchant.store.lbl.imageHeaderStore.inf")}}</small>

                                        <div class="btn-open" @click="flowHeaderStore.modal = true">
                                            {{getMyLanguage("Store","merchant.store.btn.selectImage")}}
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>


                        <!-- STORE CONTACT -->
                        <div class="box-registration">
                            <div class="row formBox">
                                <div class="col-12">
                                    <div class="check-show-contacts">
                                        <h3 class="subtitle">
                                            {{getMyLanguage("Store","merchant.store.subtitle.showContacts")}}
                                        </h3>

                                        <p class="obs-subtitle">
                                            {{getMyLanguage("Store","merchant.store.subtitle.showContacts.obs")}}
                                        </p>

                                        <div class="form-group-radio sidebyside">
                                            <input type="radio"
                                            v-model="dataMerchant.store.store_details.show_details"
                                            id="showContactsTrue"
                                            :value="true"
                                            name="radioContacts"
                                            class="form-radio"
                                            @click='dataMerchant.store.store_details.show_details = true, changeContact(dataMerchant.store.addresses[0])'
                                            :checked='dataMerchant.store.store_details.show_details' >

                                            <label for="showContactsTrue" class="form-radio-label">
                                                <span>{{getMyLanguage("Store","merchant.store.btn.btn-yes")}}</span>
                                            </label>

                                            <input type="radio"
                                            v-model="dataMerchant.store.store_details.show_details"
                                            id="showContactsFalse"
                                            :value="false"
                                            name="radioContacts"
                                            class="form-radio"
                                            @click="dataMerchant.store.store_details.show_details = false"
                                            :checked='!dataMerchant.store.store_details.show_details' >
                                            
                                            <label for="showContactsFalse" class="form-radio-label">
                                                <span>{{getMyLanguage("Store","merchant.store.btn.btn-no")}}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row formBox" v-if="dataMerchant.store.store_details.show_details">
                                <div class="col-12">
                                    <div class="addresses addresses-contact">
                                        <div class="address add" @click="addAddress('Contact')">
                                            <span>{{getMyLanguage("Store","merchant.store.btn.addAnotherAddress")}}</span>
                                        </div>

                                        <div class="list-address" v-for="(listAddress, indexAddress) in dataMerchant.store.addresses" :key="indexAddress">
                                            <div class="address"
                                            @click="changeContact(listAddress)"
                                            :class="{selected: listAddress.account_address_id == dataMerchant.store.store_details.contact_account_address_id}">
                                                <span>
                                                    {{listAddress.address_1}},&nbsp;
                                                    {{listAddress.number}}

                                                    <span v-if="listAddress.address_2">
                                                        ,&nbsp;{{listAddress.address_2}}
                                                    </span>
                                                    
                                                    ,&nbsp;{{listAddress.postal_code}}
                                                    &nbsp;|&nbsp;{{listAddress.city}}
                                                    &nbsp;|&nbsp;{{listAddress.state}}
                                                    &nbsp;|&nbsp;{{$store.state.allCountries.find(cc => cc.Id == listAddress.country_id)?.Name}}
                                                </span>
                                            </div>

                                            <div class="btn-remove"
                                            @click="removeAddress('start', listAddress)"
                                            v-if="listAddress.account_address_id != dataMerchant.store.store_details.contact_account_address_id"></div>
                                        </div>

                                         <div id="previewAddress"></div>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <label>
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.address.preview.title")}}

                                            <i><span>Adicione mais informações se necessário como por exemplo: nome do shopping, galeria, referências, etc...</span></i>

                                            <span class="characters">{{dataMerchant.store.store_details.address.length}}/100</span>
                                        </span>

                                        <small>{{getMyLanguage("Store","merchant.store.address.preview.obs")}}</small>

                                        <input type="text" class="form" v-model="dataMerchant.store.store_details.address" maxlength="100">
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6">
                                    <label>
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.region")}}

                                            <i><span>aaa</span></i>

                                            <span class="characters">{{dataMerchant.store.store_details.region.length}}/100</span>
                                        </span>

                                        <small>{{getMyLanguage("Store","merchant.store.lbl.region.obs")}}</small>

                                        <input type="text" class="form" v-model="dataMerchant.store.store_details.region" maxlength="100">
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6 form-ddi-phone">
                                    <label>
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.masterPhone")}}

                                            <i><span>aaaa</span></i>
                                        </span>

                                        <small>{{getMyLanguage("Store","merchant.store.lbl.fixed-telephone")}}</small>

                                        <div class="select-ddi">
                                            <div class="selected-ddi">
                                                <div class="flag">
                                                    <img class="flag-selected" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagCompany}/flags`">
                                                </div>
                                                <span class="ddi">+{{selectedCountryCompany.ddi}}</span>
                                            </div>
                                            
                                            <input type="text" class="form" id="phoneNumberContact01" maxlength="25"
                                            v-model="dataMerchant.store.store_details.phone_number_1"
                                            v-mask="selectedCountryCompany.phoneMask"
                                            :placeholder="selectedCountryCompany.phoneMask"
                                            @keydown="errorForm.contactProfile01.phoneNumber = false"
                                            @blur='regexPhone("contact01")'>
                                        </div>
                                    </label>
                                </div>
                                
                                <div class="col-12 col-lg-6 form-ddi-phone">
                                    <label>
                                        <span class="info">
                                            {{getMyLanguage("Store","merchant.store.lbl.secondaryPhone")}}

                                            <i><span>aaaa</span></i>
                                        </span>

                                        <small>{{getMyLanguage("Store","merchant.store.lbl.mobile-phone")}}</small>

                                        <div class="select-ddi">
                                            <div class="selected-ddi">
                                                <div class="flag">
                                                    <img class="flag-selected" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagCompany}/flags`">
                                                </div>

                                                <span class="ddi">+{{selectedCountryCompany.ddi}}</span>
                                            </div>
                                            
                                            <input type="text" class="form" id="phoneNumberContact02" maxlength="25"
                                            v-model="dataMerchant.store.store_details.phone_number_2"
                                            v-mask="selectedCountryCompany.phoneMask"
                                            :placeholder="selectedCountryCompany.phoneMask"
                                            @keydown="errorForm.contactProfile01.phoneNumber = false"
                                            @blur='regexPhone("contact01")'>
                                        </div>
                                    </label>
                                </div>

                                <div class="col-12 col-lg-6 check-show-contacts">
                                    <div class="business-hours">
                                        <div class="times">
                                            <label>
                                                <span class="info">
                                                    {{getMyLanguage("Store","merchant.store.lbl.business-hour")}}

                                                    <i><span>aaaa</span></i>
                                                </span>

                                                <small>{{getMyLanguage("Store","merchant.store.lbl.business-hour.obs")}}</small>

                                                <div class="days-time" v-for="(listTimes,indexBH) in dataMerchant.store.store_details.business_hours" :key="indexBH">
                                                    <input type="text" class="form"
                                                    v-model="dataMerchant.store.store_details.business_hours[indexBH]"
                                                    :placeholder='getMyLanguage("Store","merchant.store.lbl.business-hour.placeholder")'
                                                    :id="`bh${indexBH}`">

                                                    <div class="btn-remove" @click="removeBusinessHour(indexBH)"></div>
                                                </div>
                                                
                                                <div class="btn-add-day-time" @click="addBusinessHour()">
                                                    <span>
                                                        {{getMyLanguage("Store","merchant.store.lbl.business-hour.add")}}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- SELECT THEME -->
                        <div class="box-registration box-theme">
                            <div class="row formBox">
                                <div class="col-12">
                                    <h3 class="subtitle">{{getMyLanguage("Store","merchant.store.subtitle.selectTheme")}}</h3>

                                    <div class="templates">
                                        <div class="cont">
                                            <!-- <div class="template"
                                            :class="{active: dataMerchant.store.store_details.theme == theme.slug}"
                                            v-for="(theme,indexTheme) in dataThemes"
                                            :key="indexTheme"
                                            @click="previewTheme(theme)">

                                                <div class="image">
                                                    <img src="~@/assets/images/content/template-dne-market-01.jpg">
                                                </div>
                                                <h4>{{theme.name}}</h4>
                                            </div> -->
                                            
                                            <div class="template"
                                            :class="{active: dataMerchant.store.store_details.theme == theme.slug}"
                                            v-for="(theme,indexTheme) in dataThemes"
                                            :key="indexTheme"
                                            @click="theme.name === 'Default' ? previewTheme(theme) : null">

                                                <div class="image">
                                                    <img src="~@/assets/images/content/template-dne-market-default.jpg">
                                                </div>
                                                <h4>{{theme.name}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- STORE DOCK -->
                        <div class="box-registration box-docks">
                            <div class="row formBox">
                                <div class="col-12">
                                    <!-- <div class="check-show-contacts" :class="{active: dataMerchant.showContacts}"> -->
                                    <div class="check-show-contacts">
                                        <h3 class="subtitle">{{getMyLanguage("Store","merchant.store.subtitle.dockAddress")}}</h3>

                                        <div class="addresses">
                                            <div class="list-address" v-for="(listAddress, indexAddressDock) in dataMerchant.store.addresses" :key="indexAddressDock">
                                                <div class="address"
                                                @click="changeDock(listAddress)"
                                                :class="{selected: listAddress.account_address_id == dataMerchant.store.store_details.warehouse_account_address_id}">

                                                    <span>
                                                        {{listAddress.address_1}},&nbsp;
                                                        {{listAddress.number}}

                                                        <span v-if="listAddress.address_2">
                                                            ,&nbsp;{{listAddress.address_2}}
                                                        </span>
                                                        
                                                        ,&nbsp;{{listAddress.postal_code}}
                                                        &nbsp;|&nbsp;{{listAddress.city}}
                                                        &nbsp;|&nbsp;{{listAddress.state}}
                                                        &nbsp;|&nbsp;{{$store.state.allCountries.find(cc => cc.Id == listAddress.country_id)?.Name}}
                                                    </span>
                                                </div>

                                                <div class="btn-remove"
                                                @click="removeAddress('start',listAddress)"
                                                v-if="listAddress.account_address_id != dataMerchant.store.store_details.warehouse_account_address_id"></div>
                                            </div>

                                            <div class="address add" @click="addAddress('Warehouse')">
                                            <span>{{getMyLanguage("Store","merchant.store.btn.addAnotherAddress")}}</span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="action single">
                            <div class="btn-primary" v-if="!flowSave.load" @click="validateForm('layout')">
                                {{getMyLanguage("Store","merchant.store.btn.save")}}
                            </div>
                        </div>
                    </div>



                    <!-- MANAGE STAFF -->
                    <div class="cont-section manage-staff" v-if='sectionManage.btn === "staff" && !sectionManage.load'>

                        <h2 class="title-secondary">
                            {{getMyLanguage("Store","merchant.store.staff.title")}}
                        </h2>

                        <div class="box-registration">
                            <h3 class="subtitle">
                                {{getMyLanguage("Store","merchant.store.staff.subtitle")}}
                            </h3>

                            <div class="btn-add" @click="modalAddStaff()">
                                {{getMyLanguage("Store","merchant.store.staff.btn-add")}}
                            </div>
                            
                            <div class="list-users">
                                <div class="user"
                                v-for="(listStaff, listStaffIndex) in staff.list"
                                :key="listStaffIndex"
                                :class="listStaff.status_extra"
                                @click="modalViewStaff(listStaff)">
                                    <span class="name">{{listStaff.full_name}}</span>
                                    <span class="level">
                                        {{getMyLanguage("Store","merchant.store.staff.label.function-type." + dataMerchantTypes.filter(mt => mt.Id == listStaff.merchant_type_id)[0].type)}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </div>


    <!-- MODAL HEADER STORE -->
    <div class="modal modal-header-store"
    :class="{modalHeaderStore: !flowHeaderStore.showUpload}"
    v-if="flowHeaderStore.modal">

        <div class="modal-cont" :class="{modalShort: flowHeaderStore.showUpload}">
            
            <div class="modal-close"
            @click="flowHeaderStore.modal = false, flowHeaderStore.showUpload = false, flowHeaderStore.confirm = false"
            v-if="!flowHeaderStore.confirm"></div>
        
            <div class="modal-scroll" v-if="!flowHeaderStore.showUpload">
                <h2 class="title-secondary">
                    {{getMyLanguage("Store","merchant.store.subtitle.headerStore")}}
                </h2>
                
                <div class="load" v-if="flowHeaderStore.load"></div>

                <!-- UPLOAD -->
                <div class="open-upload-header-store" v-if="!flowHeaderStore.showUpload && !flowHeaderStore.load">
                    <p>
                        <strong>{{getMyLanguage("Store","merchant.store.lbl.headerImage")}}</strong>
                    </p>

                    <div @click="flowHeaderStore.showUpload = true">
                        {{getMyLanguage("Store","merchant.store.btn.addHeaderImage")}}
                    </div>
                </div>

                <div class="or" v-if="!flowHeaderStore.load">
                    <strong>{{getMyLanguage("Store","merchant.store.select-header-store.or")}}</strong>
                </div>

                <div class="select-header-store" v-if="!flowHeaderStore.load">
                    <p>
                        <strong>{{getMyLanguage("Store","merchant.store.select-header-store.lbl")}}</strong>
                    </p>

                    <select class="form">
                        <option value="" disabled selected hidden>{{getMyLanguage("Store","merchant.store.option.select")}}</option>
                        <option value="000">Filtar por categoria</option>
                        <option value="111">Tecnologia (computadores, periféricos ...)</option>
                        <option value="222">Games (consolas, jogos ...)</option>
                        <option value="333">Esportes</option>
                        <option value="444">Telemóveis (aparelhos, acessórios ...)</option>
                        <option value="555">Vestuário (roupas, calçados ...)</option>
                    </select>

                    <div class="headers">
                        <div v-for="(headers,indexHeaders) in dataHeaderStore" :key="indexHeaders">

                            <div class="header-store"
                            @click="changeHeaderStore(headers.banner.attachment.id,headers.banner.attachment.attachment)"
                            :style="{ background: `url(${headers.banner.attachment.attachment})` }">

                                <img v-if="!uploadFileLogo.previewURLImage"
                                :src="dataMerchant.store.logo_attachment"
                                :alt="dataMerchant.store.name"
                                class="store-logo"
                                :class="{boxLogo: dataMerchant.store.logo_attachment_box}">

                                <img v-if="uploadFileLogo.previewURLImage"
                                :src="uploadFileLogo.previewURLImage"
                                :alt="dataMerchant.store.name"
                                class="store-logo"
                                :class="{boxLogo: dataMerchant.store.logo_attachment_box}">

                                <h2 class="store-name">{{dataMerchant.store.name}}</h2>
                            </div>

                            <p>
                                <span>
                                    {{getMyLanguage("Store","merchant.store.select-header-store.option-name")}}&nbsp;
                                    <small>{{headers.banner.attachment.type}}</small>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="upload-header-store" v-if="flowHeaderStore.showUpload">
                <h2 class="title-secondary">
                    {{getMyLanguage("Store","merchant.store.subtitle.uploadImageHeader")}}
                </h2>

                <label for="LblUploadFileHeaderStore" class="upload">
                    
                    <span v-if="!uploadFileHeaderStore.selected" class="upload">
                        {{getMyLanguage("Store","merchant.store.subtitle.uploadImageHeader.inf01")}}
                        <small>{{getMyLanguage("Store","merchant.store.subtitle.uploadImageHeader.inf02")}}</small>
                    </span>
                    
                    <em v-if="!uploadFileHeaderStore.previewURLImage" :class='uploadFileHeaderStore.selected'>
                        <!-- {{getMyLanguage("Store","merchant.store.btn.selectImage")}} -->
                        <span class="send" v-if="!uploadFileHeaderStore.selected">
                            {{getMyLanguage("Store","merchant.store.document-status.msg.file-select")}}
                        </span>

                        <span class="selected" v-if="uploadFileHeaderStore.selected">
                            {{getMyLanguage("Store","merchant.store.document-status.msg.file-selected")}}
                        </span>
                    </em>

                    <input type="file" id="LblUploadFileHeaderStore" ref="fileHeaderStore" accept="image/*" @change="uploadHeaderStore()">
                    
                    <div class="preview-image" v-if="uploadFileHeaderStore.previewURLImage">
                        <div>
                            <img :src="uploadFileHeaderStore.previewURLImage">
                        </div>
                    </div>
                </label>

                <div class="resume-file" v-if="uploadFileHeaderStore.resumeFile">
                    <div class="error" v-if="uploadFileHeaderStore.typeFile != 'image'">
                        {{getMyLanguage("Store","merchant.store.document-status.msg.please-select-image")}}
                    </div>

                    <p>
                        <strong>
                            {{getMyLanguage("Store","merchant.store.document-status.resume-file.file")}}
                        </strong>
                        {{ uploadFileHeaderStore.File.name }}
                    </p>

                    <p>
                        <strong>
                            {{getMyLanguage("Store","merchant.store.document-status.resume-file.type")}}
                        </strong>
                        {{ uploadFileHeaderStore.File.type }}
                    </p>

                    <p>
                        <strong>
                            {{getMyLanguage("Store","merchant.store.document-status.resume-file.size")}}
                        </strong>
                        {{ formatFileSize(uploadFileHeaderStore.File ? uploadFileHeaderStore.File.size : 0) }}
                    </p>
                </div>

                <div class="box-error" v-if="errorsPostHeaderStore.upload != false">
                    <h3 class="title-secondary">
                        {{ getMyLanguage("box-error", "title-error") }}
                    </h3>
                    
                    <p>{{getMyLanguage("Store","merchant.store.document-status.upload-error")}}</p>
                </div>

                <div class="bt-upload">
                    <div class="btn-primary btn-disabled"
                    v-if='uploadFileHeaderStore.selected != "fileSelected"
                        || uploadFileHeaderStore.File === ""
                        || uploadFileHeaderStore.typeFile != "image"'>

                        {{getMyLanguage("Store","merchant.store.document-status.btn-view")}}
                    </div>
                    
                    <div class="btn-primary"
                    @click="changeHeaderStorePreview()"
                    v-if='uploadFileHeaderStore.selected == "fileSelected"
                        && !errorsPostDocs.upload
                        && !uploadFileHeaderStore.loadUpload
                        && uploadFileHeaderStore.File != ""
                        && uploadFileHeaderStore.typeFile == "image"'>

                        {{getMyLanguage("Store","merchant.store.document-status.btn-view")}}
                    </div>

                    <div class="btn-primary load" v-if="uploadFileHeaderStore.loadUpload"></div>
                </div>
            </div>


            <!-- CONFIRM -->
            <!-- <div class="confirm" v-if="flowHeaderStore.confirm">
                <div class="load" v-if="flowHeaderStore.load"></div>

                <div class="box" v-if="!flowHeaderStore.load">
                    <h3 class="title-secondary">{{getMyLanguage("Store","merchant.store.confirm.headerStore.title")}}</h3>
                    <p>{{getMyLanguage("Store","merchant.store.confirm.headerStore.txt")}}</p>
                    <div class="bts">
                        <div class="btn btn-no" @click="flowHeaderStore.confirm = false">{{getMyLanguage("Store","merchant.store.btn.btn-no")}}</div>
                        <div class="btn btn-yes" @click="changeHeaderStore()">{{getMyLanguage("Store","merchant.store.btn.btn-yes")}}</div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>


    <!-- MODAL CHANGE THEME -->
    <div class="modal modalThemeStore" v-if="flowThemeStore.modal">
        <div class="modal-cont">
            <div class="modal-close"
            @click="flowThemeStore.modal = false, flowThemeStore.confirm = false"
            v-if="!flowThemeStore.confirm"></div>
            
            <div class="modal-scroll">
                <div class="header-theme">
                    <h3>{{flowThemeStore.theme.name}}</h3>

                    <div class="medias">
                        <span class="mobile"
                        :class="{active: flowThemeStore.device == 'mobile'}"
                        @click="flowThemeStore.device = 'mobile'">
                            <img src="~@/assets/images/icons/device-mobile.svg">
                        </span>

                        <span class="tablet"
                        :class="{active: flowThemeStore.device == 'tablet'}"
                        @click="flowThemeStore.device = 'tablet'">
                            <img src="~@/assets/images/icons/device-tablet.svg">
                        </span>

                        <span class="desktop"
                        :class="{active: flowThemeStore.device == 'desktop'}"
                        @click="flowThemeStore.device = 'desktop'">
                            <img src="~@/assets/images/icons/device-desktop.svg">
                        </span>
                    </div>
                </div>
                
                <div class="theme">
                    <img src="/images/themes/theme-default-mobile.jpg" class="theme-mobile" v-if="flowThemeStore.device == 'mobile'">
                    <img src="/images/themes/theme-default-tablet.jpg" class="theme-tablet" v-if="flowThemeStore.device == 'tablet'">
                    <img src="/images/themes/theme-default-desktop.jpg" class="theme-desktop" v-if="flowThemeStore.device == 'desktop'">
                </div>
            </div>
            
            <!-- CONFIRM -->
            <!-- <div class="open-confirm"
            @click="flowThemeStore.confirm = true"
            v-if="!flowThemeStore.confirm">
                <span>{{getMyLanguage("Store","merchant.store.btn.applyTheme")}}</span>
            </div> -->

            <div class="confirm" v-if="flowThemeStore.confirm">
                <div class="load" v-if="flowThemeStore.load"></div>

                <div class="box" v-if="!flowThemeStore.load">
                    <h3 class="title-secondary">
                        {{getMyLanguage("Store","merchant.store.confirm.themeStore.title")}}
                    </h3>

                    <p>{{getMyLanguage("Store","merchant.store.confirm.themeStore.txt")}}</p>
                    
                    <div class="bts">
                        <div class="btn btn-no" @click="flowThemeStore.confirm = false">
                            {{getMyLanguage("Store","merchant.store.btn.btn-no")}}
                        </div>

                        <div class="btn btn-yes" @click="changeThemeStore()">
                            {{getMyLanguage("Store","merchant.store.btn.btn-yes")}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- MODAL ADD ADDRESS -->
    <div class="modal modal-add-address" v-if="flowAddressAdd.modal">
        <div class="modal-cont">
            <div class="modal-close" @click="flowAddressAdd.modal = false"></div>

            <div class="modal-scroll">
                <h2 class="title-secondary">
                    {{getMyLanguage("Store","merchant.store.address.modal-title.new-address")}} / {{flowAddressAdd.address.address_type_code}}
                </h2>

                <div class="load" v-if="flowAddressAdd.load"></div>

                <div class="row formBox show" v-if="!flowAddressAdd.load && flowAddressAdd.status == 'new'">

                    <!-- COUNTRY DOCK -->
                    <!-- <div class="col-12 col-lg-6">
                        <div class="autocomplete has-label no-icon formResidenceCountry" :class="{opened: dropListOpened.countryDock}">
                            <div class="openAutocomplete" v-if="!listCountryDockStatus" @click="dropListCountry(true,'dock')"></div>
                            <div class="closeAutocomplete closeDropListCountry" v-if="listCountryDockStatus" @click="dropListCountry(false,'dock')"></div>

                            <label>
                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagDock}/flags`" class="flag-selected" v-if="selectedCountryFlagDock != false">
                                <span>{{getMyLanguage("Store","merchant.store.lbl.country")}}</span>
                                <input type="text" id="residenceCountryDock" class="form" :class="{selected: selectedCountryFlagDock != false, error: errorForm.dockProfile.countryId}" @focus="dropListCountry(true,'dock')" @blur="dropListCountry(false,'dock')" @keydown.esc="dropListCountry(false,'dock')" @keyup="filterListCountryDock" :value="selectedCountryDock.name">
                                <p class="msg-validate-form msg-error anime" v-if='errorForm.dockProfile.countryId'>{{getMyLanguage("errors","errors.residenceError")}}</p>
                            </label>

                            <ul id="drop-list" v-if="listCountryDockStatus">
                                <li v-for="(itemListDock,index) in dataCountry" :key="index" @click="newAddress.countryId = itemListDock.id, newAddress.phoneCountryId = itemListDock.id, changeMask('dock'), selectedCountryFlagDock = itemListDock.flag, selectedCountryDDIDock.countrySelected = true, selectedCountryDDIDock.active = 'active', selectedCountryDDIDock.phoneMask = itemListDock.phoneMask, selectedCountryDDIDock.ddi = itemListDock.ddi, selectedCountryDDIDock.flag = itemListDock.flag, selectedCountryDock.name = itemListDock.name, dropListCountry(false,'dock'), errorForm.dockProfile.countryId = false">
                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemListDock.flag}/flags`">
                                    <a>{{itemListDock.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div> -->

                    <!-- PHONE DOCK -->
                    <!-- <div class="col-12 col-lg-6">
                        <div class="form-ddi-phone">
                            <label :class='{error: (errorsPostSave.dataMerchant === "account_activate_phone_already_exists" || validatePhone.dock.regex === false), validated: validatePhone.dock.regex}'>
                                <span>{{getMyLanguage("Store","merchant.store.lbl.dockPhone")}}</span>
                                <div class="select-ddi" :class='{disabled: selectedCountryDDIDock.phoneMask == "?"}' v-if="selectedCountryDDIDock.changeMask">
                                    <div class="selected-ddi">
                                        <div class="flag">
                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDIDock.flag}/flags`" v-if="selectedCountryDDIDock.flag != ''">
                                        </div>
                                        <span class="ddi" v-if="selectedCountryDDIDock.phoneMask != '?'">+{{selectedCountryDDIDock.ddi}}</span>
                                    </div>
                                    
                                    <input v-if="selectedCountryDDIDock.phoneMask != '?'" v-model="validatePhone.dock.val" id="phoneNumberDock" type="text" class="form" v-mask="selectedCountryDDIDock.phoneMask" @keydown="errorForm.dockProfile.phoneNumber = false" @blur='regexPhone("dock")' maxlength="25" >
                                </div>
                                <div class="load" v-if="!selectedCountryDDIDock.changeMask"></div>

                                <p class="msg-validate-form msg-error regex-error anime" v-if='validatePhone.dock.regex === false || errorForm.dockProfile.phoneNumber'>{{getMyLanguage("errors","errors.phoneError")}}</p>
                                <p class="msg-validate-form msg-error anime" v-if='errorsPostSave.dataMerchant === "account_activate_phone_already_exists"'>{{getMyLanguage("errors","errors.phoneRegistered")}}</p>
                            </label>
                        </div>
                    </div> -->

                    <div class="col-12 col-lg-4">
                        <div class="autocomplete has-label no-icon formResidenceCountry" :class="{opened: dropListOpened.countryAdd}">
                            <div class="openAutocomplete" v-if="!listCountryAddStatus" @click="dropListCountry(true,'add')"></div>
                            <div class="closeAutocomplete closeDropListCountry" v-if="listCountryAddStatus" @click="dropListCountry(false,'add')"></div>

                            <label>
                                <img class="flag-selected"
                                :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagAddAddress}/flags`"
                                v-if="selectedCountryFlagAddAddress != false">

                                <span>{{getMyLanguage("Store","merchant.store.lbl.country")}}</span>
                                
                                <input type="text" id="residenceCountryAdd" class="form"
                                :class="{selected: selectedCountryFlagAddAddress != false,error: errorForm.addAddress.country_id}"
                                @focus="dropListCountry(true,'add')"
                                @blur="dropListCountry(false,'add')"
                                @keydown.esc="dropListCountry(false,'add')"
                                @keyup="filterListCountryAdd"
                                :value="countryTranslate(selectedCountryAdd.isocode)">
                                <!-- <p class="msg-validate-form msg-error anime" v-if='errorForm.addAddress.country_id'>{{getMyLanguage("errors","errors.residenceError")}}</p> -->
                            </label>

                            <div class="cont-autocomplete" v-if="listCountryAddStatus">
                                <ul id="drop-list">
                                    <!-- <li v-for="(itemListContact,index) in dataCountry" :key="index" @click="newAddress.countryId = itemListContact.id, newAddress.phoneCountryId = itemListContact.id, changeMask('contact'), selectedCountryFlagContact = itemListContact.flag, selectedCountryDDIContact.countrySelected = true, selectedCountryDDIContact.active = 'active', selectedCountryDDIContact.phoneMask = itemListContact.phoneMask, selectedCountryDDIContact.ddi = itemListContact.ddi, selectedCountryDDIContact.flag = itemListContact.flag, selectedCountryAdd.name = itemListContact.name, dropListCountry(false,'contact'), errorForm.contactProfile01.countryId = false"> -->
                                    <!-- <li v-for="(itemListAdd,index) in dataCountry" :key="index" @click="flowAddressAdd.address.country_id = itemListAdd.id, selectedCountryFlagAddAddress = itemListAdd.flag, selectedCountryDDIContact.countrySelected = true, selectedCountryDDIContact.active = 'active', selectedCountryDDIContact.phoneMask = itemListAdd.phoneMask, selectedCountryDDIContact.ddi = itemListAdd.ddi, selectedCountryDDIContact.flag = itemListAdd.flag, selectedCountryAdd.name = itemListAdd.name, dropListCountry(false,'add'), errorForm.addAddress.country_id = false"> -->
                                    <li v-for="(itemListAdd,index) in $store.state.allCountries" :key="index"
                                    @click="flowAddressAdd.address.country_id = itemListAdd.Id,
                                        selectedCountryFlagAddAddress = itemListAdd.Flag,
                                        selectedCountryDDIContact.countrySelected = true,
                                        selectedCountryDDIContact.active = 'active',
                                        selectedCountryDDIContact.phoneMask = itemListAdd.phoneMask,
                                        selectedCountryDDIContact.ddi = itemListAdd.ddi,
                                        selectedCountryDDIContact.Flag = itemListAdd.flag,
                                        selectedCountryAdd.isocode = itemListAdd.IsoCode,
                                        dropListCountry(false,'add'),
                                        errorForm.addAddress.country_id = false">

                                        <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemListAdd.Flag}/flags`">
                                        <a>{{ countryTranslate(itemListAdd.IsoCode) }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-4">
                        <label>
                            <span>{{getMyLanguage("Store","merchant.store.lbl.state")}}</span>
                            
                            <input type="text" class="form"
                            :class='{error: errorForm.addAddress.state}'
                            v-model="flowAddressAdd.address.state"
                            @keydown="errorForm.addAddress.state = false">
                        </label>
                    </div>

                    <div class="col-12 col-lg-4">
                        <label>
                            <span>{{getMyLanguage("Store","merchant.store.lbl.city")}}</span>

                            <input type="text" class="form"
                            :class='{error: errorForm.addAddress.city}'
                            v-model="flowAddressAdd.address.city"
                            @keydown="errorForm.addAddress.city = false">
                        </label>
                    </div>
                    
                    <div class="col-12">
                        <label>
                            <span>{{getMyLanguage("Store","merchant.store.lbl.address1")}}</span>

                            <input type="text" class="form"
                            :class='{error: errorForm.addAddress.address_1}'
                            v-model="flowAddressAdd.address.address_1"
                            @keydown="errorForm.addAddress.address_1 = false">
                        </label>
                    </div>
                    
                    <div class="col-12 col-lg-4">
                        <label>
                            <span>{{getMyLanguage("Store","merchant.store.lbl.number")}}</span>

                            <input type="text" class="form"
                            :class='{error: errorForm.addAddress.number}'
                            v-model="flowAddressAdd.address.number"
                            @keydown="errorForm.addAddress.number = false">
                        </label>
                    </div>

                    <div class="col-12 col-lg-4">
                        <label>
                            <span>{{getMyLanguage("Store","merchant.store.lbl.address2")}}</span>

                            <input type="text" class="form"
                            :class='{error: errorForm.addAddress.address_2}'
                            v-model="flowAddressAdd.address.address_2"
                            @keydown="errorForm.addAddress.address_2 = false">
                        </label>
                    </div>

                    <div class="col-12 col-lg-4">
                        <label>
                            <span>{{getMyLanguage("Store","merchant.store.lbl.postalCode")}}</span>
                            <input type="text" class="form"
                            :class='{error: errorForm.addAddress.postal_code}'
                            v-model="flowAddressAdd.address.postal_code"
                            @keydown="errorForm.addAddress.postal_code = false">
                        </label>
                    </div>


                    <div class="col-12 action">
                        <div class="btn-back" @click="flowAddressAdd.modal = false">
                            <span>{{getMyLanguage("Store","merchant.store.btn.cancel")}}</span>
                        </div>

                        <div class="btn-primary" @click="validateNewAddress()">
                            {{getMyLanguage("Store","merchant.store.btn.save")}}
                        </div>
                    </div>
                </div>


                <!-- ERROR -->
                <div class="row" v-if="flowAddressAdd.status == 'error'">
                    <div class="col-12 d-flex justify-content-center">
                        <div class="box-error">
                            <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                            <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- MODAL REMOVE ADDRESS -->
    <div class="modal modal-remove" v-if="flowAddressRemove.modal">
        <div class="modal-cont">
            <div class="modal-close" @click="flowAddressRemove.modal = false"></div>
            <h2 class="title-secondary">
                {{getMyLanguage("Store","merchant.store.address.remove.title")}}
            </h2>

            <div class="load" v-if="flowAddressRemove.load"></div>

            <div class="box-registration" v-if="!flowAddressRemove.load">
                <h3>{{flowAddressRemove.address.address_1}}</h3>
                
                <p><strong>{{getMyLanguage("Store","merchant.store.address.remove.txt-confirm")}}</strong></p>

                <div class="action single">
                    <div class="btn-cancel" @click="flowAddressRemove.modal = false">
                        {{getMyLanguage("Store","merchant.store.btn.btn-no")}}
                    </div>

                    <div class="btn-primary" @click="removeAddress('confirm')">
                        {{getMyLanguage("Store","merchant.store.btn.btn-yes")}}
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- MODAL CONFIRM ALTERATIONS STORE -->
    <div class="modal modal-alterations-store" v-if="flowSave.modalConfirm">
        <div class="modal-cont">
            <div class="modal-close" @click="flowSave.modalConfirm = false"></div>
            <div class="modal-scroll">
                <h2 class="title-secondary">
                    {{getMyLanguage("Store","merchant.store.alterations.confirm.title")}}
                </h2>

                <div class="load" v-if="flowSave.load"></div>

                <div v-else>
                    <div class="box-pending alert-confirmation">
                        <p>{{getMyLanguage("Store","merchant.store.alterations.confirm.alert-confirmation-01")}}</p>
                        <p>{{getMyLanguage("Store","merchant.store.alterations.confirm.alert-confirmation-02")}}</p>
                        <p>{{getMyLanguage("Store","merchant.store.alterations.confirm.alert-confirmation-03")}}</p>
                    </div>

                    <div class="confirm-alterations">
                        <div class="form-group-check">
                            <input type="checkbox" id="acceptedConfirmation" class="form-check" v-model="flowSave.confirmAccepted">
                            
                            <label for="acceptedConfirmation" class="form-check-label">
                                <span>{{getMyLanguage("Store","merchant.store.alterations.confirm.accept")}}</span>
                            </label>
                        </div>

                        <div class="bts-action">
                            <div class="btn-primary btn-disabled" v-if="!flowSave.confirmAccepted">
                                {{getMyLanguage("Store","merchant.store.btn.save")}}
                            </div>

                            <div class="btn-primary" v-if="flowSave.confirmAccepted" @click="saveSection('layout')">
                                {{getMyLanguage("Store","merchant.store.btn.save")}}
                            </div>

                            <div class="btn-primary btn-cancel" @click="flowSave.modalConfirm = false">
                                {{getMyLanguage("Store","merchant.store.btn.cancel")}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- MODAL ADD/EDT STAFF -->
    <div class="modal modal-staff" v-if="staff.modal.new">
        <div class="modal-cont">
            <div class="modal-close" @click="staff.modal.new = false"></div>

            <div class="load" v-if="staff.flowStaff.load"></div>
            
            <div class="modal-scroll" v-if="!staff.flowStaff.load && (staff.flowStaff.status === 'new' || staff.flowStaff.status === 'edt')">
                <h2 class="title-secondary" v-if="staff.flowStaff.status == 'new'">
                    {{getMyLanguage("Store",'merchant.store.staff.add.title')}}
                </h2>

                <p class="obs" v-if="staff.flowStaff.status == 'new'">
                    {{getMyLanguage("Store",'merchant.store.staff.add.subtitle')}}
                </p>

                <h2 class="title-secondary" v-if="staff.flowStaff.status == 'edt'">
                    {{getMyLanguage("Store",'merchant.store.staff.btn-edit')}}
                </h2>


                <!-- STAFF > NAME -->
                <label :class='{error: errorForm.staff.full_name}'>
                    <span>{{getMyLanguage("Store","merchant.store.staff.label.username")}}</span>
                    
                    <input type="text" class="form" v-model="staff.user.full_name" @keydown="errorForm.staff.full_name = false" v-if="staff.flowStaff.status == 'new'">

                    <div class="form form-disabled" v-else>{{staff.user.full_name}}</div>
                </label>
                
                <!-- STAFF > TYPE -->
                <label :class='{error: errorForm.staff.merchant_type_id}'>
                    <span>{{getMyLanguage("Store","merchant.store.staff.label.function")}}</span>

                    <select class="form" v-model="staff.user.merchant_type_id" @change="errorForm.staff.merchant_type_id, errorForm.staff.merchant_type_id = false">
                        <option v-for="(itemsMerchantType, indexMerchantType) in dataMerchantTypes" :key="indexMerchantType" :value="itemsMerchantType.Id">
                            {{getMyLanguage("Store","merchant.store.staff.label.function-type." + itemsMerchantType.type)}}
                        </option>
                    </select>
                </label>
                
                <!-- STAFF > EMAIL -->
                <label :class="{validated: (validateEmail.staff.regex && errorForm.staff.email === false), error: (validateEmail.staff.regex === false || errorForm.staff.email)}" v-if="staff.flowStaff.status == 'new'">
                    
                    <span>{{getMyLanguage("Store","merchant.store.lbl.email")}}</span>
                    
                    <input type="text" class="form" maxlength="60"
                    v-model="staff.user.email"
                    @blur='regexEmail("staff")'
                    @keydown="errorForm.staff.email = false"                   
                    >
                </label>

                <label v-else>
                    <span>{{getMyLanguage("Store","merchant.store.lbl.email")}}</span>
                    <div class="form form-disabled">{{staff.user.email}}</div>
                </label>


                <!-- STAFF > COUNTRY -->
                <div class="autocomplete has-label no-icon formLanguage" :class="{opened: dropListOpened.staff}" v-if="staff.flowStaff.status == 'new'">
                    <div class="openAutocomplete" v-if="!listCountryStaff" @click="dropListCountry(true,'staff')"></div>
                    <div class="closeAutocomplete closeDropListCountry" v-if="listCountryStaff" v-on:click="dropListCountry(false,'staff')"></div>

                    <label :class="{error: errorForm.staff.country_id}">
                        <span>{{getMyLanguage("Store","merchant.store.lbl.country")}}</span>
                        <img class="flag-selected"
                        :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagStaff}/flags`"
                        v-if="selectedCountryFlagStaff != false">
                        
                        <input type="text" id="residenceCountryStaff" class="form"
                        :class="{selected: selectedCountryFlagStaff != false}"
                        @focus="dropListCountry(true,'staff')"
                        @blur="dropListCountry(false,'staff')"
                        @keydown.esc="dropListCountry(false,'staff')"
                        v-on:keyup="filterListCountryStaff()"
                        :value="countryTranslate(selectedCountryStaff.isocode)">
                    </label>

                    <div class="cont-autocomplete" v-if="listCountryStaff">
                        <ul id="drop-list">
                            <li v-for="(itemList,index) in $store.state.allCountries" :key="index"
                            v-on:click="staff.user.country_id = itemList.Id,
                                staff.user.phone_country_id = itemList.Id,
                                changeMask('staff',itemList.PhoneMask),
                                selectedCountryDDIStaff.PhoneMask = itemList.PhoneMask,
                                selectedCountryFlagStaff = itemList.Flag,
                                selectedCountryDDIStaff.countrySelected = true,
                                selectedCountryDDIStaff.active = 'active',
                                selectedCountryDDIStaff.ddi = itemList.DDI,
                                selectedCountryDDIStaff.flag = itemList.Flag,
                                selectedCountryStaff.isocode = itemList.IsoCode,
                                dropListCountry(false,'staff')">

                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.Flag}/flags`">
                                <a>{{ countryTranslate(itemList.IsoCode) }}</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <label v-else>
                    <span>{{getMyLanguage("Store","merchant.store.lbl.country")}}</span>

                    <div class="form form-disabled">
                        {{ countryTranslate(dataCountry.find(ctry => ctry.Id == staff.user.country_id)?.IsoCode) }}
                    </div>
                </label>


                <!-- STAFF > PHONE -->
                <div class="form-ddi-phone">
                    <label :class='{error: (validatePhone.staff.regex === false || errorForm.staff.phone_number), validated: validatePhone.staff.regex}' v-if="staff.flowStaff.status == 'new'">
                        <span>{{getMyLanguage("BecomeSeller","become-seller.register.mobile-phone")}}</span>

                        <div class="select-ddi"
                        :class='{disabled: selectedCountryDDIStaff.PhoneMask == "?"}'
                        v-if="selectedCountryDDIStaff.changeMask">

                            <div class="selected-ddi">
                                <div class="flag">
                                    <img
                                    :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDIStaff.flag}/flags`"
                                    v-if="selectedCountryDDIStaff.flag != ''">
                                </div>

                                <span class="ddi" v-if="selectedCountryDDIStaff.PhoneMask != '?'">
                                    +{{selectedCountryDDIStaff.ddi}}
                                </span>
                            </div>
                            
                            <input type="text" inputmode="numeric" class="form form-phone" id="phoneNumberStaff" maxlength="25"
                            v-model="validatePhone.staff.val"
                            v-mask="selectedCountryDDIStaff.PhoneMask"
                            @blur="regexPhone('staff')"
                            :placeholder="selectedCountryDDIStaff.PhoneMask"
                            v-if='selectedCountryDDIStaff.PhoneMask != "?" && selectedCountryDDIStaff.PhoneMask != "load"'>
                        </div>

                        <div class="form load-phone" v-if="!selectedCountryDDIStaff.changeMask"></div>
                    </label>

                    <label v-else>
                        <span>{{getMyLanguage("BecomeSeller","become-seller.register.mobile-phone")}}</span>

                        <div class="form form-disabled">
                            {{staff.user.phone_number}}
                        </div>
                    </label>
                </div>

                <div class="btn-center">
                    <div class="btn-primary"
                    v-if="!staff.flowStaff.load && staff.flowStaff.status == 'new'"
                    @click="validateStaff('new')">
                        {{getMyLanguage("Store","merchant.store.staff.btn-add")}}
                    </div>

                    <div class="btn-primary"
                    v-if="!staff.flowStaff.load && staff.flowStaff.status == 'edt'"
                    @click="validateStaff('edt')">
                        {{getMyLanguage("Store","merchant.store.staff.btn-edt")}}
                    </div>

                    <div class="btn-primary load" v-if="staff.flowStaff.load"></div>
                </div>
            </div>


            <!-- SUCCESS ADD -->
            <div class="success" v-if="staff.flowStaff.status == 'added'">
                <h2 class="title-secondary">
                    {{getMyLanguage("Store","merchant.store.staff.box-success.title")}}
                </h2>

                <div class="box box-success">
                    <p>{{getMyLanguage("Store","merchant.store.staff.box-success.added-01")}}</p>
                   
                    <p>
                        {{getMyLanguage("Store","merchant.store.staff.box-success.added-02")}}&nbsp;
                        <strong>{{this.staff.user.email}}</strong>
                    </p>
                </div>
            </div>
            
            
            <!-- SUCCESS EDT -->
            <div class="success" v-if="staff.flowStaff.status == 'updated'">
                <h2 class="title-secondary">
                    {{getMyLanguage("Store","merchant.store.staff.box-success.title")}}
                </h2>

                <div class="box box-success">
                    <p><strong>{{this.staff.user.full_name}}</strong></p>
                    <p>{{getMyLanguage("Store","merchant.store.staff.box-success.edited-01")}}</p>
                </div>
            </div>
        </div>
    </div>


    <!-- MODAL VIEW STAFF -->
    <div class="modal modal-staff"
    :class="{modalRemove: staff.modal.remove}"
    v-if="staff.modal.view || staff.modal.remove">
    
        <div class="modal-cont">
            <div class="modal-close" @click="staff.modal.view = false, staff.modal.remove = false"></div>
            
            <div class="load" v-if="staff.flowStaff.load"></div>

            <div class="modal-scroll" v-if="staff.modal.view && !staff.flowStaff.load">
                <h2 class="title-secondary">
                    {{getMyLanguage("Store","merchant.store.staff.user-information.title")}}
                </h2>

                <div class="view-staff" :class="staff.view.status_extra">
                    <div>
                        <small>{{getMyLanguage("Store","merchant.store.staff.label.username")}}</small>
                        <strong>{{staff.view.full_name}}</strong>
                    </div>

                    <div>
                        <small>{{getMyLanguage("Store","merchant.store.staff.label.function")}}</small>
                        <strong>{{getMyLanguage("Store","merchant.store.staff.label.function-type." + dataMerchantTypes.find(mt => mt.Id == staff.view.merchant_type_id)?.type)}}</strong>
                    </div>

                    <div>
                        <small>{{getMyLanguage("Store","merchant.store.lbl.email")}}</small>
                        <strong>{{staff.view.email}}</strong>
                    </div>

                    <div>
                        <small>{{getMyLanguage("Store","merchant.store.lbl.country")}}</small>
                        <strong>{{ countryTranslate(dataCountry.find(ctry => ctry.Id == staff.view.country_id)?.IsoCode) }}</strong>
                    </div>

                    <div>
                        <small>{{getMyLanguage("Store","become-seller.register.mobile-phone")}}</small>
                        <strong>{{staff.view.phone_number}}</strong>
                    </div>
                </div>

                <div class="btn-center-col">
                    <div class="btn-primary btn-edt" @click="modalEdtStaff(staff.view)" v-if="staff.view.status_extra == 'Active'">
                        {{getMyLanguage("Store","merchant.store.staff.btn-edt.informations")}}
                    </div>

                    <div class="btn-del" @click="removeStaff('start', staff.view)" v-if="staff.view.status_extra == 'Active'">
                        {{getMyLanguage("Store","merchant.store.staff.btn-remove.user")}}
                    </div>
                    
                    <div class="btn-primary btn-edt" @click="staff.user.reference = staff.view.reference, staff.user.merchant_type_id = staff.view.merchant_type_id, staff.user.status_id = '896d3ee6-eff7-40af-aa9c-33109b622962', saveStaff('edt')" v-if="staff.view.status_extra == 'Inactive'">
                        <!-- {{getMyLanguage("Store","merchant.store.staff.btn-edt.informations")}} -->
                        Reativar utilizador
                    </div>
                </div>
            </div>


            <!-- REMOVE USER -->
            <div class="modal-scroll" v-if="staff.modal.remove">

                <div class="box-registration remove-user" v-if="staff.flowStaff.status != 'removed' && !staff.flowStaff.load">
                    <h2 class="title-secondary">
                        {{getMyLanguage("Store","merchant.store.staff.remove-user.title")}}
                    </h2>

                    <h3>{{staff.view.full_name}}</h3>
                    
                    <p>
                        <strong>
                            {{getMyLanguage("Store","merchant.store.staff.remove-user.confirm")}}
                        </strong>
                    </p>

                    <div class="action single">
                        <div class="btn-cancel" @click="staff.modal.remove = false, staff.modal.view = true">
                            {{getMyLanguage("Store","merchant.store.btn.btn-no")}}
                        </div>
                        
                        <div class="btn-primary" @click="removeStaff('confirm')">
                            {{getMyLanguage("Store","merchant.store.btn.btn-yes")}}
                        </div>
                    </div>
                </div>

                <!-- REMOVED -->
                <div v-if="staff.flowStaff.status == 'removed' && !staff.flowStaff.load">
                    
                    <div v-if="!staff.flowStaff.load">
                        <h2 class="title-secondary">
                            {{getMyLanguage("Store","merchant.store.staff.remove-user.removed.title")}}
                        </h2>

                        <div class="box box-pending">
                            <p><strong>{{this.staff.view.full_name}}</strong></p>
                            <p>{{getMyLanguage("Store","merchant.store.staff.remove-user.removed.msg")}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    const $ = require('jquery')
    window.$ = $

    $(document).on("click",".form-ddi-phone", function(){
        $(".form-ddi-phone").removeClass("active")
        $(this).addClass("active")
    })
    
    $(document).on("blur",".form-ddi-phone", function(){
        $(".form-ddi-phone").removeClass("active")
    })

    import Header from "@/components/Essentials/Header.vue";
    import Breadcrumb from "@/components/Merchant/Breadcrumb.vue";

    import customScriptsSaves from "./script-saves.js";
    import customScriptsGeneral from "./script-store-general.js";
    import customScriptsValidations from "./script-validations.js";
    import customScriptsStepStore from "./script-step-store.js";

    export default {
        components: {
            Header,
            Breadcrumb
        },

        data(){
            return Object.assign(
                {},
                customScriptsSaves.data.call(this),
                customScriptsGeneral.data.call(this),
                customScriptsValidations.data.call(this),
                customScriptsStepStore.data.call(this)
            );
        },
        
        async mounted(){
            await customScriptsGeneral.mounted.call(this);
        },

        methods: {
            ...customScriptsGeneral.methods,
            ...customScriptsStepStore.methods,
            ...customScriptsValidations.methods,
            ...customScriptsSaves.methods,
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                deep: true,
                handler() {
                    this.languageSelected();
                    this.orderLangs()
                    this.ordercountries()
                },
                immediate: true
            },

            '$store.state': {
                deep: true,
                handler(newData) {
                    if (
                        newData.allCountries !== undefined && newData.allCountries !== null &&
                        newData.allLanguages !== undefined && newData.allLanguages !== null &&
                        newData.allCurrencies !== undefined && newData.allCurrencies !== null
                    ) {
                        this.dataCountry = newData.allCountries;
                        this.dataLanguage = newData.allLanguages;
                        this.dataCurrency = newData.allCurrencies;
                        this.checkStores();
                    }
                },
                immediate: true
            }
        },

        created() {
            if ( this.$store.state.statusLogin ){
                document.title = `${localStorage.getItem("storeName")} | DNE Market`
            }

            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="@/assets/scss/_merchant-store-company.scss" lang="scss" scoped />