<template>
    <div class="open-filters" v-if="!filters.opened" @click='showFilters()'>
        {{getMyLanguage("Filter","sidebar.title")}}
    </div>

    <aside class="sidebar-filters">
        <!-- <img src="~@/assets/images/brands/sport-zone.png" class="logo"> -->

        <div class="close-filters" v-if="filters.opened" @click='showFilters()'></div>

        <div class="content-filters" :class="{active: filters.opened}">

            <!-- CATEGORIES -->
            <ul class="categories">
                <CategoryList
                v-for="category in dataSource.categories"
                :key="category.id"
                :category="category"
                />
            </ul>

            <!-- <ul class="categories">
                <li v-for="(categs, index) in dataSource.categories" :key="index">
                    <a :href="categs.slug" class="categ" v-if="!categs.subcategories">
                        {{categs.name}}
                    </a>

                    <div class="categ hasChild"
                    :class="{ active: filters.sub[index] }"
                    v-if="categs.subcategories"
                    @click="filters.sub[index] = !filters.sub[index]">
                    
                        {{categs.name}}
                    </div>
                    
                    <ul class="subcategories" v-if="filters.sub[index]">
                        <li v-for="(subcategs, indexSub) in categs.subcategories" :key="indexSub">
                            <a :href="subcategs.slug">
                                {{subcategs.name}}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul> -->


            <ul class="filters" id="result-filters">
                <!-- <h2>{{getMyLanguage("Filter","sidebar.title")}}</h2> -->

                <!-- SEARCH -->
                <li class="search-text" v-if="this.$route.params.query">
                    <h3>{{getMyLanguage("Filter","sidebar.search-text")}}</h3>
                    <p>Informática</p>
                </li>


                <!-- BRANDS -->
                <li class="filter-type" v-if="dataSource.brands">
                    <h3>{{getMyLanguage("Filter","sidebar.brands")}}</h3>
                    <ul class="filter-list" :class="{more: filters.brandsMore}">
                        <li v-for="(brands, index) in dataSource.brands" :key="index">
                            <div class="form-group-check">
                                <input type="checkbox" class="form-check"
                                :id="brands.id"
                                :value="brands.id"
                                @click='checkFilter(brands.id)'
                                :checked="(selectedFilters.brands == brands.id)">

                                <label :for="brands.id" class="form-check-label">
                                    <span>{{brands.name}}</span>
                                    <small class="qtd">{{brands.quantity}}</small>
                                </label>
                            </div>
                        </li>
                    </ul>
                    
                    <div class="btn-more-filter"
                    v-if="dataSource.brands.length > 5 && !filters.brandsMore"
                    @click="filters.brandsMore = true">

                        <span>{{getMyLanguage("Filter","sidebar.bt-more")}}</span>
                    </div>
                </li>


                <!-- TYPES -->
                <li class="filter-type" v-if="dataSource.types">
                    <h3>{{getMyLanguage("Filter","sidebar.type")}}</h3>
                    <ul class="filter-list" :class="{more: filters.typesMore}">
                        <li v-for="(types, index) in dataSource.types" :key="index">
                            <div class="form-group-check">
                                <input type="checkbox" class="form-check"
                                :id="types.id"
                                :value="types.id"
                                @click='checkFilter(types.id)'
                                :checked="(selectedFilters.types == types.id)">

                                <label :for="types.id" class="form-check-label">
                                    <span>
                                        {{ getMyLanguage("Filter","sidebar.type." + types.name) }}
                                    </span>

                                    <small class="qtd">
                                        {{types.quantity}}
                                    </small>
                                </label>
                            </div>
                        </li>
                    </ul>

                    <div class="btn-more-filter"
                    v-if="dataSource.types.length > 5 && !filters.typesMore"
                    @click="filters.typesMore = true">
                        <span>
                            {{getMyLanguage("Filter","sidebar.bt-more")}}
                        </span>
                    </div>
                </li>


                <!-- <li class="filter-region">
                    <h3>{{getMyLanguage("Filter","sidebar.country-region")}}</h3>
                    <ul class="filter-list">
                        <li>
                            <div class="form-group-check">
                                <input type="checkbox" id="countryRegionPortugal" class="form-check" v-model="filters.countryRegion.Portugal" checked>
                                <label for="countryRegionPortugal" class="form-check-label"><span>Portugal</span><small class="qtd">325</small></label>
                            </div>
                        </li>
                        <li>
                            <div class="form-group-check">
                                <input type="checkbox" id="countryRegionEspanha" class="form-check" v-model="filters.countryRegion.Espanha">
                                <label for="countryRegionEspanha" class="form-check-label"><span>Espanha</span><small class="qtd">480</small></label>
                            </div>
                        </li>
                        <li>
                            <div class="form-group-check">
                                <input type="checkbox" id="countryRegionFranca" class="form-check" v-model="filters.countryRegion.Franca">
                                <label for="countryRegionFranca" class="form-check-label"><span>França</span><small class="qtd">382</small></label>
                            </div>
                        </li>
                        <li>
                            <div class="form-group-check">
                                <input type="checkbox" id="countryRegionSuíca" class="form-check" v-model="filters.countryRegion.Suíca">
                                <label for="countryRegionSuíca" class="form-check-label"><span>Suíca</span><small class="qtd">265</small></label>
                            </div>
                        </li>
                        <li>
                            <div class="form-group-check">
                                <input type="checkbox" id="countryRegionItalia" class="form-check" v-model="filters.countryRegion.Italia">
                                <label for="countryRegionItalia" class="form-check-label"><span>Itália</span><small class="qtd">276</small></label>
                            </div>
                        </li>
                    </ul>
                </li> -->

                <li class="filter-price">
                    <h3>{{getMyLanguage("Filter","sidebar.price")}}</h3>
                    
                    <p class="price-min-max">
                        <span>19</span>
                        <span>1738</span>
                    </p>

                    <input type="range" step="1"
                    :min="19"
                    :max="1738"
                    v-model="filters.rangePrice"
                    @touchend="upSummary(dataPurchase.quantity)">

                    <p class="up-to">
                        {{getMyLanguage("Filter","sidebar.price.until")}}&nbsp;
                        {{filters.rangePrice}}
                    </p>
                </li>
            </ul>
        </div>
    </aside>
</template>

<script>
    import CategoryList from "@/components/Filter/CategoryList.vue";
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            CategoryList
        },

        props: {
            dataSource: {},
        },

        data() {
            return {
                openCategories: false,
                subcategories: [],

                filters: {
                    opened: false,
                    
                    sub: {},

                    brands: {},
                    brandsMore: false,

                    types: {},
                    typeMore: false,

                    // countryRegion: {},
                    // countryRegionMore: false,

                    rangePrice: 1738
                },

                selectedFilters: []
                // selectedFilters: {
                //     categories: [],
                //     brands: [],
                //     types: []
                // }
            }
        },

        methods: {
            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            showFilters(){
                if ( this.filters.opened ){
                    this.filters.opened = false
                } else {
                    this.filters.opened = true
                }
            },

            checkFilter(id){
                const index = this.selectedFilters.indexOf(id);
                if (index !== -1) {
                    this.selectedFilters.splice(index, 1);
                } else {
                    this.selectedFilters.push(id);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_dneElements.scss';

    .categories{
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid #ddd;
    }

    .open-filters{
        @media (min-width: 992px){
            display: none;
        }

        @media (max-width: 992px){
            position: fixed;
            z-index: 999;
            right: 5px;
            bottom: 5px;
            height: 36px;
            line-height: 36px;
            font-size: 13px;
            color: $color-text-tertiary;
            padding: 0 30px 0 10px;
            border-radius: 6px;
            background: url('~@/assets/images/icons/filter-white.svg') no-repeat calc(100% - 5px) center $color-primary;
            background-size: 16px auto;
            cursor: pointer;
        }
    }

    .sidebar-filters{
        @media (max-width: 992px){
            // position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }

        @media (min-width: 992px){
            width: 220px;
        }

        .filters{
            // @media (max-width: 992px){
            //     position: fixed;
            //     z-index: 9999;
            //     top: 0;
            //     left: -280px;
            //     width: 280px;
            //     height: 100%;
            //     padding: 20px;
            //     overflow-y: auto;
            //     transition: .6s;
            //     background: $color-text-tertiary;
            // }

            // &.active{
            //     left: 0;
            // }

            .filter-list{
                @include scrollbars(6px, $color-secondary, #ddd);
                padding-right: 8px;
                overflow-y: hidden;
                max-height: 150px;
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 3px;
                }            
                &::-webkit-scrollbar-track {
                    border-radius: 3px;
                }

                &.more{
                    overflow-y: auto;
                    max-height: 315px;
                }
                
            }

            h2{
                margin-bottom: 10px;
            }

            h3{
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 10px;
            }

            .btn-more-filter{
                display: inline-flex;
                align-items: center;
                margin-top: 10px;
                font-size: 13px;
                font-weight: 600;
                line-height: 30px;
                color: $color-secondary;
                padding: 0 10px;
                background: #f5f5f5;
                border-radius: 6px;
                transition: .3s;
                cursor: pointer;

                &:after{
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    margin-left: 5px;
                    background: url('~@/assets/images/icons/see-more.svg') no-repeat center;
                    background-size: 100% auto;
                    transition: .3s;
                }

                @media (min-width: 992px){
                    &:hover{
                        background: #eee;
                        padding: 0 12px;
                    }
                }
            }

            .form-check-label{
                width: 100%;
                justify-content: space-between;
                align-items: flex-start;
                font-size: 14px;
                line-height: 16px;
                min-height: 30px;

                &:before{
                    top: 4px;
                }

                span, small{
                    display: flex;
                    align-items: center;
                    min-height: 30px;
                }

                small{
                    color: $color-secondary;
                }
            }

            .search-text, .filter-category, .filter-type, .filter-region{
                padding-bottom: 25px;
                margin-bottom: 25px;
                border-bottom: 1px solid #ddd;
            }

            .search-text{
                h3, p{
                    margin-bottom: 0;
                }
            }

            .filter-price{
                margin-bottom: 40px;
                
                .price-min-max{
                    display: flex;
                    justify-content: space-between;
                    font-size: 13px;
                    line-height: 15px;
                    margin-bottom: 5px;
                }

                input{
                    margin: 0;
                }

                .up-to{
                    text-align: center;
                    font-size: 13px;
                    margin: 0;
                }
            }
        }


        .logo{
            border: 1px solid #eee;
            padding: 10px;

            @media (max-width: 992px){
                padding: 5px;
                max-width: 120px;
                max-height: 70px;
            }

            @media (min-width: 992px){
                margin-bottom: 30px;
            }
        }

        .close-filters{
            position: fixed;
            z-index: 9995;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: url('~@/assets/images/icons/close-white.svg') no-repeat 290px 30px rgba($color: #000000, $alpha: 0.75);
            background-size: 26px auto;
            animation: filtersClose 1.3s alternate;
        }

        @keyframes filtersClose {
            0% {background-image: none;}
            60% {background-image: none; background-position: 250px 30px;}
            100% {background-image: url('~@/assets/images/icons/close-white.svg'); background-position: 290px 30px;}
        }

        .content-filters{
            @media (max-width: 992px){
                position: fixed;
                z-index: 9999;
                top: 0;
                left: -280px;
                width: 280px;
                height: 100%;
                padding: 20px;
                overflow-y: auto;
                transition: .6s;
                background: $color-text-tertiary;
            }
            
            &.active{
                left: 0;
            }
        }
    }
</style>