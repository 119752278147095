<template>
    <div class="dne-market products">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />
        <div class="load load-product" v-if="!productShow.sku"></div>

        <!-- BREADCRUMB -->
        <div class="breadcrumb" data-aos="fade-up" data-aos-offset="-100" v-if="productShow.sku">
            <div class="container-fluid">
                <ul>
                    <li><router-link :to="{ name: 'Home' }">{{getMyLanguage("Breadcrumb","home")}}</router-link></li>
                    <li><router-link :to="{ name: 'Brand' }">Nike</router-link></li>
                    <!-- <li><router-link :to="{ name: 'Categories' }">Corrida e caminhada</router-link></li> -->
                    <li>{{product.name}}</li>
                </ul>
            </div>
        </div>

        
        <!-- CONTENT PRODUCT -->
        <section class="content-product" v-if="productShow.sku">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-lg-4 product">
                        
                        <!-- GALLERY SLIDER -->
                        <div class="gallery-slider" data-aos="fade-up" data-aos-offset="-100">
                            <Carousel id="gallery" :items-to-show="1" :wrap-around="false" v-model="currentSlide">
                                <Slide v-for="(slider, slide) in productShow.sku.gallery" :key="slide">
                                    <div class="carousel__item">
                                        <div class="photo" @click="zoom(slider)">
                                            <img :src='"/images/items/" + slider'>
                                        </div>
                                    </div>
                                </Slide>
                            </Carousel>

                            <Carousel id="thumbnails" :items-to-show="4" :wrap-around="true" v-model="currentSlide" ref="carousel">
                                <Slide v-for="(slider, slide) in productShow.sku.gallery" :key="slide">
                                    <div class="carousel__item" @click="slideTo(slide)">
                                        <img :src='"/images/items/" + slider'>
                                    </div>
                                </Slide>
                            </Carousel>
                        </div>


                        <!-- PRODUCT -->
                        <div class="cont-product">
                            <h1 data-aos="fade-up" data-aos-offset="-100">{{product.name}} - {{productShow.sku.model}}</h1>
                            <router-link :to="{ name: 'Category', params: { id: '123', pageTitle: product.category } }" class="category" data-aos="fade-up">{{product.category}}</router-link>
                            <div class="short-description" data-aos="fade-up" data-aos-offset="-100">{{product.shortDescription}}</div>
                            
                            <div class="availability" data-aos="fade-up" data-aos-offset="-100">
                                <div class="available" v-if='productShow.sku.availability == "available"'>
                                    <p><strong>{{getMyLanguage("Product","availability.available.lbl")}}</strong></p>
                                    <p>{{getMyLanguage("Product","availability.available.txt")}}&nbsp;<strong>{{productShow.dateDelivery}}</strong></p>
                                </div>

                                <div class="unavailable" v-if='productShow.sku.availability == "unavailable"'>
                                    <p>{{getMyLanguage("Product","availability.unavailable.lbl")}}</p>
                                </div>

                                <div class="alert-stock" :class="{active: alertStock.includes(productShow.sku.skuId)}" v-if='productShow.sku.availability == "out-of-stock"'>
                                    <p>{{getMyLanguage("Product","availability.out-of-stock.lbl")}}</p>
                                    <div class="btn-alert" v-if="!alertStock.includes(productShow.sku.skuId)" @click='itemAlertStock(productShow.sku.skuId,"add")'>{{getMyLanguage("Product","availability.out-of-stock.bt-alert")}}</div>
                                    <div class="active-alert" v-if="alertStock.includes(productShow.sku.skuId)">
                                        <strong>{{getMyLanguage("Product","availability.out-of-stock.active-alert")}}</strong>
                                        <span @click='itemAlertStock(productShow.sku.skuId,"remove")'>{{getMyLanguage("Product","availability.out-of-stock.bt-alert-remove")}}</span>
                                    </div>
                                </div>

                                <div class="order" v-if='productShow.sku.availability == "order"'>
                                    <p><strong>{{getMyLanguage("Product","availability.order.lbl")}}</strong></p>
                                    <p>{{getMyLanguage("Product","availability.order.txt")}}&nbsp;<strong>25/03/2023</strong></p>
                                </div>
                            </div>


                            <!-- ADD CART -->
                            <div class="add-cart">
                                <div class="price-variables" data-aos="fade-up" data-aos-offset="-100">
                                    <div class="values">
                                        <div class="old-price">
                                            <del>{{productShow.sku.oldPrice.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</del>
                                            <small>-{{productShow.sku.discount}}%</small>
                                        </div>

                                        <div class="price">
                                            {{productShow.sku.price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                        </div>

                                        <div class="delivery">
                                            <span>{{productShow.priceDelivery.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}</span> /&nbsp;{{getMyLanguage("Product","add-cart.values.delivery")}}
                                        </div>
                                    </div>

                                    <div class="variables">
                                        <p class="lbl">{{getMyLanguage("Product","add-cart.variables.colors")}}</p>
                                        <div class="options">
                                            <div v-for="(listSkus, index) in product.skus" :key="index">
                                                <input type="radio" name="skus" :value="listSkus.id" :id="listSkus.id" :checked="listSkus.id == productShow.sku.skuId">
                                                <label :for="listSkus.id" @click='changeSKU(listSkus.id,productShow.sku.sizeId)'>
                                                    <img :src='"/images/items/" + listSkus.thumbs'>
                                                </label>
                                            </div>
                                        </div>

                                        <p class="lbl">{{getMyLanguage("Product","add-cart.variables.sizes")}}</p>
                                        <div class="options">
                                            <div v-for="(listSizes, index) in product.sizes" :key="index">
                                                <input type="radio" name="sizes" :value="listSizes.id" :id="listSizes.id" :checked="listSizes.id == productShow.sku.sizeId">
                                                <label :class="product.sku.filter(SIZE => SIZE.sizeId === listSizes.id)[0].availability" :for="listSizes.id" @click='changeSize(productShow.sku.skuId,listSizes.id)'>
                                                    <span>{{listSizes.lbl}}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="add-to-cart" data-aos="fade-up" data-aos-offset="-100" v-if='productShow.sku.availability === "available" || productShow.sku.availability === "order"'>
                                    <div class="quantity">
                                        <div class="qtd">
                                            <div class="less" @click='qtdPurchase("less")'>-</div>
                                            <div class="items">{{qtd}}</div>
                                            <div class="more" @click='qtdPurchase("more")'>+</div>
                                            <div class="tooltip" v-if="tooltipStock">{{getMyLanguage("Product","add-cart.tooltip.max")}}&nbsp;{{productShow.sku.stock}}</div>
                                        </div>
                                    </div>

                                    <div class="btn-primary btn-add-cart" @click="addToCart()">
                                        <span>{{getMyLanguage("Product","add-cart.bt-add-cart")}}</span>
                                    </div>
                                </div>
                            </div>


                            <!-- SELLER -->
                            <div class="row seller" data-aos="fade-up" data-aos-offset="-100">
                                
                                <router-link :to="{ name:'Brand', params: { id: '123', pageTitle: 'Nike' } }" class="logo-seller">
                                    <img src="~@/assets/images/brands/nike.svg" alt="Nike">
                                </router-link>
                                
                                <div class="inf-seller">
                                    <div>
                                        <p class="lbl">{{getMyLanguage("Product","store.lbl")}}</p>
                                        <p><router-link :to="{ name:'StoreTemp', params: { id: '123', pageTitle: 'Sport Zone'} }"><strong>Sport Zone</strong></router-link></p>
                                        <p>1258&nbsp;{{getMyLanguage("Product","store.products")}}</p>
                                    </div>
                                </div>
                            </div>


                            <!-- SHARE / WISHLIST -->
                            <div class="share-wishlist" data-aos="fade-up" data-aos-offset="-100">

                                <div class="share">
                                    <p class="lbl">{{getMyLanguage("Product","share.lbl")}}</p>

                                    <div class="lnks">
                                        <div class="whatsapp"></div>
                                        <div class="facebook"></div>
                                        <div class="instagram"></div>
                                        <div class="twitter"></div>
                                    </div>
                                </div>

                                <div class="wishlist">
                                    <p class="lbl">{{getMyLanguage("Product","wishlist.lbl")}}</p>
                                    <div class="icon" :class="{selected: wishlist}" @click='wishList("nome do produto")'></div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 col-lg-8" data-aos="fade-up" data-aos-offset="-100">

                        <!-- GALLERY ZOOM -->
                        <div class="gallery-zoom" alt="aaa" data-gallery="7">
                            <div class="photo" v-for="(galleryZoom, index) in productShow.sku.gallery" :key="index" @click="zoom(galleryZoom)">
                                <img :src='"/images/items/" + galleryZoom'>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- DETAILS -->
                <div class="row details" data-aos="fade-up" data-aos-offset="-100">
                    <div class="col-12">
                        <h3 class="title-details"><strong>{{getMyLanguage("Product","details.title")}}</strong></h3>
                        <div class="topics">
                            <p>Número do produto: DO2408-739</p>
                            <p>Sexo: Unisexo</p>
                            <p>Tecnologia: ZoomX</p>
                            <p>Tipo de corrida: Corrida Rápida</p>
                            <p>Desporto: Corrida</p>
                            <p>Drop (mm): 8</p>
                            <p>Conforto e amortecimento: Alto</p>
                            <p>Coleção: Nike Ekiden Pack</p>
                            <p>Fabricante: Nike</p>
                            <p>Tipo de calçado: Neutro, Supinação</p>
                            <p>Superfície: Estrada</p>
                            <p>Modelo: Nike ZoomX Vaporfly Next%</p>
                            <p>Função: Respirável, Carbono</p>
                            <p>Largura da sapatilha: Padrão</p>
                            <p>Cor: Amarelo</p>
                            <p>Garantia: 2 anos</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- RELATED PRODUCTS -->
        <section class="featured-products related-products" v-if="productShow.sku">
            <h2 class="title-secondary" data-aos="fade-up" data-aos-offset="-100">{{getMyLanguage("FeaturedProducts","related-products.title")}}</h2>

            <div class="container-fluid">
                <ShelfTemplateProductSlider :dataSource="relatedProducts" />
            </div>
        </section>


        <!-- CONTENT SERVICE -->
        <section class="content-service" v-if="productShow.sku">
            
        </section>

        <LegalPolicies />
    </div>


    <!-- MODAL ZOOM -->
    <div class="modal modal-zoom" v-if="modal.zoom === true">
        <div class="modal-cont">
            <div class="modal-close" @click='modal.zoom = false,modal.image = ""'></div>
            <inner-image-zoom :src='"/images/items/" + modal.image' v-if='modal.image != ""' />
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import ShelfTemplateProductSlider from "@/components/Shelves/ShelfTemplateProductSlider.vue";
    import LegalPolicies from "@/components/Essentials/LegalPolicies.vue";

    import 'vue3-carousel/dist/carousel.css'
    import { defineComponent } from 'vue'
    import { Carousel, Slide } from 'vue3-carousel'

    import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
    import InnerImageZoom from 'vue-inner-image-zoom'

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default defineComponent({
        components: {
            Header,
            ShelfTemplateProductSlider,
            LegalPolicies,
            
            Carousel,
            Slide,
            // Navigation

            InnerImageZoom
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "search",

                currentSlide: 0,

                productShow: {
                    sku: "",
                    size: "",
                    dateDelivery: "15/09/2022",
                    priceDelivery: 6.25
                },
                
                product: {
                    id: "12344312",
                    name: "Nike ZoomX Vaporfly Next% 2",
                    category: "Corrida e caminhada",
                    shortDescription: "Dá o próximo passo na evolução da velocidade com as sapatilhas de competição criadas para te ajudar a lutar por novos objetivos e atingir recordes. Ajudam a melhorar o conforto e a respirabilidade com uma parte superior redesenhada. Desde uma corrida de 10 quilómetros até uma maratona, este modelo conta com amortecimento reativo e suporte seguro, tal como a versão anterior, para que superes os teus limites.",
                    sizes: [
                        {
                            id: "s1",
                            lbl: "41"
                        },
                        {
                            id: "s2",
                            lbl: "42"
                        },
                        {
                            id: "s3",
                            lbl: "43"
                        },
                        {
                            id: "s4",
                            lbl: "44"
                        }
                    ],
                    skus: [
                        {
                            id: "111",
                            lbl: "Yellow",
                            thumbs: "nike-zoomx-yellow-thumbs.jpg"
                        },
                        {
                            id: "222",
                            lbl: "Red",
                            thumbs: "nike-zoomx-red-thumbs.jpg"
                        },
                        {
                            id: "333",
                            lbl: "Blue",
                            thumbs: "nike-zoomx-blue-thumbs.jpg"
                        }
                    ],
                    sku: [
                        {
                            skuId: "111",
                            model: "Yellow",
                            sizeId: "s1",
                            size: "41",
                            discount: 11,
                            oldPrice: 323.75,
                            price: 289.65,
                            stock: 12,
                            availability: "available",
                            gallery: ["nike-zoomx-yellow-01.jpg","nike-zoomx-yellow-02.jpg","nike-zoomx-yellow-03.jpg","nike-zoomx-yellow-04.jpg","nike-zoomx-yellow-05.jpg","nike-zoomx-yellow-06.jpg","nike-zoomx-yellow-07.jpg"]
                        },
                        {
                            skuId: "111",
                            model: "Yellow",
                            sizeId: "s2",
                            size: "42",
                            discount: 11,
                            oldPrice: 348.65,
                            price: 314.78,
                            stock: 0,
                            availability: "unavailable",
                            gallery: ["nike-zoomx-yellow-01.jpg","nike-zoomx-yellow-02.jpg","nike-zoomx-yellow-03.jpg","nike-zoomx-yellow-04.jpg","nike-zoomx-yellow-05.jpg","nike-zoomx-yellow-06.jpg","nike-zoomx-yellow-07.jpg"]
                        },
                        {
                            skuId: "111",
                            model: "Yellow",
                            sizeId: "s3",
                            size: "43",
                            discount: 11,
                            oldPrice: 348.65,
                            price: 314.78,
                            stock: 0,
                            availability: "out-of-stock",
                            gallery: ["nike-zoomx-yellow-01.jpg","nike-zoomx-yellow-02.jpg","nike-zoomx-yellow-03.jpg","nike-zoomx-yellow-04.jpg","nike-zoomx-yellow-05.jpg","nike-zoomx-yellow-06.jpg","nike-zoomx-yellow-07.jpg"]
                        },
                        {
                            skuId: "111",
                            model: "Yellow",
                            sizeId: "s4",
                            size: "44",
                            discount: 11,
                            oldPrice: 348.65,
                            price: 314.78,
                            stock: 10,
                            availability: "order",
                            gallery: ["nike-zoomx-yellow-01.jpg","nike-zoomx-yellow-02.jpg","nike-zoomx-yellow-03.jpg","nike-zoomx-yellow-04.jpg","nike-zoomx-yellow-05.jpg","nike-zoomx-yellow-06.jpg","nike-zoomx-yellow-07.jpg"]
                        },
                        {
                            skuId: "222",
                            model: "Red",
                            sizeId: "s1",
                            size: "41",
                            discount: 11,
                            oldPrice: 343.56,
                            price: 309.52,
                            stock: 2,
                            availability: "available",
                            gallery: ["nike-zoomx-red-01.jpg","nike-zoomx-red-02.jpg","nike-zoomx-red-03.jpg","nike-zoomx-red-04.jpg","nike-zoomx-red-05.jpg","nike-zoomx-red-06.jpg","nike-zoomx-red-07.jpg"]
                        },
                        {
                            skuId: "222",
                            model: "Red",
                            sizeId: "s2",
                            size: "42",
                            discount: 11,
                            oldPrice: 343.56,
                            price: 309.52,
                            stock: 10,
                            availability: "available",
                            gallery: ["nike-zoomx-red-01.jpg","nike-zoomx-red-02.jpg","nike-zoomx-red-03.jpg","nike-zoomx-red-04.jpg","nike-zoomx-red-05.jpg","nike-zoomx-red-06.jpg","nike-zoomx-red-07.jpg"]
                        },
                        {
                            skuId: "222",
                            model: "Red",
                            sizeId: "s3",
                            size: "43",
                            discount: 11,
                            oldPrice: 343.56,
                            price: 309.52,
                            stock: 0,
                            availability: "unavailable",
                            gallery: ["nike-zoomx-red-01.jpg","nike-zoomx-red-02.jpg","nike-zoomx-red-03.jpg","nike-zoomx-red-04.jpg","nike-zoomx-red-05.jpg","nike-zoomx-red-06.jpg","nike-zoomx-red-07.jpg"]
                        },
                        {
                            skuId: "222",
                            model: "Red",
                            sizeId: "s4",
                            size: "44",
                            discount: 11,
                            oldPrice: 343.56,
                            price: 309.52,
                            stock: 0,
                            availability: "unavailable",
                            gallery: ["nike-zoomx-red-01.jpg","nike-zoomx-red-02.jpg","nike-zoomx-red-03.jpg","nike-zoomx-red-04.jpg","nike-zoomx-red-05.jpg","nike-zoomx-red-06.jpg","nike-zoomx-red-07.jpg"]
                        },
                        {
                            skuId: "333",
                            model: "Blue",
                            sizeId: "s1",
                            size: "41",
                            discount: 11,
                            oldPrice: 343.56,
                            price: 309.52,
                            stock: 4,
                            availability: "available",
                            gallery: ["nike-zoomx-blue-01.jpg","nike-zoomx-blue-02.jpg","nike-zoomx-blue-03.jpg","nike-zoomx-blue-04.jpg","nike-zoomx-blue-05.jpg","nike-zoomx-blue-06.jpg","nike-zoomx-blue-07.jpg"]
                        },
                        {
                            skuId: "333",
                            model: "Blue",
                            sizeId: "s2",
                            size: "42",
                            discount: 11,
                            oldPrice: 343.56,
                            price: 309.52,
                            stock: 0,
                            availability: "out-of-stock",
                            gallery: ["nike-zoomx-blue-01.jpg","nike-zoomx-blue-02.jpg","nike-zoomx-blue-03.jpg","nike-zoomx-blue-04.jpg","nike-zoomx-blue-05.jpg","nike-zoomx-blue-06.jpg","nike-zoomx-blue-07.jpg"]
                        },
                        {
                            skuId: "333",
                            model: "Blue",
                            sizeId: "s3",
                            size: "43",
                            discount: 11,
                            oldPrice: 343.56,
                            price: 309.52,
                            stock: 9,
                            availability: "available",
                            gallery: ["nike-zoomx-blue-01.jpg","nike-zoomx-blue-02.jpg","nike-zoomx-blue-03.jpg","nike-zoomx-blue-04.jpg","nike-zoomx-blue-05.jpg","nike-zoomx-blue-06.jpg","nike-zoomx-blue-07.jpg"]
                        },
                        {
                            skuId: "333",
                            model: "Blue",
                            sizeId: "s4",
                            size: "44",
                            discount: 11,
                            oldPrice: 343.56,
                            price: 309.52,
                            stock: 9,
                            availability: "available",
                            gallery: ["nike-zoomx-blue-01.jpg","nike-zoomx-blue-02.jpg","nike-zoomx-blue-03.jpg","nike-zoomx-blue-04.jpg","nike-zoomx-blue-05.jpg","nike-zoomx-blue-06.jpg","nike-zoomx-blue-07.jpg"]
                        },
                    ]
                },

                qtd: 1,
                stock: 8,
                tooltipStock: false,
                alertStock: [""],

                wishlist: false,

                modal: {
                    zoom: false,
                    image: ""
                },


                relatedProducts: [
                    {
                        url: "",
                        name: "Casaco de inverno",
                        image: "casaco-inverno-tommy-jeans.jpg",
                        store: "C&A",
                        brand: "Tommy Jeans",
                        price: 298,
                        discount: "11",
                        highlights: ["free-shipping"]
                    },
                    {
                        url: "",
                        name: "Sweat - Preto",
                        image: "sweat-preto.jpg",
                        store: "Nike Store",
                        brand: "Nike",
                        price: 69,
                        discount: "",
                        highlights: ["free-shipping","to-order"]
                    },
                    {
                        url: "",
                        name: "Blusão com capuz Therma-FIT Repel Windrunner - Bege",
                        image: "blusao-com-capuz.jpg",
                        store: "Nike Store",
                        brand: "Nike",
                        price: 179,
                        discount: "23",
                        highlights: []
                    },
                    {
                        url: "",
                        name: "iPhone 13 recondicionado - Lumière stellaire - 128 GB",
                        image: "iphone-13.jpg",
                        store: "NOS",
                        brand: "Apple",
                        price: 1738,
                        discount: "5",
                        highlights: []
                    },
                    {
                        url: "",
                        name: "Blusão com capuz Therma-FIT Repel Windrunner - Bege",
                        image: "blusao-com-capuz.jpg",
                        store: "Nike Store",
                        brand: "Nike",
                        price: 179,
                        discount: "",
                        highlights: ["free-shipping"]
                    },
                    {
                        url: "",
                        name: "iPhone 13 recondicionado - Lumière stellaire - 128 GB",
                        image: "iphone-13.jpg",
                        store: "NOS",
                        brand: "Apple",
                        price: 1738,
                        discount: "",
                        highlights: []
                    },
                    {
                        url: "",
                        name: "Casaco de inverno",
                        image: "casaco-inverno-tommy-jeans.jpg",
                        store: "C&A",
                        brand: "Tommy Jeans",
                        price: 321,
                        discount: "",
                        highlights: []
                    }
                ]
            };
        },

        async mounted() {
            this.initialProduct()
            this.languageSelected()
            this.goTop()
        },

        methods: {
            initialProduct(){
                setTimeout(() => {
                    this.productShow.sku = this.product.sku[0]
                    this.goTop()
                    // document.title = this.product.name + " - " + this.productShow.sku.model + " - DNE Market"
                }, 500);

            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},0);
                return false;
            },

            slideTo(val) {
                this.currentSlide = val
                console.log(this.currentSlide)
            },

            zoom(value) {
                this.modal.zoom = true

                setTimeout(() => {
                    this.modal.image = value
                }, 500);
            },

            changeSKU(valueSKU,valueSize){
                // this.productShow.sku = this.product.sku.filter(SKU => SKU.skuId === valueSKU)[0] && this.product.sku.filter(SIZE => SIZE.sizeId === valueSize)[0]
                this.productShow.sku = this.product.sku.filter(SKU => SKU.skuId === valueSKU)[0]
                console.log(valueSize)
                this.qtd = 1
                $('html, body').animate({scrollTop : 0},600);

                document.title = this.product.name + " - " + this.productShow.sku.model + " - DNE Market"
            },
            
            changeSize(valueSKU,valueSize){
                console.log(valueSKU)
                this.qtd = 1
                // this.productShow.sku = this.product.sku.filter(SKU => SKU.skuId === valueSKU)[0] && this.product.sku.filter(SIZE => SIZE.sizeId === valueSize)[0]
                this.productShow.sku = this.product.sku.filter(SIZE => SIZE.sizeId === valueSize)[0]
            },

            qtdPurchase(operation){
                if ( operation === "more" && this.qtd < this.productShow.sku.stock ){
                    this.qtd = this.qtd + 1
                }

                if ( operation === "less" && this.qtd > 1 ){
                    this.qtd = this.qtd - 1
                }

                if ( operation === "more" && this.qtd == this.productShow.sku.stock ){
                    this.tooltipStock = true
                    
                    setTimeout(() => {
                        this.tooltipStock = false
                    }, 2000);
                }
            },

            itemAlertStock(value,action){
                if ( action === "add" ){
                    this.alertStock.push(value)
                } else if ( action === "remove" ){
                    this.alertStock.splice(this.alertStock.indexOf(value), 1)
                } else{
                    console.log("Alert unavailable")
                }
            },

            addToCart(){
                // this.emitter.emit('my-event', {'eventContent': 'String changed'})
                // this.$emit('add-cart',"foi")

                // this.$store.dispatch('addCart',"aaa")
                console.log(this.$store.state.cart)

                this.$toast.success(this.getMyLanguage("Product","add-cart.tooltip"));
                setTimeout(this.$toast.clear, 6000)
            },

            wishList(name) {
                if ( this.wishlist === true ){
                    this.wishlist = false
                } else{
                    this.wishlist = true
                }

                this.$emit('addWish', name)
            },

            addWishlist(name){
                console.log(name)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            // document.title = this.product.name
            // document.head.querySelector("meta[name=description]").content = this.getMyLanguage("seo", "title.description.home");
        },
    });
</script>

<style src="./custom-product_bkp.scss" lang="scss" scoped />

<style>
    .modal-zoom .modal-cont .iiz__close{top: auto; bottom: 10px;}
</style>