<template>
    <div class="account">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- BREADCRUMB -->
        <Breadcrumb />

        
        <!-- CONTENT -->
        <section class="content-purchases">
            <div class="container-fluid">
                <h2 class="title-secondary">
                    {{getMyLanguage("Account","purchases.title")}}
                </h2>

                <div class="load" v-if="load"></div>

                <div class="row" v-if="errorsGet.dataPurchases != false">
                    <div class="col-12">
                        <div class="box box-error">
                            <h3 class="title-secondary">
                                {{getMyLanguage("box-error","title-error")}}
                            </h3>

                            <p v-if='errorsGet.dataPurchases != "401"'>
                                {{getMyLanguage("box-error","msg-error")}}
                            </p>

                            <p v-if='errorsGet.dataPurchases == "401"'>
                                {{getMyLanguage("box-error","401")}}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row" data-aos="fade-up" data-aos-offset="-100" v-if="!load && !errorsGet.dataPurchases">
                    <div class="col-12">
                        <div class="purchases">
                            
                            <div class="purchase"
                            v-for="(purchase, indexPurchase) in dataPurchases"
                            :key="indexPurchase"
                            :id="`purchase${indexPurchase}`">

                                <div class="order">
                                    <div class="header-print">
                                        <div class="logo"></div>

                                        <div class="content">
                                            <div class="dne-market">
                                                <p>
                                                    <strong>{{getMyLanguage("Account","purchases.purchase.company-name")}}</strong>
                                                </p>

                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                                            </div>

                                            <div class="shipping">
                                                <p>
                                                    <strong>{{getMyLanguage("Account","purchases.purchase.buyers-name")}}</strong>
                                                </p>

                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="header-purchase">
                                        <div class="reference">
                                            <small>{{getMyLanguage("Account","purchases.purchase.reference")}}</small>
                                            <strong>{{purchase.reference}}</strong>
                                        </div>
                                        <div class="date">
                                            <small>{{getMyLanguage("Account","purchases.purchase.purchase-date")}}</small>
                                            <strong>{{dateTime(purchase.created_at)}}</strong>
                                        </div>
                                        <div class="status">
                                            <small>{{getMyLanguage("Account","purchases.purchase.status")}}</small>
                                            <strong>{{purchase.status}}</strong>
                                        </div>
                                        <a :href="`/${purchase.store.slug}`" class="seller">
                                            <small>{{getMyLanguage("Account","purchases.purchase.sold-by")}}</small>
                                            <strong>{{purchase.store.name}}</strong>
                                        </a>
                                    </div>

                                    <a :href="`/${item.product.slug}`" class="items" v-for="(item, indexItem) in purchase.items" :key="indexItem">
                                        <div class="item">
                                            <div class="photo">
                                                <img :src="item.product.attachment">
                                            </div>
                                            <div class="details">
                                                <p class="name">{{item.product.name}}</p>
                                                <div class="price">
                                                    <span>
                                                        {{item.price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                        <i></i>
                                                    </span>

                                                    <small v-if="(item.quantity = 1)">
                                                        {{item.quantity}} {{getMyLanguage("Account","purchases.purchase.item")}}
                                                    </small>

                                                    <small v-if="(item.quantity > 1)">
                                                        {{item.quantity}} {{getMyLanguage("Account","purchases.purchase.items")}}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div class="resume-purchase">
                                    <div class="download-pdf">
                                        <div @click="pdfPurchaseSingle(purchase.reference, indexPurchase)" class="btn-primary" v-html='getMyLanguage("Btn","download-pdf")'></div>
                                    </div>

                                    <table class="box-summary">
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.products")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    {{purchase.summary.items_total.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.discount")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    {{purchase.summary.discount.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.subtotal")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    {{purchase.summary.subtotal.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.shipping")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    {{purchase.summary.shipping.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.tax")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    {{purchase.summary.tax.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.fee")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    {{purchase.summary.fee.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.total")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    {{purchase.summary.total.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>
                                                <strong>{{getMyLanguage("Account","purchases.summary.cashback")}}</strong>
                                            </td>

                                            <td>
                                                <span>
                                                    {{purchase.summary.cashback.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import Breadcrumb from "@/components/Account/Breadcrumb.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMarket from '@/config/apiMarket.js'

    import moment from 'moment';

    export default {
        components: {
            Header,
            Breadcrumb
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "account",
                load: true,
                dataPurchases: {},

                errorsGet: {
                    dataPurchases: false
                }
            }
        },

        async mounted() {
            this.languageSelected()

            await apiMarket.get('/api/v1/market/order/filter')
            .then(response => {
                this.dataPurchases = response.data
                
                setTimeout(() => {
                    this.load = false
                }, 500);
            })
            .catch(error => {
                this.errorsGet.dataPurchases = error.response.status

                setTimeout(() => {
                    this.load = false
                }, 500);
            })
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="./custom-profile.scss" lang="scss" scoped />