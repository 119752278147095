import axios from 'axios';

const token = localStorage.getItem('accessToken');
let code = ""
let ticker = ""
let storeReference = 0

if ( localStorage.getItem('storeReference') === "" || localStorage.getItem('storeReference') === null ) {
    storeReference = localStorage.setItem("storeReference", 0)
} else {
    storeReference = localStorage.getItem('storeReference')
}

if ( localStorage.getItem('codeLanguage') === "" || localStorage.getItem('codeLanguage') === null ) {
    code = "en"
} else {
    code = localStorage.getItem('codeLanguage')
}

if ( localStorage.getItem('instrumentTicker') === "" || localStorage.getItem('instrumentTicker') === null ) {
    ticker = "DNEEUR"
} else {
    ticker = localStorage.getItem('instrumentTicker')
}

const apiMarket = axios.create({
    baseURL: process.env.VUE_APP_MARKET_API,
    headers: { 'Authorization': `bearer ${token}`, 'Content-Language': code, 'Instrument-Ticker': ticker, 'store-reference': storeReference, 'X-Client-Identifier': "DneMarket" }
});

export default apiMarket;