<template>
    <CookieConsent
        :message= texts.message
        :link-label= texts.linkLabel
        :buttonLabel= texts.btnLabel
        :href= urlPrivacy
    />
</template>

<script>
    import CookieConsent from 'vue-cookieconsent-component/src/components/CookieConsent.vue'
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            CookieConsent
        },

        data (){
            return{
                language: "",
                urlPrivacy: '',
                texts: {
                    message: 'Cookies',
                    linkLabel: '',
                    btnLabel: ''
                }
            }
        },

        methods: {
            privacyUrl(){
                this.urlPrivacy = `/${this.language}/privacy`
            },

            getMyLanguage(group, code){
               return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            cookieTexts(){
                // let textsMessage = this.getMyLanguage("CookieConsent", "cookie.consent.message")
                let textsLinkLabel = this.getMyLanguage("CookieConsent", "cookie.consent.link.label")
                let textsBtnLabel = this.getMyLanguage("CookieConsent", "cookie.consent.btn.label")

                // this.texts.message = textsMessage
                this.texts.linkLabel = textsLinkLabel
                this.texts.btnLabel = textsBtnLabel
            }
        },

        created() {
            this.privacyUrl();
            this.cookieTexts()
        },

        watch:{
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.language = this.$store.state.SelectLanguage.code
                    this.privacyUrl();
                    this.cookieTexts()
                    this.getMyLanguage();
                }
            }
        }
    }
</script>



<style lang="scss">
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';
    @import '@/assets/scss/_dneElements.scss';
    @import '@/assets/scss/_buttons.scss';


    .cookie-consent{
        @include shadown;
        
        position: fixed;
        z-index: 99999;
        left: 5px;
        bottom: 5px;
        padding: 18px;
        border: 2px solid rgba($color: $color-text-tertiary, $alpha: 0.25);
        text-align: center;
        border-radius: 16px;
        background: $color-primary;
        color: $color-text-tertiary;

        .cookie-consent-message{
            display: block;
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 30px;

            .cookie-consent-link{
                font-size: 14px;
                line-height: 30px;
                font-weight: 400;
                padding: 0 10px;
                border: 1px solid rgba($color: $color-text-tertiary, $alpha: 0.3);
                color: $color-text-tertiary;
                border-radius: 15px;
                display: block;
                margin-top: 10px;
            }
        }
        
        .cookie-consent-compliance{
            background: $color-secondary;
            color: $color-text-tertiary;
            display: block;
            white-space: nowrap;
            border-radius: 12px;
            transition: .3s;
            border: none;
            line-height: 40px;
            height: 40px;
            font-weight: 600;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;

            @media (max-width: 576px){
                font-size: 14px;
                padding: 0 15px;
            }
        
            @media (min-width: 576px){
                font-size: 16px;
                padding: 0 20px;
            }
        }
    }
</style>