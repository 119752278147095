<template>
    <header class="header-store"
    :class="{center: !dataSource.store_details}"
    :style="bannerStyle" v-if="dataSource.slug">

        <a :href="`/${dataSource.slug}`" class="logo">
            <img class="store-logo"
            :src="dataSource.logo_attachment"
            :class="{storeLogoBox: dataSource.logo_attachment_box}"
            :style="{ background: dataSource.logo_attachment_box ? dataSource.logo_attachment_hexa : 'none' }">

            <h2 class="store-name">
                {{dataSource.name}}
            </h2>
        </a>

        <span class="contact" v-if="dataSource.store_details">
            <!-- <span class="address">
                {{dataSource.store_details.address}}
            </span> -->

        
            <div class="store-favorite">
                <div class="favorite">
                    <!-- <div class="icon" :class="{selected: dataProductVariant.wishlist}" @click="addWishlist(dataProductVariant.Id,dataProductVariant.wishlist)"></div> -->
                    <div class="icon" @click="addFavorite(dataSource.reference,dataSource.name)"></div>
                </div>
                
                <span class="open-contact" @click="modal = true">
                    Ver mais
                </span>
            </div>
            
            <Share :dataSourceStore="shareStore" :dataSourceOrigin="shareOrigin" />
        </span>
    </header>

    
    <!-- MODAL HEADER STORE -->
    <div class="modal modal-header-store" v-if="modal">
        <div class="modal-cont">
            <div class="modal-close" @click="modal = false"></div>
            <div class="modal-scroll">
                <h3 class="title-secondary">{{dataSource.name}}</h3>
                
                <!-- CONTACT -->
                <div class="box-address">
                    <!-- <p class="address">
                        <span>
                            {{dataSource.store_details.address}}<br>
                            {{dataSource.store_details.region}}
                        </span>
                    </p> -->

                    <p class="hour" v-if="dataSource.store_details.business_hours">
                        <span v-for="(hour, index) in dataSource.store_details.business_hours" :key="index">{{hour}}</span>
                    </p>

                    <p class="phone" v-if="dataSource.store_details.phone_number_1 || dataSource.store_details.phone_number_2">
                        <span v-if="dataSource.store_details.phone_number_1">{{dataSource.store_details.phone_number_1}}</span>
                        <span v-if="dataSource.store_details.phone_number_2">{{dataSource.store_details.phone_number_2}}</span>
                    </p>

                    <p class="email" v-if="dataSource.store_details.email">
                        <span>{{dataSource.store_details.email}}</span>
                    </p>
                </div>

                <!-- MAP -->
                <div class="map-store">
                    <iframe :src="`https://maps.google.com/maps?q=${dataSource.store_details.lat_long}&hl=es;z=14&amp;output=embed`" frameborder="0"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Share from "@/components/Essentials/Share.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            Share
        },

        props: {
            dataSource: {}
        },

        data(){
            return {
                VUE_APP_MARKET_URL: process.env.VUE_APP_MARKET_URL,

                modal: false,
                
                shareStore: {
                    url: "",
                    title: "",
                    price: "",
                    attachment: "",
                    description: "",
                },
                
                shareOrigin: "HeaderStore",

                bannerStyle: ""
            }
        },

        async mounted(){
            setTimeout(() => {
                this.shareStore.url = `${this.VUE_APP_MARKET_URL}${this.dataSource.slug}`
                this.shareStore.title = this.dataSource.name
                this.shareStore.attachment = "https://dneecosystem.com/dne-platform.png"
                // this.shareStore.description = `${this.dataSource.store_details.address}/${this.dataSource.store_details.region}`

                if ( this.dataSource.banner_attachment ){
                    this.bannerStyle = `background: url(${this.dataSource.banner_attachment})`
                } else {
                    this.bannerStyle = 'background: #1f3561'
                }
            }, 1000);
        },

        methods: {
            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            addFavorite(reference,name){
                console.log(reference)
                console.log(name)
                this.$toast.success(`${name} adicionada aos favoritos`)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    const flagSelect = String(this.shareStore.url).split("/")[3];
                    
                    const newUrl = this.$store.state.SelectLanguage.code;
                    const newLangage = flagSelect;

                    this.shareStore.url = this.shareStore.url.replace(new RegExp(`/${newLangage}/`), `/${newUrl}/`);
                }
            }
        }
    }
</script>

<style src="@/assets/scss/_header-store.scss" lang="scss" scoped />