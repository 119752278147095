<template>
    <a :href="`/${flagSelected}/merchant/products/add`" class="item add">
        <h3>ADD</h3>
    </a>

    <div class="item" v-for="(shelfProduct, index) in dataSource" :key="index">
        <a :href="`/${flagSelected}/merchant/products/manager/${shelfProduct.reference}`">
            <div class="photo">
                <img :src='shelfProduct.product_variants[0].attachments[0].attachment' :alt="shelfProduct.name">
            </div>

            <div class="details">
                <h3 class="name">
                    {{shelfProduct.name}}&nbsp;<small>{{shelfProduct.brand.name}}</small>
                </h3>

                <!-- <div class="price">
                    <span>{{shelfProduct.price}}</span>
                </div> -->
            </div>
        </a>
    </div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        props: {
            dataSource: {}
        },

        data () {
            return {
                VUE_APP_IMAGE_URL: process.env.VUE_APP_IMAGE_URL,
                flagSelected: "en",
            }
        },

        async mounted() {
            console.log(this.dataSource)
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';
    @import '@/assets/scss/_dneElements.scss';
    @import '@/assets/scss/_inputs.scss';

    .item{
        margin: 0 10px 14px 10px;

        @media (max-width: 768px){
            width: calc(100% - 20px);
        }
        
        @media (min-width: 768px) and (max-width: 1200px){
            width: calc(100%/2 - 20px);
        }

        @media (min-width: 1200px){
            width: calc(100%/3 - 20px);
        }

        // @media (min-width: 1400px){
        //     width: calc(100%/4 - 20px);
        // }

        &.add{
            display: flex !important;
            justify-content: center;
            align-items: center;
            background: $color-secondary;
            position: relative;
            min-height: 91px;
            border-radius: 12px;
            cursor: pointer;

            h3{
                color: rgba($color: $color-text-tertiary, $alpha: 0.75);
                margin: 0;
                font-size: 28px;
                line-height: 40px;
                margin-left: 10px;
                transition: .3s;
            }

            &:before{
                @include shadown;
                content: '';
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 59%;
                background: url('~@/assets/images/icons/add.svg') no-repeat center $color-text-tertiary;
                background-size: 20px auto;
                transition: .3s;
            }

            @media (min-width: 992px){
                &:hover{
                    h3{
                        color: $color-text-tertiary;
                    }
                }
            }
        }

        a{
            display: flex;
            align-items: center;
            border: 1px solid #ddd;
            border-radius: 12px;
            background: $color-text-tertiary;

            @media (min-width: 992px){
                &:hover{
                    @include shadown;
                    border-color: #bbb;
                }
            }

            .photo{
                border-right: 1px solid #ddd;
                overflow: hidden;
                width: 90px;
                position: relative;
                border-radius: 12px 0 0 12px;

                img{
                    width: 100%;
                }
            }

            .details{
                width: calc(100% - 90px);
                padding: 0 10px 0 15px;

                .store-name{
                    display: block;
                    margin-bottom: 5px;
                    color: $color-text-secondary;
                    font-size: 12px;
                    line-height: 15px;
                }

                .name{
                    font-family: $font-primary-semibold;
                    // margin-bottom: 5px;
                    margin-bottom: 0;
                    
                    @media (max-width: 576px){
                        font-size: 12px;
                        line-height: 16px;
                    }
                    
                    @media (min-width: 576px){
                        font-size: 14px;
                        line-height: 18px;
                    }
                    
                    small{
                        // display: inline-block;
                        display: block;
                        color: $color-secondary;
                    }
                }
                
                .price{
                    small{
                        display: block;
                        color: $color-text-secondary;
                        font-size: 12px;
                        line-height: 15px;
                    }

                    span{
                        padding-right: 18px;
                        background: url("~@/assets/images/icons/dne-symbol.svg") no-repeat right 5px;
                        background-size: 15px auto;
                        font-family: $font-secondary-bold;
                        color: $color-primary;
                        font-size: 18px;
                        line-height: 20px;
                        height: 20px;

                        del{
                            color: #999;
                            margin-right: 7px;
                        }
                    }
                }
            }
        }
    }
</style>