<template>
    <div class="merchant page-products">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />


        
        <!-- CONTENT -->
        <section class="content-products">
            <div class="load" v-if="load"></div>

            <div class="row" v-if="errorsGet.dataProducts != false">
                <div class="col-12">
                    <div class="box box-error">
                        <h3 class="title-secondary">
                            {{getMyLanguage("box-error","title-error")}}
                        </h3>

                        <p v-if='errorsGet.dataProducts != "401"'>
                            {{getMyLanguage("box-error","msg-error")}}
                        </p>

                        <p v-if='errorsGet.dataProducts == "401"'>
                            {{getMyLanguage("box-error","401")}}
                        </p>
                    </div>
                </div>
            </div>

            <div class="container" v-if="!load && !errorsGet.dataProducts">
                <h2 class="title-secondary subtitle">
                    <small>{{storeName}}</small>
                    {{getMyLanguage("Merchant","products.title")}}
                </h2>

                <div class="search-bar" v-if="dataFilter.length > 0">
                    <input type="search" inputmode="search" class="form form-search" id="searchProduct"
                    v-on:keyup="searchFilter"
                    :placeholder='getMyLanguage("Merchant", "products.placeholder.search")'>
                    
                    <div class="filter">
                        <div class="btn-filter" @click="advancedSearch.modal = true"></div>
                    </div>
                </div>

                <div class="products" id="products-list" v-if="dataFilter.length > 0">
                    <!-- <ShelfTemplateAdminProduct :dataSource="listProducts" /> -->
                    <ShelfTemplateAdminProduct :dataSource="dataFilter" />
                </div>

                <div class="box-empty" v-if="dataFilter.length == 0">
                    <h2 class="title-secondary">{{ getMyLanguage("box-empty","title-empty.products") }}</h2>
                    <p>{{ getMyLanguage("box-empty","msg-empty.products") }}</p>

                    <div class="btn">
                        <a :href="`/${flagSelected}/merchant/products/add`" class="add">
                            <h3>ADD</h3>
                        </a>
                    </div>
                </div>
            </div>
        </section>


        <!-- MODAL ADVANCED SEARCH -->
        <div class="modal" v-if="advancedSearch.modal">
            <div class="modal-cont">
                <div class="modal-close" @click="advancedSearch.modal = false"></div>
                <div class="modal-scroll">
                    <h2 class="title-secondary">Busca avançada</h2>
                    <div class="filters">
                        filtros....
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import Breadcrumb from "@/components/Merchant/Breadcrumb.vue";
    import ShelfTemplateAdminProduct from "@/components/Merchant/ShelfTemplateAdminProduct.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMerchant from '@/config/apiMerchant.js'

    import bus from '@/eventBus';

    import moment from 'moment';

    export default {
        components: {
            Header,
            Breadcrumb,
            ShelfTemplateAdminProduct
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "merchant",
                breadcrumb: "",
                load: true,
                querySearch: "",
                dataFilter: {},

                storeName: "",

                errorsGet: {
                    dataProducts: false
                },

                advancedSearch: {
                    modal: false,
                    aaa: ""
                }
            }
        },

        async mounted() {
            this.languageSelected()

            this.storeName = localStorage.getItem("storeName")

            // await api.get(`api/v1/market/product/filter?query=${this.querySearch}&include_variants=false&include_attachments=false&compressed_response=false`)
            await apiMerchant.get('/api/v1/market/product/filter?include_variants=false&include_attachments=true&compressed_response=false')
            .then(response => {
                this.dataFilter = response.data
                
                // let listProduct = []
                // response.data.forEach(product => {
                //     product.product_variants.forEach(pv => {
                //         pv.brand = product.brand
                //         listProduct.push(pv)
                //     })
                // });
                
                // this.listProducts = listProduct

                setTimeout(() => {
                    this.load = false
                }, 500);
            })
            .catch(error => {
                this.load = false
                this.errorsGet.dataProducts = JSON.stringify(error.response.status)

                if ( error.response.status == 401 ){
                    bus.emit('open-login',"401");
                }

                // console.clear()
            })

            this.breadcrumb = [
                {
                    slug: `${this.flagSelected}/merchant/dashboard`,
                    name: this.getMyLanguage("Merchant","breadcrumb.dashboard")
                },
                {
                    name: this.getMyLanguage("Merchant","breadcrumb.products")
                }
            ]
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            searchFilter() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchProduct");
                const filter = inputList.value.toUpperCase();
                const group = document.getElementById("products-list");
                const item = group.getElementsByClassName("item");
                for (i = 0; i < item.length; i++) {
                    a = item[i].getElementsByTagName("h3")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            item[i].style.display = "";
                    } else {
                            item[i].style.display = "none";
                    }
                }
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="./custom-products.scss" lang="scss" scoped />