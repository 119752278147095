<template>
    <div class="breadcrumb" v-if="dataSource">
        <div class="container-fluid">
            <ul>
                <li v-for="(breadcrumb, index) in dataSource" :key="index">
                    <a :href="`/${breadcrumb.value}`" v-if="breadcrumb.value">
                        {{ breadcrumb.key === 'Home' ? getMyLanguage('Breadcrumb', 'home') : breadcrumb.key }}
                    </a>

                    <span v-if="!breadcrumb.value">
                        {{breadcrumb.key}}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        props: {
            dataSource: {}
        },
        
        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>