<template>
    <div class="banner-slider">
        <div class="container-fluid">
            <Carousel :wrap-around="true" :transition="1000" class="pagination">
                <!-- <Slide v-for="slide in 10" :key="slide">
                    <div class="carousel__item">
                        <img :src="`/images/items/${productShow.sku.gallery[slide - 1]}.jpg`">
                    </div>
                </Slide> -->

                <Slide v-for="(banners, index) in dataSource" :key="index">
                    <div class="carousel__item">
                        <a :href="banners.link" :target="banners.target">
                            <img :src="`/images/banners/${banners.image}`" :alt="banners.title">
                        </a>
                    </div>
                </Slide>

                <template #addons>
                    <Navigation />
                    <Pagination />
                </template>
            </Carousel>
        </div>
    </div>

    <!-- <div class="banner-full" v-for="(banners, index) in dataSource" :key="index">
        <div class="container-fluid">
            <a :href="banners.link" :target="banners.target">
                <img :src="`/images/banners/${banners.image}´">
            </a>
        </div>
    </div> -->
</template>


<script>
    import { defineComponent } from 'vue'
    import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
    

    export default defineComponent({
        props: {
            dataSource: {}
        },

        name: 'Basic',
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
        },

        methods: {
            
        }
    })
</script>

<style src="@/assets/scss/_carousel.scss" lang="scss" />

<style lang="scss" scoped>
    .banner-slider{

        .carousel{
            .carousel__item{
                width: 100%;
                
                img{
                    width: 100%;
                }
            }   
        }
    }
</style>