<template>
    <div class="sidebar-cont">

        <!-- LOGIN -->
        <div class="box-sidebar login">
            <div class="login-cont" v-if="!$store.state.statusLogin">
                <h3>
                    <span>{{ getMyLanguage("Login", "login.access-account") }}</span>
                </h3>
                
                <p class="msg">
                    {{ getMyLanguage("Sidebar","login.msg") }}
                </p>
                
                <div class="btn-primary" @click="openLogin()">
                    {{ getMyLanguage("Sidebar","login.cta") }}
                </div>
            </div>

            <div class="login-cont" v-if="$store.state.statusLogin">
                <div class="user">
                    <div class="photo-user">
                        <div></div>
                    </div>
                    
                    <h4 class="name-user">
                        {{$store.state.profile.profile_full_name}}
                    </h4>
                </div>

                <div class="shortcuts">
                    <div class="short my-account" @click="shortCuts('account')">
                        <small>{{ getMyLanguage("Sidebar","shortcuts.account") }}</small>
                    </div>

                    <div class="short wishlist" @click="shortCuts('wishlist')">
                        <small>{{ getMyLanguage("Sidebar","shortcuts.wishlist") }}</small>
                    </div>

                    <div class="short cart" @click="shortCuts('cart')">
                        <small>{{ getMyLanguage("Sidebar","shortcuts.cart") }}</small>
                    </div>

                    <div class="short logout" @click="shortCuts('logout')">
                        <small>{{ getMyLanguage("Sidebar","shortcuts.logout") }}</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="box-sidebar my-business" v-if="dataSource.isMerchant">
            <!-- <h3><span>{{getMyLanguage("Login",'my-business.title')}}</span></h3> -->

            <div class="store-list">
                <div class="store"
                @click="changeStore(storeMerchant.store.reference, storeMerchant.store.name, storeMerchant.company_reference)"
                v-for="(storeMerchant, indexStore) in $store.state.myBusiness"
                :key="indexStore"
                :class="{active: (dataSource.headerTemplate == 'merchant' && storeMerchant.store.reference == storeReferenceActive)}">

                    <div class="store-name">
                        <div class="acronym">{{ acronym(storeMerchant.store.name) }}</div>
                        <div class="name">{{storeMerchant.store.name}}</div>
                    </div>
                    
                    <Menu
                    :dataSource="dataSource.headerTemplate"
                    v-if="dataSource.headerTemplate == 'merchant'
                        && storeMerchant.store.reference == storeReferenceActive" />
                </div>
            </div>
            
            <a :href="`/${flagSelected}/merchant/company/0`" class="add-store">
                <span>
                    {{getMyLanguage("Store","merchant.store.partners.add-company")}}
                </span>
            </a>
        </div>

        <div class="box-sidebar becomeSeller" v-if="!dataSource.isMerchant">
            <h3>
                <span>{{getMyLanguage("Login",'my-business.title')}}</span>
            </h3>

            <p class="msg">
                {{getMyLanguage("BecomeSeller","become-seller.cont.msg")}}
            </p>

            <BecomeSeller :isMerchant="dataSource.isMerchant" :dataOrigin="origin" />
        </div>


        <!-- MENU -->
        <Menu
        :dataSource="dataSource.headerTemplate"
        v-if="dataSource.headerTemplate != 'merchant'" />
    </div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import Menu from '@/components/Essentials/Menu.vue';
    import BecomeSeller from '@/components/Essentials/BecomeSeller.vue';

    import bus from '@/eventBus';

    export default{
        props: {
            dataSource: Object
        },

        components: {
            Menu,
            BecomeSeller
        },

        data(){
            return {
                showContent: false,
                flagSelected: "en",

                origin: "Sidebar",

                storeReferenceActive: 0,
            }
        },

        async mounted(){
            this.storeReferenceActive = localStorage.getItem("storeReference")
            this.showContent = true
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            acronym(nameStore) {
                const acronym = nameStore.split(' ');
                const firstLetter = acronym[0].charAt(0);
                const secondLetter = acronym[1]?.charAt(0) || '';
                return firstLetter + secondLetter;
            },
            
            changeStore(ref, name, company){
                localStorage.setItem("storeReference", ref)
                this.storeReferenceActive = localStorage.getItem("storeReference")
                localStorage.setItem("storeName", name)
                localStorage.setItem("companyReference", company)

                document.body.classList.remove('body-sidebar-opened')
                bus.emit('close-sidebar');
            
                if ( this.urlMerchant ){
                    document.location.reload(true)
                } else {
                    window.location.href = `/${this.flagSelected}/merchant/dashboard`
                }

                // this.urlMerchant && window.location.reload(true) || (window.location.href = `/${this.flagSelected}/merchant/dashboard`);
            },

            openLogin(){
                bus.emit('open-login',"sidebar");
                bus.emit('close-sidebar');
            },
            
            shortCuts(value){
                                
                if ( value == "account" ){
                    window.location.href = `/${this.flagSelected}/account/purchases`
                }

                if ( value == "wishlist" ){
                    bus.emit('open-wishlist');
                    bus.emit('close-sidebar');
                }

                if ( value == "cart" ){
                    bus.emit('open-minicart');
                    bus.emit('close-sidebar');
                }

                if ( value == "logout" ){
                    bus.emit('logout-mktplace');
                }
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="@/assets/scss/_sidebar.scss" lang="scss" scoped />
