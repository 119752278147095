<template>
    <div class="merchant page-products page-products-manager">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />

        
        <!-- CONTENT -->
        <section class="content-edit">
            <div class="container">
                <div class="load" v-if="load"></div>

                <div class="row" v-if="!load && errorsGet.dataProduct != false">
                    <div class="col-12">
                        <div class="box box-error">
                            <h3 class="title-secondary">
                                {{getMyLanguage("box-error","title-error")}}
                            </h3>

                            <p v-if='errorsGet.dataProduct != "401"'>
                                {{getMyLanguage("box-error","msg-error")}}
                            </p>

                            <p v-if='errorsGet.dataProduct == "401"'>
                                {{getMyLanguage("box-error","401")}}
                            </p>
                        </div>
                    </div>
                </div>


                <!-- STEPS -->
                <div class="row header-steps" v-if="!load && !errorsGet.dataProduct">
                    <div class="col-12">
                        <ul class="steps" :class="{right: flowRegister.step == 'stp03' || flowRegister.step == 'stp04'}">
                            <li class="step-01" :class='{active: flowRegister.step == "stp01", validated: flowRegister.step01Validated}' @click='flowRegister.step = "stp01"'>
                                <strong>01</strong>
                                <small>Descrição</small>
                            </li>

                            <li class="step-02" :class='{active: flowRegister.step == "stp02", validated: flowRegister.step02Validated, disabled: !flowRegister.step01Validated}' @click='flowRegister.step01Validated && (flowRegister.step = "stp02")'>
                                <strong>02</strong>
                                <small>Categorias</small>
                            </li>
                            
                            <li class="step-03" :class='{active: flowRegister.step == "stp03", validated: flowRegister.step03Validated, disabled: !flowRegister.step02Validated}' @click='flowRegister.step02Validated && (flowRegister.step = "stp03")'>
                                <strong>03</strong>
                                <small>Variantes</small>
                            </li>
                            
                            <li class="step-04" :class='{active: flowRegister.step == "stp04", validated: flowRegister.step04Validated, disabled: !flowRegister.step03Validated}' @click='flowRegister.step03Validated && (flowRegister.step = "stp04")'>
                                <strong>04</strong>
                                <small>Revisão</small>
                            </li>
                        </ul>
                    </div>
                </div>

                
                <!-- STEP 01 -->
                <div class="row step-01" v-if='flowRegister.step == "stp01" && !load && !errorsGet.dataProduct'>
                    <div class="load" v-if="flowRegister.load"></div>

                    <div class="col-12" v-if="!flowRegister.load">
                        <div class="select-type">
                            <label v-if='flowRegister.step == "stp01"'>Selecione o tipo</label>
                            
                            <ul v-if='flowRegister.step == "stp01"'>
                                <li>
                                    <div class="form-group-radio">
                                        <input type="radio" id="typeProduct" name="select-type" class="form-radio" value="product" :checked='selectedType == "product"'>
                                        <label for="typeProduct" class="form-check-label">Product</label>
                                    </div>
                                </li>

                                <li>
                                    <div class="form-group-radio">
                                        <input type="radio" id="typeService" name="select-type" class="form-radio" value="service" :checked='selectedType == "service"'>
                                        <label for="typeService" class="form-check-label">Service</label>
                                    </div>
                                </li>

                                <li>
                                    <div class="form-group-radio">
                                        <input type="radio" id="typeClassified" name="select-type" class="form-radio" value="classified" :checked='selectedType == "classified"'>
                                        <label for="typeClassified" class="form-check-label">Classified</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        
                        <div class="field">
                            <label>
                                <span class="info">
                                    Nome do produto
                                    <i><span>Informe neste campo o nome simples do produto. Ex.: iPhone 14 pro Max</span></i>
                                </span>

                                <input type="text" class="form" v-model="dataProduct.name" maxlength="80">
                            </label>
                        </div>

                        <div class="field">
                            <label>
                                <span class="info">
                                    Palavras substitutas
                                    <i><span>São variações do nome do produto que possivelmente o usuário possa pesquisar. Ex.: portátil, notebook, desktop</span></i>
                                </span>

                                <small>(que tenham a mesma semântica)</small>
                                
                                <input type="text" class="form">
                            </label>

                            <small class="example">Geladeira > Frigorífico</small>
                            <small class="example">Calvin Klein > Calvin Klain</small>
                            <small class="obs">* Essas palavras serão utilizadas nas buscas internas e em sites de busca.</small>
                        </div>

                        <div class="field">
                            <!-- <label>Descrição <small>(Meta Tag Description)</small> <span class="characters" :class="{unlimited: dataProduct.descriptions.description.length >= 160}">{{dataProduct.descriptions.description.length}} / 160</span></label> -->
                            <!-- <textarea class="form short" maxlength="160" v-model="dataProduct.descriptions.description"></textarea> -->
                            <label>
                                <span class="info">
                                    Descrição
                                    <i><span>Resumo do produto para visualização em sites de busca.</span></i>
                                </span>

                                <small>(Meta Tag Description)</small> <span class="characters">0 / 160</span>
                                
                                <textarea class="form short" maxlength="160"></textarea>
                            </label>
                            
                            <small class="obs">* Recomendado entre 50 a 160 caracteres.</small>
                        </div>

                        <div class="field">
                            <label>
                                <span class="info">
                                    EAN
                                    <i><span>EAN (European Article Numbers) são sequências numéricas de 13 dígitos para identificação e controle dos produtos em todo o mundo.</span></i>
                                </span>
                                
                                <input type="text" class="form">
                            </label>

                        </div>
                        
                        <div class="field">
                            <label>
                                <span class="info">
                                    Descrição curta
                                    <i><span>Um pequeno resumo do produto, destacando as principais características.</span></i>
                                </span>
                                
                                <input type="text" class="form">
                            </label>
                            
                        </div>

                        <div class="field">
                            <!-- <label>Descrição completa <span class="characters" :class="{unlimited: dataProduct.descriptions.fullDescription.length >= 1200}">{{dataProduct.descriptions.fullDescription.length}} / 1200</span></label> -->
                            <!-- <textarea class="form" maxlength="1200" v-model="dataProduct.descriptions.fullDescription"></textarea> -->
                            <label>
                                <span class="info">
                                    Descrição completa
                                    <i><span>Descrição completa. *Exceto informações ténicas que serão informadas nos proximos campos.</span></i>
                                </span>
                            </label>

                            <span class="characters">0 / 1200</span>
                            
                            <textarea class="form" maxlength="1200"></textarea>
                        </div>

                        <div class="field">
                            <label>
                                <span class="info">
                                    Descrição técnica
                                    <i><span>abcde</span></i>
                                </span>
                            </label>

                            <div class="field-value">
                                <input type="text" class="form" placeholder="Chave" v-model="flowRegister.keys[0]">
                                
                                <span>>></span>
                                
                                <input type="text" class="form add" placeholder="Valor" v-model="flowRegister.keys[1]">
                                
                                <div class="bt-add" @click="addKey()"></div>
                            </div>

                            <!-- <div class="list-field">
                                <div class="list" v-for="(key, indexKeys) in dataProduct.descriptions.keysDescription" :key="indexKeys">
                                    <span class="remove" @click="removeKey(indexKeys)"></span>
                                    <div>
                                        <input type="text" class="formList" v-model="key[0]">
                                        <input type="text" class="formList" v-model="key[1]">
                                    </div>
                                </div>
                            </div> -->

                            <small class="example">Cor >> Amarelo</small>
                            <small class="example">Largura >> 100cm</small>
                            <small class="obs">* Importante para uma descrição mais completa ainda.</small>
                        </div>

                        <div class="field">
                            <label class="hasCheck">
                                <span class="info">
                                    Pesos e medidas
                                    <i><span>abcde</span></i>
                                </span>
                                <small>Indispensável para o cálculo de entrega.</small>

                                <div class="form-group-check">
                                    <input type="checkbox" class="form-check" id="measurementsCheck"
                                    @click='flowRegister.measurementsApply = !flowRegister.measurementsApply'
                                    :checked="flowRegister.measurementsApply">

                                    <label for="measurementsCheck" class="form-check-label">
                                        <span>Aplicar</span>
                                    </label>
                                </div>
                            </label>

                            <div class="measurements" v-if="flowRegister.measurementsApply">
                                <div class="measure">
                                    <input type="number" inputmode="decimal" class="form" placeholder="Peso"><span>kg</span>
                                </div>

                                <div class="measure">
                                    <input type="number" inputmode="decimal" class="form" placeholder="Altura"><span>cm</span>
                                </div>

                                <div class="measure">
                                    <input type="number" inputmode="decimal" class="form" placeholder="Largura"><span>cm</span>
                                </div>

                                <div class="measure">
                                    <input type="number" inputmode="decimal" class="form" placeholder="Comprimento"><span>cm</span>
                                </div>
                            </div>
                        </div>

                        <div class="bts">
                            <div class="btn-primary" @click='save("stp01")'>
                                Salvar e continuar
                            </div>
                        </div>
                    </div>
                </div>
                

                <!-- STEP 02 -->
                <div class="row step-02" v-if='flowRegister.step == "stp02" && !load'>
                    <div class="load" v-if="flowRegister.load"></div>

                    <div class="col-12" v-if="!flowRegister.load">
                        <h2 class="title-product">{{dataProduct.name}}</h2>

                        <div class="filter">
                            <label>
                                <span class="info">
                                    Marca
                                    <i><span>abcde</span></i>
                                </span>
                            </label>

                            <div class="scroll">
                                <ul class="list">
                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="brand111" name="select-brand" class="form-radio" value="Levis">
                                            <label for="brand111" class="form-check-label">Levis</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="brand222" name="select-brand" class="form-radio" value="Adidas" checked>
                                            <label for="brand222" class="form-check-label">Adidas</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="brand333" name="select-brand" class="form-radio" value="Nike">
                                            <label for="brand333" class="form-check-label">Nike</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div class="add">
                                <input type="text" class="form add" placeholder="Nova marca">
                                <div class="bt-add"></div>
                            </div>
                        </div>


                        <div class="filter">
                            <label>
                                <span class="info">
                                    Categoria
                                    <i><span>abcde</span></i>
                                </span>
                            </label>

                            <div class="scroll">
                                <ul class="list">
                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="categ111" name="select-category" class="form-radio" value="Categoria 01">
                                            <label for="categ111" class="form-check-label">Categoria 01</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="categ222" name="select-category" class="form-radio" value="Categoria 02">
                                            <label for="categ222" class="form-check-label">Categoria 02</label>
                                        </div>

                                        <ul class="sublist">
                                            <li class="li">
                                                <div class="form-group-radio">
                                                    <input type="radio" id="subcateg111" name="select-category" class="form-radio" value="Subcategoria 01">
                                                    <label for="subcateg111" class="form-check-label">Subcategoria 01</label>
                                                </div>
                                            </li>

                                            <li class="li">
                                                <div class="form-group-radio">
                                                    <input type="radio" id="subcateg222" name="select-category" class="form-radio" value="Subcategoria 02">
                                                    <label for="subcateg222" class="form-check-label">Subcategoria 02</label>
                                                </div>
                                            </li>

                                            <li class="li">
                                                <div class="form-group-radio">
                                                    <input type="radio" id="subcateg333" name="select-category" class="form-radio" value="Subcategoria 02">
                                                    <label for="subcateg333" class="form-check-label">Subcategoria 03</label>
                                                </div>
                                            </li>

                                            <li class="li">
                                                <div class="form-group-radio">
                                                    <input type="radio" id="subcateg444" name="select-category" class="form-radio" value="Subcategoria 02">
                                                    <label for="subcateg444" class="form-check-label">Subcategoria 04</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="categ333" name="select-category" class="form-radio" value="Categoria 01">
                                            <label for="categ333" class="form-check-label">Categoria 03</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="categ444" name="select-category" class="form-radio" value="Categoria 01">
                                            <label for="categ444" class="form-check-label">Categoria 04</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="categ555" name="select-category" class="form-radio" value="Categoria 01">
                                            <label for="categ555" class="form-check-label">Categoria 05</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="categ666" name="select-category" class="form-radio" value="Categoria 01">
                                            <label for="categ666" class="form-check-label">Categoria 06</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="categ777" name="select-category" class="form-radio" value="Categoria 01">
                                            <label for="categ777" class="form-check-label">Categoria 07</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="categ888" name="select-category" class="form-radio" value="Categoria 01">
                                            <label for="categ888" class="form-check-label">Categoria 08</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="categ999" name="select-category" class="form-radio" value="Categoria 01">
                                            <label for="categ999" class="form-check-label">Categoria 09</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div class="select-add">
                                <select class="form">
                                    <option>/</option>
                                    <option>/Categoria 01</option>
                                    <option>/Categoria 02</option>
                                </select>

                                <div class="add">
                                    <input type="text" class="form add" placeholder="Nova categoria">
                                    <div class="bt-add"></div>
                                </div>
                            </div>
                        </div>


                        <div class="filter">
                            <label>
                                <span class="info">
                                    Categoria Global (DNE Market)
                                    <i><span>abcde</span></i>
                                </span>
                            </label>

                            <div class="search-list">
                                <input type="search" inputmode="search" class="form form-search" placeholder="Pesquisar">
                                <div class="open-list">Listar</div>
                            </div>
                            
                            <div class="scroll">
                                <ul class="list">
                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="global111" name="select-brand" class="form-radio" value="Global 01">
                                            <label for="global111" class="form-check-label">Global 01</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="global222" name="select-brand" class="form-radio" value="Global 02">
                                            <label for="global222" class="form-check-label">Global 02</label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-radio">
                                            <input type="radio" id="global333" name="select-brand" class="form-radio" value="Global 03">
                                            <label for="global333" class="form-check-label">Global 03</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="bts">
                            <div class="btn-back" @click='flowRegister.step = "stp01", goTop()'></div>
                            <div class="btn-primary" @click='save("stp02")'>Salvar e continuar</div>
                        </div>
                    </div>
                </div>


                <!-- STEP 03 -->
                <div class="row step-03" v-if='flowRegister.step == "stp03" && !load'>
                    <div class="load" v-if="flowRegister.load"></div>

                    <div class="col-12" v-if="!flowRegister.load">
                        <h2 class="title-product">{{dataProduct.name}}</h2>
                        
                        <div class="variables">
                            <div class="btn-primary add-variable" @click='modalVariable("add")'>Adicionar variante</div>

                            <!-- exibir se for maior que 1 -->
                            <!-- <div class="search"> -->
                                <!-- <input type="search" inputmode="search" class="form form-search" id="searchVars" @keyup="filterVariables" placeholder='Procure por uma variante' /> -->
                                <!-- <input type="search" inputmode="search" class="form form-search-icon" v-model="flowRegister.varSearch" id="searchVars" placeholder='Procure por uma variante' /> -->
                                
                                <!-- <div class="suggestions" id="suggestions">
                                    <ul>
                                        <li v-for="(variable, indexSearchVar) in varsSearch" :key="indexSearchVar">{{variable.name}}</li>
                                    </ul>
                                </div> -->
                            <!-- </div> -->


                            <div class="variable" v-for="(variable,indexVariable) in dataProduct.variables" :key="indexVariable">
                                <div class="header-variable">
                                    <h3>
                                        <span>
                                            <small>{{dataProduct.name}}&nbsp;&nbsp;</small>{{variable[0]}}
                                        </span>

                                        <span class="price">{{variable[1]}}</span>
                                    </h3>

                                    <div class="actions">
                                        <div class="btn-edt" @click='modalVariable("edt",variable.id)'></div>
                                        <div class="btn-del" @click='modalVariable("del",variable.id)'></div>
                                    </div>
                                </div>

                                <div class="thumbs">

                                    <div class="select-all">
                                    <!-- <h3 class="title-secondary">Select images</h3> -->
                                        <div @click="imageSelection" :class="{checked: allSelected}">{{ allSelected ? 'Desmarcar todas as imagens' : 'Marcar todas as imagens' }}</div>
                                    </div>
                                    
                                    
                                    <div class="gallery">
                                        <div class="add-image" @click="modal.addImages = true,modal.variableID = variable.id">
                                            <h3>ADD<small>IMAGE</small></h3>
                                        </div>

                                        <div class="image-check" v-for="(gallery, indexGallery) in dataProduct.images" :key="indexGallery">
                                            <input type="checkbox" :id="`img01${indexGallery}`" class="form-check" v-model="flowRegister.selectedImages[indexGallery]">

                                            <label :for="`img01${indexGallery}`">
                                                <img src="/images/items/nike-zoomx-blue-01.jpg">
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="anc" id="variables"></div>
                        </div>
                    </div>

                    <div class="col-12 bts" v-if="!flowRegister.load">
                        <div class="btn-back" @click='flowRegister.step = "stp02", goTop()'></div>

                        <div class="btn-primary btn-disabled" v-if="dataProduct.variables == 0">
                            Salvar e continuar
                        </div>

                        <div class="btn-primary" @click='save("stp03")' v-if="dataProduct.variables != 0">
                            Salvar e continuar
                        </div>
                    </div>
                </div>


                <!-- STOP 04 -->
                <div class="row step-04" v-if='flowRegister.step == "stp04" && !load'>
                    <div class="col-12">
                        PREVIEW
                    </div>

                    <div class="col-12 bts">
                        <div class="btn-back" @click='flowRegister.step = "stp03", goTop()'></div>

                        <div class="btn-primary" @click='save("stp04")'>
                            Publicar
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- MODAL ADD/EDT VARIABLE -->
        <!-- <div class="modal modal-variables" :class="{noGallery: !modal.managerGallery}" v-if="modal.variables"> -->
        <div class="modal modal-variables" v-if="modal.variables">
            <div class="modal-cont" :class="{modalShort: del}">
                <div class="modal-close" @click="modal.variables = false"></div>

                <div class="modal-scroll">

                    <!-- ADD -->
                    <div class="add" v-if='modal.variablesAction == "add"'>
                        <h3 class="title-secondary">
                            Add variante
                        </h3>

                        <div class="field">
                            <label>
                                <span class="info">
                                    Nome da variante
                                    <i><span>abcde</span></i>
                                </span>

                                <input type="text" class="form" v-model="flowRegister.variable[0]">

                                <p class="preview-name">{{dataProduct.name}} <span>{{flowRegister.variable[0]}}</span></p>
                            </label>

                            <label>
                                <span class="info">
                                    Valor da variante
                                    <i><span>abcde</span></i>
                                </span>

                                <input type="number" inputmode="decimal" class="form form-price" v-model="flowRegister.variable[1]" placeholder="0">
                            </label>

                            <div class="btn-primary" @click="addVariable()">Add</div>
                        </div>
                    </div>

                    <!-- EDT -->
                    <div class="edt" v-if='modal.variablesAction == "edt"'>
                        <h3 class="title-secondary">Editar variante</h3>
                        
                        <div class="field">
                            <label>
                                <span class="info">
                                    Nome da variante
                                    <i><span>abcde</span></i>
                                </span>
                                
                                <input type="text" class="form" value="variante do v-model" placeholder="Nome da variante">

                                <p class="preview-name">{{dataProduct.name}} <span>{{}}</span></p>
                            </label>

                            <label>
                                <span class="info">
                                    Valor da variante
                                    <i><span>abcde</span></i>
                                </span>

                                <input type="number" inputmode="decimal" class="form form-price" placeholder="0">
                            </label>

                            <div class="btn-primary">Salvar</div>
                        </div>
                    </div>

                    <!-- DEL -->
                    <div class="del" v-if='modal.variablesAction == "del"'>
                        <h3 class="title-secondary">Remover variante</h3>

                        <div class="del-cont">
                            <h3>{{dataProduct.name}}</h3>
                            <h2>Nome da variável</h2>
                            <div class="btn-cancel">Excluir</div>
                        </div>
                    </div>

                    <!-- GALLERY -->
                    <!-- <h3 class="title-secondary" v-if="modal.managerGallery">Imagens da variante</h3>
                    <div class="gallery" v-if="modal.managerGallery">
                        <div class="add-image" @click="modal.addImages = true">
                            <h3>ADD IMAGE</h3>
                        </div>

                        <div class="image">
                            <div class="remove" @click="delImage()"></div>
                            <span>
                                <img src="/images/items/nike-zoomx-blue-01.jpg">
                            </span>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>


        <!-- MODAL ADD IMAGES -->
        <div class="modal modal-upload" v-if="modal.addImages">
            <div class="modal-cont">
                <div class="modal-close" @click="modal.addImages = false"></div>

                <div class="modal-scroll">
                    <h3 class="title-secondary">
                        Upload imagens
                    </h3>
                    
                    <div class="col-upload">
                        <label for="uploadImg" class="upload-img" v-if="upload.File == null">
                            Escolher imagens
                        </label>

                        <label for="uploadImg" class="upload-img fileSelected" v-if="upload.File != null">
                            Ficheiro(s) selecionado(s)
                        </label>

                        <input type="file" id="uploadImg" multiple="multiple" accept="image/jpg, image/jpeg, image/png" ref="file" @change="uploadFile()">

                        <!-- <div class="btn-upload" :class="{fileSelected: upload.File != null, error: validation.File === false}">Upload</div> -->
                        <div class="btn-upload" :class="{fileSelected: upload.File != null}">
                            Enviar
                        </div>
                    </div>

                    <div class="box-alert">
                        <div>
                            <p>* Adicione até 10 fotos/imagens.</p>
                            <p>* Envie fotos/imagens com boa qualidade e superiores a 1000px de largura.</p>
                            <p>* Fotos/imagens no formato JPG, JPEG e PNG.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- MODAL REMOVE IMAGE -->
        <div class="modal modal-remove-image" v-if="modal.removeImages">
            <div class="modal-cont">
                <div class="modal-close" @click="modal.removeImages = false"></div>
                <div class="modal-scroll">
                    <div class="remove-image">
                        <img src="/images/items/nike-zoomx-blue-01.jpg" class="img-del">

                        <div class="btn-cancel btn-remove">
                            Remover
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    $(document).on("click",".field", function(){
        $(".field").removeClass("active")
        $(this).addClass("active")
    })
    
    $(document).on("blur",".field", function(){
        $(".field").removeClass("active")
    })

    import Header from "@/components/Essentials/Header.vue";
    import Breadcrumb from "@/components/Merchant/Breadcrumb.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMerchant from '@/config/apiMerchant.js'

    import bus from '@/eventBus';

    import moment from 'moment';

    export default {
        components: {
            Header,
            Breadcrumb
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "merchant",
                load: true,

                breadcrumb: [],

                flowRegister: {
                    load: false,
                    step: "stp01",
                    selectedType: "",
                    keys: ["",""],
                    measurementsApply: true,
                    variable: [],
                    varSearch: "",
                    selectedImages: [],

                    step01Validated: false,
                    step02Validated: false,
                    step03Validated: false,
                    step04Validated: false
                },

                flowUpload: {
                    image: "",
                    variable: null
                },

                dataProduct: {},
                productReference: "",
                
                // dataProduct: {
                //     productName: "",
                //     descriptions: {
                //         description: "",
                //         fullDescription: "",
                //         keysDescription: [
                //             ["Cor","Amarelo"],
                //             ["Largura","100cm"],
                //             ["Altura","50cm"],
                //             ["Profundidade","60cm"]
                //         ],
                //     },
                //     variables: [],
                //     images: [
                //         "nike-zoomx-blue-01.jpg",
                //         "nike-zoomx-blue-01.jpg",
                //         "nike-zoomx-blue-01.jpg",
                //         "nike-zoomx-blue-01.jpg",
                //         "nike-zoomx-blue-01.jpg",
                //         "nike-zoomx-blue-01.jpg",
                //         "nike-zoomx-blue-01.jpg"
                //     ]
                // },

                modal: {
                    variables: false,
                    variablesAction: "add",
                    managerGallery: false,

                    variableID: "",
                    addImages: false,
                    removeImages: false
                },

                errorsGet: {
                    dataProduct: false
                },

                // TEMP
                upload: {
                    File: null,
                    selected: false,
                    load: false
                },

                varsSearch: [
                    {
                        name: "Azul",
                        price: "123",
                        images: []
                    },
                    {
                        name: "Vermelho",
                        price: "128",
                        images: []
                    },
                    {
                        name: "Branco",
                        price: "118",
                        images: []
                    }
                ]
            }
        },

        async mounted() {
            this.languageSelected()

            this.productReference = this.$route.params.productReference

            if ( this.productReference != undefined ){
                
                await apiMerchant.get(`/api/v1/market/product/${this.productReference}`)
                .then(response => {
                    this.dataProduct = response.data
                    console.log(response.data)

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
                .catch(error => {
                    this.load = false
                    this.errorsGet.dataProduct = JSON.stringify(error.response.status)

                    if ( error.response.status == 401 ){
                        bus.emit('open-login',"401");
                    }

                    // console.clear()
                })
            }

            this.breadcrumb = [
                {
                    slug: `${this.flagSelected}/merchant/dashboard`,
                    name: this.getMyLanguage("Merchant","breadcrumb.dashboard")
                },
                {
                    slug: `${this.flagSelected}/merchant/products`,
                    name: this.getMyLanguage("Merchant","breadcrumb.products")
                }
            ]

            if ( this.productReference == undefined ){
                this.breadcrumb.push({name: this.getMyLanguage("Merchant","breadcrumb.add-product")})
            } else{ 
                this.breadcrumb.push({name: this.getMyLanguage("Merchant","breadcrumb.manager-product")})
            }

            console.log(this.breadcrumb)
            

            // then
            this.selectedType = "product"
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                if ( this.$route.params.productReference != undefined ){
                    setTimeout(() => {
                        document.title = `${this.dataProduct.name} | DNE Market`
                    }, 1000);
                } else {
                    document.title = this.getMyLanguage("Merchant","products.title.add-product")
                }
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            addKey(){
                //validar campos antes do push
                this.dataProduct.descriptions.keysDescription.push(this.flowRegister.keys)
                this.flowRegister.keys = ["",""]
            },

            removeKey(value){
                this.dataProduct.descriptions.keysDescription.splice(value, 1)
            },

            addVariable(){
                this.dataProduct.variables.push(this.flowRegister.variable)
                this.flowRegister.variable = []
                this.modal.variables = false
                this.imageSelection()
                this.$toast.success("Variante adicionada");

                setTimeout(() => {
                    var lastVariable = $('.step-03 .variables .variable').last();
                    var positionTop = lastVariable.offset().top;

                    $(window).scrollTop(positionTop - 80);
                }, 500);

                if ( this.dataProduct.variables.length == 1 ){
                    this.modal.addImages = true
                }
            },
            
            removeVariable(value){
                this.dataProduct.variables.splice(value, 1)
            },

            imageSelection() {
                // console.log(this.flowRegister.selectedImages)
                if (this.allSelected) {
                    this.flowRegister.selectedImages = Array(this.dataProduct.images.length).fill(false);
                } else {
                    this.flowRegister.selectedImages = Array(this.dataProduct.images.length).fill(true);
                }
            },

            save(step){
                if ( step == "stp01" ){
                    this.flowRegister.load = true

                    // validar campos
                    // post para api

                    // then
                    this.goTop()

                    setTimeout(() => {
                        this.flowRegister.load = false
                        this.flowRegister.step01Validated = true
                        this.flowRegister.step = "stp02"                        
                        this.$toast.success("Salvo com sucesso");
                    }, 1500);
                }
                
                if ( step == "stp02" ){
                    this.flowRegister.load = true

                    // validar campos
                    // post para api

                    // then
                    this.goTop()

                    setTimeout(() => {
                        this.flowRegister.load = false
                        this.flowRegister.step = "stp03"
                        this.flowRegister.step02Validated = true                        
                        this.$toast.success("Salvo com sucesso");
                    }, 1500);
                }
                
                if ( step == "stp03" ){
                    this.flowRegister.load = true

                    // validar campos
                    // post para api

                    // then
                    this.goTop()

                    setTimeout(() => {
                        this.flowRegister.load = false
                        this.flowRegister.step = "stp04"
                        this.flowRegister.step03Validated = true
                        this.$toast.success("Salvo com sucesso");
                    }, 1500);
                }
                
                if ( step == "stp04" ){
                    this.flowRegister.load = true

                    // validar campos
                    // post para api

                    // then
                    this.goTop()

                    setTimeout(() => {
                        this.flowRegister.load = false
                        this.flowRegister.step04Validated = true                        
                        this.$toast.success("Salvo com sucesso");
                    }, 1500);
                }
            },

            modalVariable(action,id){
                this.modal.variablesAction = action
                this.modal.variableID = id

                if ( action == "add" ){
                    this.modal.variables = true
                }
                
                if ( action == "edt" ){
                    this.modal.variables = true
                    this.modal.managerGallery = true
                }
                
                if ( action == "del" ){
                    this.modal.variables = true
                }
            },

            // filterVariables(){
            //     let a = '';
            //     let i = '';
            //     let txtValue = '';
            //     const inputList = document.getElementById("searchVars");
            //     const filter = inputList.value.toUpperCase();
            //     const ul = document.getElementById("suggestions");
            //     const li = ul.getElementsByTagName("ul");
            //     for (i = 0; i < li.length; i++) {
            //         a = li[i].getElementsByTagName("li")[1];
            //         txtValue = a.textContent || a.innerText;
            //         if (txtValue.toUpperCase().indexOf(filter) > -1) {
            //                 li[i].style.display = "";
            //         } else {
            //                 li[i].style.display = "none";
            //         }
            //     }
            // },

            uploadFile() {
                this.upload.File = this.$refs.file.files[0];
                console.log(this.upload)
            },

            delImage(){
                this.modal.removeImages = true
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        computed: {
            allSelected() {
                return this.flowRegister.selectedImages.every(value => value === true);
            }
        },

        created() {
            if ( this.$store.state.statusLogin ){
                if ( this.$route.params.productReference != undefined ){
                    setTimeout(() => {
                        document.title = `${this.dataProduct.name} | DNE Market`
                    }, 1000);
                } else {
                    document.title = this.getMyLanguage("Merchant","products.title.add-product")
                }
            }


            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="./custom-product-manager.scss" lang="scss" scoped />