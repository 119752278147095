<template>
    <div class="merchant page-campaigns">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />

        
        <!-- CONTENT -->
        <section class="content-campaigns">
            
            <div class="load" v-if="load"></div>

            <!-- <div class="row" v-if="errorsGet.dataSales != false">
                <div class="col-12">
                    <div class="box box-error">
                        <h3 class="title-secondary">{{getMyLanguage("box-error","title-error")}}</h3>
                        <p v-if='errorsGet.dataSales != "401"'>{{getMyLanguage("box-error","msg-error")}}</p>
                        <p v-if='errorsGet.dataSales == "401"'>{{getMyLanguage("box-error","401")}}</p>
                    </div>
                </div>
            </div> -->

            <!-- <div class="container" v-if="!load && !errorsGet.dataSales"> -->
            <div class="container" v-if="!load">
                <h2 class="title-secondary subtitle">
                    <small>{{storeName}}</small>
                    {{getMyLanguage("Merchant","campaigns.title")}}
                </h2>


                <!-- <div class="search-bar" data-aos="fade-up" data-aos-offset="-100" v-if="dataSales.length > 0">
                    <input type="search" inputmode="search" class="form form-search" id="searchSale" v-on:keyup="searchFilter" :placeholder='getMyLanguage("Merchant", "products.placeholder.search")'>
                    
                    <div class="filter">
                        <div class="btn-filter" @click="advancedSearch.modal = true"></div>
                    </div>
                </div> -->

                <!-- <div class="cont-sales" data-aos="fade-up" data-aos-offset="-100" v-if="!load && !errorsGet.dataSales && dataSales.length > 0"> -->
                <div class="cont-campaigns" data-aos="fade-up" data-aos-offset="-100" v-if="!load">
                    <div class="campaigns">
                        <div class="box tool-unavailable">
                            <h3 class="title-secondary">{{getMyLanguage("Merchant","tool-unavailable.title")}}</h3>
                            <p>{{getMyLanguage("Merchant","tool-unavailable.msg")}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- MODAL ADVANCED SEARCH -->
        <!-- <div class="modal" v-if="advancedSearch.modal">
            <div class="modal-cont">
                <div class="modal-close" @click="advancedSearch.modal = false"></div>
                <div class="modal-scroll">
                    <h2 class="title-secondary">Busca avançada</h2>
                    <div class="filters">
                        filtros....
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import Breadcrumb from "@/components/Merchant/Breadcrumb.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    // import apiMerchant from '@/config/apiMerchant.js'

    // import bus from '@/eventBus';

    import moment from 'moment';

    export default {
        components: {
            Header,
            Breadcrumb
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "merchant",
                breadcrumb: "",
                load: true,
                dataSales: {},

                storeName: "",

                errorsGet: {
                    dataSales: false
                }
            }
        },

        async mounted() {
            this.languageSelected()

            this.storeName = localStorage.getItem("storeName")

            // await apiMerchant.get('/api/v1/market/order/filter')
            // .then(response => {
            //     this.dataSales = response.data
            //     console.log(this.dataSales)
                
            //     setTimeout(() => {
            //         this.load = false
            //     }, 500);
            // })
            // .catch(error => {
            //     this.load = false
            //     this.errorsGet.dataSales = JSON.stringify(error.response.status)

            //     if ( error.response.status == 401 ){
            //         bus.emit('open-login',"401");
            //     }
            // })

            this.breadcrumb = [
                {
                    slug: `${this.flagSelected}/merchant/dashboard`,
                    name: this.getMyLanguage("Merchant","breadcrumb.dashboard")
                },
                {
                    name: this.getMyLanguage("Merchant","breadcrumb.campaigns")
                }
            ]

            // TEMP
            setTimeout(() => {
                this.load = false
            }, 1500);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                this.languageSelected();
                }
            }
        },

        created() {
            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="./custom-campaigns.scss" lang="scss" scoped />

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';

    
</style>