<template>
    <div></div>
    <!-- <div class="top-banner" v-if="topbanner.image">
        <a :href="topbanner.link" :target="topbanner.target">
            <img :src="`/images/banners/&{topbanner.image}`" :alt="topbanner.title">
        </a>
    </div> -->
</template>


<script>
    export default{
        props: {
            topbanner: {}
        }
    }
</script>

<style lang="scss" scoped>
    .top-banner{
        width: 100%;
        margin-bottom: 40px;
        
        img{
            width: 100%;
        }
    }
</style>