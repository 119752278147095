<template>
    <a :href="`/${this.flagSelected}/merchant/company/0`" v-if="$store.state.statusLogin" class="become-seller" :class="dataOrigin">
        <span v-if="dataOrigin != 'Login' && !dataSource" v-html='getMyLanguage("BecomeSeller","become-seller.btn-cta")'></span>
        <span v-if="dataOrigin == 'Login' && !dataSource" v-html='getMyLanguage("BecomeSeller","become-seller.btn-cta-nowrap")'></span>
        <span v-if="dataSource" v-html='getMyLanguage("Store","merchant.store.partners.add-company")'></span>
    </a>
    
    <a :href="`/${this.flagSelected}/become-seller`" v-else class="become-seller" :class="dataOrigin">
        <span v-if="dataOrigin != 'Login' && !dataSource" v-html='getMyLanguage("BecomeSeller","become-seller.btn-cta")'></span>
        <span v-if="dataOrigin == 'Login' && !dataSource" v-html='getMyLanguage("BecomeSeller","become-seller.btn-cta-nowrap")'></span>
        <span v-if="dataSource" v-html='getMyLanguage("Store","merchant.store.partners.add-company")'></span>
    </a>
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        props: {
            dataSource: Boolean,
            dataOrigin: String
        },

        data(){
            return {
                flagSelected: "en",
                showContent: false
            }
        },
        
        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            },
            '$store.state.statusLogin': {
                immediate: true,
                handler() {
                    setTimeout(() => {
                        this.showContent = true
                    }, 500);
                }
            }
        }
    }
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_dneElements.scss';

    .col-tools{
        .become-seller{
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            
            @media (max-width: 360px){
                margin-right: 3px;
            }
            
            @media (min-width: 360px) and (max-width: 576px){
                margin-right: 5px;

                &:before{
                    animation: showButtom 1s alternate;
                }
            }

            @media (min-width: 576px){
                margin-right: 20px;

                &:before{
                    animation: showButtom 1s alternate;
                }
            }

            @media (min-width: 576px){
                display: flex;
                align-items: center;

                &:hover{
                    &:before{
                        background-color: #e5e5e5;
                    }
                }
            }
            
            &:before{
                content: '';
                display: block;
                flex-shrink: 0;
                border-radius: 50%;
                background: url('~@/assets/images/icons/become-seller.svg') no-repeat center $color-text-tertiary;
                background-size: auto 18px;
                border: 1px solid #eee;
                transition: .3s;

                @media (max-width: 360px){
                    width: 30px;
                    height: 30px;
                }
                
                @media (min-width: 360px){
                    width: 32px;
                    height: 32px;
                }
            }

            span{
                display: block;
                // min-width: 80px;
                max-width: 120px;
                position: relative;
                right: 0;
                flex: 1;
                font-size: 12px;
                line-height: 13px;
                white-space: nowrap;
                color: $color-primary;
                margin-left: 5px;
                animation: showButtomName 1s alternate;
                
                @media (max-width: 430px){
                    display: none;
                }
            }

            &.Header{
                @media (max-width: 576px){
                    display: none;
                }
            }
        }
    }
    
    @keyframes showButtomName {
        0% {opacity: 0;}
        50% {opacity: 0;}
        100% {opacity: 1;}
    }


    .becomeSeller{
        .become-seller{
            position: relative;
            background: $color-secondary;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 30px;
            border-radius: 12px;
            
            &:before{
                @include shadown;
                content: '';
                display: block;
                width: 36px;
                height: 36px;
                background: url('~@/assets/images/icons/become-seller-white.svg') no-repeat center rgba($color: $color-text-tertiary, $alpha: 0.15);
                background-size: 50%;
                border-radius: 50%;
                margin-right: 10px;
            }

            span{
                color: $color-text-tertiary;
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
</style>