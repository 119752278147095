<template>
    <div class="dne-market">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- BREADCRUMB -->
        <div class="breadcrumb" data-aos="fade-up" data-aos-offset="-100">
            <div class="container-fluid">
                <ul>
                    <li>
                        <router-link :to="{ name: 'Home' }">
                            {{getMyLanguage("Breadcrumb","home")}}
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{ name: 'Category' }">
                            Informática
                        </router-link>
                    </li>

                    <li>Formatação</li>
                </ul>
            </div>
        </div>

        
        <!-- CONTENT SERVICE -->
        <section class="content-service">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-lg-6 col-xl-5 product service">
                       
                        <!-- SERVICE -->
                        <div class="cont-product">
                            <router-link :to="{ name: 'ProductTemp' }" data-aos="fade-up" data-aos-offset="-100">
                                <h1>Formatação</h1>
                            </router-link>

                            <router-link :to="{ name: 'Category' }" class="category" data-aos="fade-up" data-aos-offset="-100">
                                Serviços > Informática
                            </router-link>

                            <div class="short-description" data-aos="fade-up" data-aos-offset="-100">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium dicta cumque at consequatur! Officia repellendus error qui quasi laborum dolor id fuga laudantium, voluptatibus vero beatae omnis ab odio neque. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla, quae sit. Repellat numquam ab, reiciendis accusantium facilis minima, eos veniam aliquam deserunt distinctio qui! Tempora dolorum reprehenderit ipsa corrupti ipsum.
                            </div>
                            
                            <!-- <div class="availability" data-aos="fade-up" data-aos-offset="-100">
                                <p><span class="available">Disponível</span></p>
                                <p>Data de entrega estimada: <strong>15/09/2022</strong></p>
                            </div> -->


                            <!-- ADD CART -->
                            <div class="add-cart">
                                <div class="price-variables" data-aos="fade-up" data-aos-offset="-100">
                                    <div class="values">
                                        <div class="old-price">
                                            <del>90</del>
                                            <small>-11%</small>
                                        </div>

                                        <div class="price">
                                            80
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="btn-primary btn-add-cart" data-aos="fade-up" data-aos-offset="-100">
                                    <span>Adicionar ao carrinho</span>
                                </div> -->

                                <div class="add-to-cart" data-aos="fade-up" data-aos-offset="-100">
                                    <div class="quantity">
                                        <div class="qtd">
                                            <div class="less" @click='qtdPurchase("less")'>-</div>
                                            <div class="items">{{qtd}}</div>
                                            <div class="more" @click='qtdPurchase("more")'>+</div>
                                            <div class="tooltip" v-if="tooltipStock">{{getMyLanguage("Product","add-cart.tooltip.max")}}&nbsp;{{stock}}</div>
                                        </div>
                                    </div>

                                    <div class="btn-primary btn-add-cart" @click="addToCart()">
                                        <span>{{getMyLanguage("Product","add-cart.bt-add-cart")}}</span>
                                    </div>
                                </div>
                            </div>


                            <!-- SHARE / WISHLIST -->
                            <div class="share-wishlist" data-aos="fade-up" data-aos-offset="-100">

                                <div class="share">
                                    <p class="lbl">{{getMyLanguage("Product","share.lbl")}}</p>

                                    <div class="lnks">
                                        <div class="whatsapp"></div>
                                        <div class="facebook"></div>
                                        <div class="instagram"></div>
                                        <div class="twitter"></div>
                                    </div>
                                </div>

                                <div class="wishlist">
                                    <p class="lbl">
                                        {{getMyLanguage("Product","wishlist.lbl")}}
                                    </p>

                                    <div class="icon"
                                    :class="{selected: wishlist}"
                                    @click='wishList("nome do produto")'></div>
                                </div>
                            </div>


                            <!-- SELLER -->
                            <div class="row seller" data-aos="fade-up" data-aos-offset="-100">
                                <router-link :to="{ name:'Brand' }" class="logo-seller">
                                    <img src="~@/assets/images/brands/amige.svg" alt="Amige">
                                </router-link>

                                <div class="inf-seller">
                                    <div>
                                        <p>{{getMyLanguage("Service","company.lbl")}} <strong>Amige</strong></p>
                                        <p>{{getMyLanguage("Service","services.lbl")}} <strong>16</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 col-lg-6 col-xl-7" data-aos="fade-up" data-aos-offset="-100">

                        <!-- GALLERY ZOOM -->
                        <div class="gallery-zoom" data-gallery="1">
                            <div class="photo" @click="zoom()">
                                <img src="~@/assets/images/items/servico-exemplo.jpg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <LegalPolicies />
    </div>


    <!-- MODAL ZOOM -->
    <div class="modal modal-zoom" v-if="modal.zoom === true">
        <div class="modal-cont">
            <div class="modal-close" @click='modal.zoom = false,modal.image = ""'></div>
            <inner-image-zoom :src="`/items/${modal.image}.jpg`" v-if='modal.image != ""' />
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import LegalPolicies from "@/components/Essentials/LegalPolicies.vue";

    import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
    import InnerImageZoom from 'vue-inner-image-zoom'

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
                Header,
                LegalPolicies,
                InnerImageZoom
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "search",
                
                wishlist: false,

                qtd: 1,
                stock: 5,
                tooltipStock: false,

                modal: {
                    zoom: false,
                    image: ""
                }
            };
        },

        async mounted() {
            this.languageSelected()
            this.detailsPage()
            this.goTop()
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo", "title.home");
                document.head.querySelector("meta[name=description]").content = this.getMyLanguage("seo", "title.description.home");
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},0);
                return false;
            },

            detailsPage(){
                setTimeout(() => {
                    document.title = "Formatação de notebook | DNE Market"
                }, 100);
            },

            zoom() {
                this.modal.zoom = true

                setTimeout(() => {
                    this.modal.image = "servico-exemplo"
                }, 500);
            },

            wishList(name) {
                if ( this.wishlist === true ){
                    this.wishlist = false
                } else{
                    this.wishlist = true
                }

                this.$emit('addWish', name)
            },

            qtdPurchase(operation){
                if ( operation === "more" && this.qtd < this.stock ){
                    this.qtd = this.qtd + 1
                }

                if ( operation === "less" && this.qtd > 1 ){
                    this.qtd = this.qtd - 1
                }

                if ( operation === "more" && this.qtd == this.stock ){
                    this.tooltipStock = true
                    
                    setTimeout(() => {
                        this.tooltipStock = false
                    }, 2000);
                }
            },

            addToCart(){
                // this.emitter.emit('my-event', {'eventContent': 'String changed'})
                // this.$emit('add-cart',"foi")
                this.$toast.success(this.getMyLanguage("Product","add-cart.tooltip"));
                setTimeout(this.$toast.clear, 6000)
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            document.title = this.getMyLanguage("seo", "title.home");
            document.head.querySelector("meta[name=description]").content = this.getMyLanguage("seo", "title.description.home");
        },
    };
</script>

<style src="./custom-product.scss" lang="scss" scoped />

