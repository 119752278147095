<template>
  <div class="dne-market home">
    <div id="dne"></div>
    <!-- <Header :dataSource="components.PhCategoriesHeader" /> -->
    <Header :dataSource="headerTemplate" />

    <ConfirmLocation />

    <!-- SEARCH HOME -->
    <section class="search-home">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-7">
                    <h3 v-html='getMyLanguage("Home","search.cta")'></h3>
                    <p v-html='getMyLanguage("Home","search.cta.dne-token")'></p>

                    <div id="ancSearch"></div>
                    
                    <Search />
                </div>

                <div class="col-12 col-lg-5 col-img">
                    <img src="~@/assets/images/home/dne-market.png" alt="DNE Market">
                </div>
            </div>
        </div>

        <VideoHome />
    </section>
    
    <Topics :dataSource="components.PhCategoriesFeatured" v-if="components.PhCategoriesFeatured" />

    <MosaicStores />
    <Benefits />
    <MosaicHighlights :dataSource="components.PhCategoriesMosaic" v-if="components.PhCategoriesMosaic" />
    
    <ShelfTemplateProductSlider :dataSource="components.PhProductsTop10" v-if="components.PhProductsTop10 && showContent" />

    <DneExchange />
    <Brands :dataSource="components.PhBrands" v-if="components.PhBrands" />
    <FeaturedCategory :dataSource="components.PhCategoryFeatured" v-if="components.PhCategoryFeatured" />
    <Top10 :dataSource="components.PhCategoriesTop10" v-if="components.PhCategoriesTop10" />
    <LegalPolicies />
    
  </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import ConfirmLocation from "@/components/Home/ConfirmLocation.vue";
    import Search from "@/components/Essentials/Search.vue";
    import Topics from "@/components/Home/Topics.vue";
    import VideoHome from "@/components/Home/VideoHome.vue";
    import Benefits from "@/components/Home/Benefits.vue";
    import MosaicStores from "@/components/Home/MosaicStores.vue";
    import MosaicHighlights from "@/components/Home/MosaicHighlights.vue";
    import DneExchange from "@/components/Home/DneExchange.vue";
    import ShelfTemplateProductSlider from "@/components/Shelves/ShelfTemplateProductSlider.vue";
    import Brands from "@/components/Home/Brands.vue";
    import FeaturedCategory from "@/components/Shelves/FeaturedCategory.vue";
    import Top10 from "@/components/Home/Top10.vue";
    import LegalPolicies from "@/components/Essentials/LegalPolicies.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMarket from '@/config/apiMarket.js'

    export default {
        components: {
            Header,
            ConfirmLocation,
            Search,
            Topics,
            VideoHome,
            Benefits,
            MosaicStores,
            MosaicHighlights,
            DneExchange,
            ShelfTemplateProductSlider,
            Brands,
            FeaturedCategory,
            Top10,
            LegalPolicies,
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "menus",
                showContent: false,
                
                // components: {
                //     response: "",
                //     PhCategoriesHeader: {
                //         type_code: "",
                //         data: []
                //     },
                //     PhCategoriesFeatured: [],
                //     PhCategoriesMosaic: {
                //         placeholder_name: "",
                //         data: []
                //     },
                //     PhProductsTop10: {
                //         placeholder_name: "",
                //         data: []
                //     },
                //     PhBrands: {
                //         placeholder_name: "",
                //         data: []
                //     },
                //     PhCategoryFeatured: {
                //         placeholder_name: "",
                //         data: []
                //     },
                //     PhCategoriesTop10: {
                //         placeholder_name: "",
                //         data: []
                //     },
                // },

                // TEMP
                components: {
                    response: "",
                    PhCategoriesHeader: {
                        type_code: "categories",
                        data: [
                            { slug: "store/1000047381/dnemarket", name: "Regresso às aulas" },
                            { slug: "store/1000047381/dnemarket", name: "Trocar de telemóvel" },
                            { slug: "store/1000047381/dnemarket", name: "Roupas de verão" },
                            { slug: "store/1000047381/dnemarket", name: "Presentes de Natal" },
                            { slug: "store/1000047381/dnemarket", name: "Tudo para o meu carro" },
                            { slug: "store/1000047381/dnemarket", name: "Viagens" },
                            { slug: "store/1000047381/dnemarket", name: "Manutenção e reparos "}
                        ]
                    },
                    PhCategoriesFeatured: [
                        { icon: "regresso-as-aulas.svg", slug: "store/1000047381/dnemarket", name: "Regresso às aulas" },
                        { icon: "trocar-de-telemovel.svg", slug: "store/1000047381/dnemarket", name: "Trocar de telemóvel" },
                        { icon: "roupas-de-inverno.svg", slug: "store/1000047381/dnemarket", name: "Roupas de verão" },
                        { icon: "presentes-de-natal.svg", slug: "store/1000047381/dnemarket", name: "Presentes de Natal" },
                        { icon: "tudo-para-meu-carro.svg", slug: "store/1000047381/dnemarket", name: "Tudo para o meu carro" },
                        { icon: "viagens.svg", slug: "store/1000047381/dnemarket", name: "Viagens" },
                        { icon: "manutencao-e-reparos.svg", slug: "store/1000047381/dnemarket", name: "Manutenção e reparos" },
                        { icon: "regresso-as-aulas.svg", slug: "store/1000047381/dnemarket", name: "Regresso às aulas" },
                        { icon: "trocar-de-telemovel.svg", slug: "store/1000047381/dnemarket", name: "Trocar de telemóvel" },
                        { icon: "regresso-as-aulas.svg", slug: "store/1000047381/dnemarket", name: "Regresso às aulas" },
                        { icon: "trocar-de-telemovel.svg", slug: "store/1000047381/dnemarket", name: "Trocar de telemóvel" },
                        { icon: "roupas-de-inverno.svg", slug: "store/1000047381/dnemarket", name: "Roupas de verão" }
                    ],
                    PhCategoriesMosaic: {
                        placeholder_name: "",
                        data: []
                    },
                    PhProductsTop10: [],
                    // PhProductsTop10: {
                    //     placeholder_name: "Produtos em destaque",
                    //     data: [
                    //         { slug: "pt/store/1000049406/apple-iphone-14-pro-max-black-512-gb/product/1000047466/variant/1000049408", name: "Casaco de inverno", image: "casaco-inverno-tommy-jeans.jpg", store: "C&A", brand: "Tommy Jeans", discount: "11", price: "149", highlights: ["free-shipping"] },
                    //         { slug: "pt/store/1000049406/apple-iphone-14-pro-max-black-512-gb/product/1000047466/variant/1000049408", name: "Sweat - Preto", image: "sweat-preto.jpg", store: "Nike Store", brand: "Nike", discount: "", price: "199", highlights: ["free-shipping","to-order"] },
                    //         { slug: "pt/store/1000049406/apple-iphone-14-pro-max-black-512-gb/product/1000047466/variant/1000049408", name: "Blusão com capuz Therma-FIT Repel Windrunner - Bege", image: "blusao-com-capuz.jpg", store: "Nike Store", brand: "Nike", discount: "23", price: "229", highlights: [] },
                    //         { slug: "pt/store/1000049406/apple-iphone-14-pro-max-black-512-gb/product/1000047466/variant/1000049408", name: "iPhone 13 recondicionado - Lumière stellaire - 128 GB", image: "iphone-13.jpg", store: "NOS", brand: "Apple", discount: "5", price: "1380", highlights: [] },
                    //         { slug: "pt/store/1000049406/apple-iphone-14-pro-max-black-512-gb/product/1000047466/variant/1000049408", name: "Blusão com capuz Therma-FIT Repel Windrunner - Bege", image: "blusao-com-capuz.jpg", store: "Nike Store", brand: "Nike", discount: "", price: "219", highlights: ["free-shipping"] },
                    //         { slug: "pt/store/1000049406/apple-iphone-14-pro-max-black-512-gb/product/1000047466/variant/1000049408", name: "iPhone 13 recondicionado - Lumière stellaire - 128 GB", image: "iphone-13.jpg", store: "NOS", brand: "Apple", discount: "", price: "1180", highlights: [] },
                    //         { slug: "pt/store/1000049406/apple-iphone-14-pro-max-black-512-gb/product/1000047466/variant/1000049408", name: "Casaco de inverno", image: "casaco-inverno-tommy-jeans.jpg", store: "C&A", brand: "Tommy Jeans", discount: "", price: "98", highlights: [] }
                    //     ]
                    // },
                    PhBrands: {
                        placeholder_name: "",
                        data: [
                            { attachment: "amige-internacional.png", slug: "store/1000047381/dnemarket", name: "Amige Internacional" },
                            { attachment: "adidas.svg", slug: "store/1000047381/dnemarket", name: "Adidas" },
                            { attachment: "dne-token.svg", slug: "store/1000047381/dnemarket", name: "DNE Token" },
                            { attachment: "nike.svg", slug: "store/1000047381/dnemarket", name: "Nike" },
                            { attachment: "amige.svg", slug: "store/1000047381/dnemarket", name: "Amige" },
                            { attachment: "amige-internacional.png", slug: "store/1000047381/dnemarket", name: "Amige Internacional" },
                            { attachment: "adidas.svg", slug: "store/1000047381/dnemarket", name: "Adidas" },
                            { attachment: "dne-token.svg", slug: "store/1000047381/dnemarket", name: "DNE Token" },
                            { attachment: "nike.svg", slug: "store/1000047381/dnemarket", name: "Nike" },
                            { attachment: "amige.svg", slug: "store/1000047381/dnemarket", name: "Amige" },
                            { attachment: "amige-internacional.png", slug: "store/1000047381/dnemarket", name: "Amige Internacional" },
                            { attachment: "adidas.svg", slug: "store/1000047381/dnemarket", name: "Adidas" },
                            { attachment: "dne-token.svg", slug: "store/1000047381/dnemarket", name: "DNE Token" },
                            { attachment: "nike.svg", slug: "store/1000047381/dnemarket", name: "Nike" },
                            { attachment: "amige.svg", slug: "store/1000047381/dnemarket", name: "Amige" },
                            { attachment: "amige-internacional.png", slug: "store/1000047381/dnemarket", name: "Amige Internacional" }                            
                        ]
                    },
                    PhCategoryFeatured: {
                        placeholder_name: "Turismo",
                        data: [
                            {
                                name: "Viagens e pacotes de férias",
                                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                                attachment: "featured-theme.jpg"
                            }
                        ]
                    },
                    PhCategoriesTop10: {
                        placeholder_name: "",
                        data: [
                            { slug: "store/1000047381/dnemarket", image: "top10-01.jpg", name: "Regresso às aulas" },
                            { slug: "store/1000047381/dnemarket", image: "top10-02.jpg", name: "Trocar de telemóvel" },
                            { slug: "store/1000047381/dnemarket", image: "top10-03.jpg", name: "Roupas de verão" },
                            { slug: "store/1000047381/dnemarket", image: "top10-04.jpg", name: "Manutenção e reparos" },
                            { slug: "store/1000047381/dnemarket", image: "top10-05.jpg", name: "Viagens" },
                            { slug: "store/1000047381/dnemarket", image: "top10-06.jpg", name: "Presentes de Natal" },
                            { slug: "store/1000047381/dnemarket", image: "top10-07.jpg", name: "Tudo para o meu carro" },
                            { slug: "store/1000047381/dnemarket", image: "top10-01.jpg", name: "Regresso às aulas" },
                            { slug: "store/1000047381/dnemarket", image: "top10-02.jpg", name: "Trocar de telemóvel" },
                            { slug: "store/1000047381/dnemarket", image: "top10-03.jpg", name: "Roupas de verão" }
                        ]
                    },
                }
            };
        },

        async mounted() {
            // this.languageSelected()
            // this.logged = true
            // this.goTop()


            // TEMPLATE
            // await apiMarket.get('/api/v1/layout/template/store/dnemarket/page/home')
            // .then(response => {
            //     response.data.containers.forEach(cnt => {
            //         if (cnt.placeholder_tag == "PhCategoriesHeader"){this.components.PhCategoriesHeader.type_code = cnt.type_code; this.components.PhCategoriesHeader.data = cnt.data;}
            //         else if (cnt.placeholder_tag == "PhCategoriesFeatured"){this.components.PhCategoriesFeatured = cnt.data;}
            //         else if (cnt.placeholder_tag == "PhCategoriesMosaic"){this.components.PhCategoriesMosaic.placeholder_name = cnt.placeholder_name; this.components.PhCategoriesMosaic.data = cnt.data;}
            //         else if (cnt.placeholder_tag == "PhProductsTop10"){this.components.PhProductsTop10.placeholder_name = cnt.placeholder_name; this.components.PhProductsTop10.data = cnt.data;}
            //         else if (cnt.placeholder_tag == "PhBrands"){this.components.PhBrands.placeholder_name = cnt.placeholder_name; this.components.PhBrands.data = cnt.data;}
            //         else if (cnt.placeholder_tag == "PhCategoryFeatured"){this.components.PhCategoryFeatured.placeholder_name = cnt.placeholder_name; this.components.PhCategoryFeatured.data = cnt.data;}
            //         else if (cnt.placeholder_tag == "PhCategoriesTop10"){this.components.PhCategoriesTop10.placeholder_name = cnt.placeholder_name; this.components.PhCategoriesTop10.data = cnt.data;}
            //     });
            // })
            // .catch(error => {
            //     console.log(error)
            // })

            // TEMP
            // const resumeTop10 = await apiMarket.get("/api/v1/market/product/filter?include_variants=false&include_attachments=false&store_reference=1000095778&store_reference=1000053841&store_reference=1000049406")
            const resumeTop10 = await apiMarket.get("/api/v1/market/product/filter?include_variants=false&include_attachments=false&store_reference=1000047381")
            this.components.PhProductsTop10 = resumeTop10.data.records
            setTimeout(() => {
                this.showContent = true
            }, 100);
        },

        methods: {
            // languageSelected(){
            //     this.flagSelected = this.$store.state.SelectLanguage.code
            // },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            // goTop(){
            //     $('html, body').animate({scrollTop : 0},200);
            //     return false;
            // }
        },
        
        // watch: {
        //     '$store.state.SelectLanguage.code': {
        //         immediate: true,
        //         handler() {
        //         this.languageSelected();
        //         }
        //     }
        // },

        created() {
            // document.title = this.getMyLanguage("seo", "title.home");
            // document.head.querySelector("meta[name=description]").content = this.getMyLanguage("seo", "title.description.home");
        },
    };
</script>

<style src="./custom-home.scss" lang="scss" scoped />

<style>
    .search-home .container-fluid p strong,
    .search-home .container-fluid h3 strong{color: #4aae57;}
</style>