export default {
    data(){
        return {

            // UPLOAD LOGO
            uploadFileLogo: {
                File: null,
                typeFile: '',
                selected: false,
                loadUpload: false,
                previewURLImage: ""
            },
            
            
            // UPLOAD HEADER STORE
            uploadFileHeaderStore: {
                File: null,
                typeFile: '',
                selected: false,
                loadUpload: false,
                previewURLImage: ""
            },


            // CHANGE HEADER STORE
            selectedHeaderStore: {
                id: "",
                preview: false
            },


            flowHeaderStore: {
                modal: false,
                load: false,
                showUpload: false,
                confirm: false
            },

            flowThemeStore: {
                modal: false,
                load: false,
                theme: "",
                device: "",
                confirm: false
            },
            

            dataSendLogo: {
                logo: ''
            },

            errorsGetDocs: {
                dataDocs: false
            },

            errorsPostDocs: {
                upload: false
            },
            
            errorsPostHeaderStore: {
                upload: false
            },


            // OPTIONS HEADER STORE
            // headersStore: [
            //     {
            //         category: "Technology",
            //         id: "0001",
            //         name: "Tech 01",
            //         image: "/images/headerstore/technology-01.jpg"
            //     },
            //     {
            //         category: "Technology",
            //         id: "0002",
            //         name: "Tech 02",
            //         image: "/images/headerstore/technology-02.jpg"
            //     },
            //     {
            //         category: "Technology",
            //         id: "0003",
            //         name: "Tech 03",
            //         image: "/images/headerstore/technology-03.jpg"
            //     },
            //     {
            //         category: "Technology",
            //         id: "0004",
            //         name: "Tech 04",
            //         image: "/images/headerstore/technology-04.jpg"
            //     }
            // ],


            // ADD ADDRESS
            flowAddressAdd: {
                modal: false,
                load: false,
                address: {},
                status: ""
            },

            // REMOVE ADDRESS
            flowAddressRemove: {
                modal: false,
                load: false,
                address: {}
            }
        }
    },

    async mounted(){
        
    },
    
    methods: {

        // UPLOAD LOGO
        uploadLogo() {
            this.uploadFileLogo.File = this.$refs.fileLogo.files[0];
            console.log(this.uploadFileLogo)

            if ( this.uploadFileLogo.File != null ){
                this.uploadFileLogo.selected = "fileSelected"
                
                const fileURL = URL.createObjectURL(this.uploadFileLogo.File);
                this.uploadFileLogo.previewURLImage = fileURL;
                
                setTimeout(() => {
                  URL.revokeObjectURL(fileURL);
                }, 10000);
            }
        },


        // UPLOAD HEADER STORE
        uploadHeaderStore() {
            this.uploadFileHeaderStore.File = this.$refs.fileHeaderStore.files[0];
            console.log(this.uploadFileHeaderStore)

            if ( this.uploadFileHeaderStore.File != null ){
                this.uploadFileHeaderStore.selected = "fileSelected"
                
                const fileURL = URL.createObjectURL(this.uploadFileHeaderStore.File);
                this.uploadFileHeaderStore.previewURLImage = fileURL;
                this.uploadFileHeaderStore.resumeFile = true

                // this.dataMerchant.store.banner_attachment = this.uploadFileHeaderStore.File
                
                setTimeout(() => {
                  URL.revokeObjectURL(fileURL);
                }, 10000);

                this.uploadFileHeaderStore.typeFile = this.uploadFileHeaderStore.File.type.split('/')[0]
            }
        },


        // HEADER STORE
        changeHeaderStore(id,img){
            this.selectedHeaderStore.id = id
            this.selectedHeaderStore.preview = true
            this.dataMerchant.store.banner_attachment = img
            // console.log(this.dataHeaderStore.filter(hs => hs.banner.attachment.id == id))
            // this.dataMerchant.store.banner_attachment = this.dataHeaderStore.filter(hs => hs.id == id).banner.attachment.attachment
            
            this.flowHeaderStore.load = true

            setTimeout(() => {
                this.flowHeaderStore.load = false
                this.flowHeaderStore.modal = false
                // this.$toast.success(this.getMyLanguage("Store","merchant.store.confirm.headerStore.toast"))
                this.$toast.success("Preview disponível")
            }, 500);
        },

        changeHeaderStorePreview(){
            this.flowHeaderStore.load = true
            
            setTimeout(() => {
                this.selectedHeaderStore.preview = true
                this.dataMerchant.store.banner_attachment = this.uploadFileHeaderStore.previewURLImage
                this.flowHeaderStore.load = false
                this.flowHeaderStore.modal = false
                // this.$toast.success(this.getMyLanguage("Store","merchant.store.confirm.headerStore.toast"))
                this.$toast.success("Preview disponível")
            }, 500);
        },


        // THEMES
        previewTheme(value){
            this.flowThemeStore.modal = true
            this.flowThemeStore.theme = value
            this.usedDevice()
        },

        usedDevice(){
            const largura = window.innerWidth
            if (largura <= 576) {
                this.flowThemeStore.device = 'mobile'
            } else if (largura <= 992) {
                this.flowThemeStore.device = 'tablet'
            } else {
                this.flowThemeStore.device = 'desktop'
            }
        },

        changeThemeStore(){
            this.flowThemeStore.load = true

            setTimeout(() => {
                this.flowThemeStore.load = false
                this.flowThemeStore.modal = false
                this.flowThemeStore.devide = ""
                this.flowThemeStore.confirm = false
                this.dataMerchant.store.store_details.theme = this.flowThemeStore.theme.slug

                this.$toast.success(this.getMyLanguage("Store","merchant.store.confirm.themeStore.toast"))
            }, 1500);
            
            // setTimeout(this.$toast.clear, 1000)
        },

        

        // ADDRESS CONTACT
        changeContact(value){
            this.dataMerchant.store.store_details.contact_account_address_id = value.account_address_id
            
            // this.dataMerchant.store.store_details.address = value.address_1 + ", " + value.number + ", " + value.address_2 + " " + value.postal_code
            // this.dataMerchant.store.store_details.address = `${value.address_1}, ${value.number}, ${value.address_2} ${value.postal_code}`
            this.dataMerchant.store.store_details.address = `${value.address_1}, ${value.number}${value.address_2 ? `, ${value.address_2}` : ''} ${value.postal_code}`;

            
            // this.dataMerchant.store.store_details.region = value.city + " | " + value.state + " | " + this.dataCountry.filter(cc => cc.id == value.country_id)[0].name
            this.dataMerchant.store.store_details.region = `${value.city} | ${value.state} | ${this.dataCountry.find(cc => cc.Id === value.country_id)?.Name}`

            // this.flowContact.action = value

            // if ( value == 'new' ){
            //     this.flowContact.modalAddressContact = true
            // } else {
            //     this.flowContact.modalAddressContact = false
            //     this.addressStoreDetails()
            // }

            const previewElement = document.getElementById('previewAddress');
            if (previewElement) {
                previewElement.scrollIntoView({ behavior: 'smooth' });
            }
        },


        // BUSINESS HOURS
        showHours(value){
            this.flowContact.showBusinessHours = value

            if ( !this.dataMerchant.store.store_details.business_hours.length ){
                this.addBusinessHour()
            }
        },

        addBusinessHour(){
            this.dataMerchant.store.store_details.business_hours.push("")

            var bhLength = this.dataMerchant.store.store_details.business_hours.length - 1

            setTimeout(() => {
                document.getElementById(`bh${bhLength}`).focus();
            }, 100);
        },

        removeBusinessHour(index) {
            this.dataMerchant.store.store_details.business_hours.splice(index, 1);
        },



        // ADDRESS DOCK
        changeDock(value){
            this.dataMerchant.store.store_details.warehouse_account_address_id = value.account_address_id

            // this.flowDock.action = value

            // if ( value == "new" ){
            //     this.flowDock.modalAddressDock = true
            // } else {
            //     this.flowDock.modalAddressDock = false
            // }
        },


        // GENERATE SLUG STORE
        generateSlugStore(value) {
            const name = value.toLowerCase();
            const slug = name.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[^\w\s-]/g, "-")
            .replace(/\s+/g, "-")
            .replace(/--+/g, "-");
            return this.dataMerchant.store.slug = slug;
        },
    }
}