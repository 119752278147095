<template>
    <div class="breadcrumb">
        <div class="container-fluid">
            <ul>
                <li>
                    <a :href="`/${flagSelected}/`">
                        {{ getMyLanguage("Breadcrumb", "home") }}
                    </a>
                </li>

                <li v-for="(breadcrumb, index) in dataSource" :key="index">
                    <a :href="`/${breadcrumb.slug}`" v-if="breadcrumb.slug">
                        {{breadcrumb.name}}
                    </a>

                    <span v-if="!breadcrumb.slug">
                        {{breadcrumb.name}}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        props: {
            dataSource: {}
        },

        data() {
            return {
                flagSelected: "en"
            }
        },
        
        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                setTimeout(() => {
                    if ( this.dataSource.length ){
                        this.dataSource.forEach(item => {
                            if ( item.slug ){
                                item.slug = item.slug.replace(/^([a-z]{2})/, this.$store.state.SelectLanguage.code);
                            }
                        });
                    }
                }, 1500);
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';

    .account{
        .breadcrumb{
            ul{
                li{
                    color: $color-text-tertiary;

                    a{
                        color: rgba($color: $color-text-tertiary, $alpha: 0.6);
                    }

                    @media (min-width: 992px){
                        &:not(:last-of-type){
                            &:after{
                                content: " > ";
                                color: rgba($color: $color-text-tertiary, $alpha: 0.6);
                                display: block;
                                margin: 0 10px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>