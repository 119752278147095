const $ = require('jquery')
window.$ = $

import apiInternational from '@/config/apiInternational.js'

export default {
    data(){
        return {
            modalDocument: {
                modal: false,
                status: "",
            },

            dataCompliance: [],

            uploadFileDocument: {
                File: null,
                selected: false,
                documentType: "1",
                loadUpload: false,
                isImage: false,
                resumeFile: false,
                previewURLImage: ""
            },

            dataSendDoc: {
                accountDocumentTypeCode: '',
                Observations: ''
            },

            errorsGetDocs: {
                dataCompliance: false
            },

            errorsPostDocs: {
                upload: false
            },
        }
    },

    async mounted(){
        
    },

    methods: {
        // async loadDocs(){
        //     const token = localStorage.getItem('accessToken');
        //     let code = localStorage.getItem('codeLanguage');
        //     let companyReference = this.dataMerchant.company.reference
    
        //     await apiInternational.get('/api/v1/international/compliance/company', { headers: { 'Authorization': `bearer ${token}`, 'content-language': code, 'Company-Reference': companyReference } })
        //     .then(response => {
        //         this.dataCompliance = response.data
        //     })
        //     .catch(error => {
        //         this.errorsGetDocs.dataCompliance = true
        //         console.log(error)
        //     })
        // },


        openModalUploadDocument(typeCode, status){
            this.dataSendDoc.accountDocumentTypeCode = typeCode
            this.modalDocument.modal = true
            this.modalDocument.status = status
        },

        uploadDocument() {
            this.uploadFileDocument.File = this.$refs.fileDocument.files[0];
            
            if ( this.uploadFileDocument.File != null ){
                this.uploadFileDocument.selected = "fileSelected"
                
                const fileURL = URL.createObjectURL(this.uploadFileDocument.File);
                this.uploadFileDocument.previewURLImage = fileURL;
                this.uploadFileDocument.resumeFile = true
                
                setTimeout(() => {
                  URL.revokeObjectURL(fileURL);
                }, 10000);
            }

            if (this.uploadFileDocument.File.type.startsWith('image/')) {
                this.uploadFileDocument.isImage = true
            } else {
                this.uploadFileDocument.isImage = false
            }
        },

        formatFileSize(fileSize) {
            if (fileSize === 0) return '0 Bytes';
            const k = 1024;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            const i = Math.floor(Math.log(fileSize) / Math.log(k));
            return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        },


        // UPLOAD
        uploadDoc(){
            this.uploadFileDocument.loadUpload = true
            const formData = new FormData();
            formData.append('File', this.uploadFileDocument.File);
            // formData.append('AccountDocumentTypeId', this.dataSendDoc.accountDocumentTypeId);
            formData.append('Type', this.dataSendDoc.accountDocumentTypeCode);
            formData.append('Observations', this.dataSendDoc.Observations);
            const token = localStorage.getItem('accessToken');
            // const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}`, 'Company-Reference': localStorage.getItem("companyReference") };
            const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}`, 'Company-Reference': this.dataMerchant.reference };
            
            apiInternational.post("/api/v1/international/compliance/company/document", formData, { headers })
            .then(response => {
                
                // this.dataMerchant.documents = response.data.documents
                console.log(response)
                location.reload()
                
                // console.clear()
                // this.loadProfile()
                
                // this.uploadFileDocument.File = null
                // this.uploadFileDocument.selected = false
                // this.uploadFileDocument.loadUpload = false
                // this.dataSendDoc.Observations = ""
                
                // setTimeout(() => {
                //     this.modalDocument.modal = false
                //     this.$toast.success(this.getMyLanguage("Store","merchant.compliance.msg.document-sent"));
                // }, 1000);
                
                // if ( this.dataCompliance.documentsValidated ){
                //     window.location.href = '#Docs'
                //     this.uploadFileDocument.loadUpload = false
                // }
                
            })
            .catch(error => {
                console.log(error)
                this.errorsPostDocs.upload = "error upload"

                setTimeout(() => {
                    this.errorsPostDocs.upload = false
                    this.uploadFileDocument.loadUpload = false
                }, 10000);
            })
        },
    }
}