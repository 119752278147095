<template>
    <div class="vouchers">
        <div class="form-voucher">
            <h3>Cupom</h3>
            <div>
                <input type="text" class="form"
                :class="{error: voucher.error}"
                @keyup="handleKeyUp()"
                @keyup.enter="handleEnterKey()"
                v-model="voucher.add"
                placeholder="Informe o código">

                <span class="btn btn-disabled" v-if="voucher.add.length < 5">
                    Validar
                </span>

                <span class="btn" v-if="voucher.add.length >= 5" @click="validateVoucher()">
                    Validar
                </span>
            </div>
        </div>


        <!-- LIST -->
        <div class="list-voucher" v-if="dataVouchers.vouchers.length && !removeVoucher.show">
            <div class="row-voucher" v-for="(list, index) in dataCheckoutPut.vouchers" :key="index">
                <div class="item">{{list}}</div>
                <div class="del" @click="remove(list)"></div>
            </div>
        </div>


        <!-- REMOVE VOUCHER -->
        <div class="remove-voucher" v-if="removeVoucher.show">
            <h4>{{removeVoucher.value}}</h4>
            <p>Excluir cupão?</p>

            <div class="bts">
                <div class="btn-primary btn-no" @click="removeVoucher.show = false, removeVoucher.value = ''">
                    Não
                </div>
                
                <div class="btn-primary btn-yes" @click="delVoucher(removeVoucher.value)">
                    Sim
                </div>
            </div>
        </div>


        <!-- MESSAGE -->
        <!-- <div class="msg accepted">
            <span>Cupom aplicado</span>
        </div> -->
    </div>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import apiMarket from '@/config/apiMarket.js'
    import bus from '@/eventBus';

    export default{
        props: {
            dataVouchers: Object
        },

        data (){
            return {
                flagSelected: "en",
                
                removeVoucher: {
                    show: false,
                    value: ""
                },

                dataCheckoutPut: "",

                voucher: {
                    add: "",
                    error: false
                }
            }
        },

        async mounted(){
            setTimeout(() => {
                this.dataCheckoutPut = this.dataVouchers
                // console.log(this.dataCheckoutPut)
            }, 100);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            handleKeyUp() {
                if ( this.voucher.add.length >= 5 ) {
                    this.voucher.error = false;
                }
            },

            handleEnterKey() {
                if ( this.voucher.add.length >= 5 ) {
                    this.validateVoucher();
                    this.voucher.error = false
                } else{
                    this.voucher.error = true
                }
            },

            validateVoucher() {
                // this.voucher.list.push({ name: this.voucher.add, status: "validated" });
                this.dataCheckoutPut.vouchers.push(this.voucher.add);
                this.voucher.add = "";
                
                apiMarket.put(`/api/v1/market/checkout/${this.$cookies.get("idCart")}`, this.dataCheckoutPut)
                .then(response => {
                    this.$toast.success("Voucher ativado com sucesso")
                    bus.emit('updateMiniCart', response.data)
                })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("Houve um erro ao adicionar o voucher")
                })
            },

            remove(value){
                this.removeVoucher.show = true
                this.removeVoucher.value = value
            },

            delVoucher(value){
                const index = this.dataCheckoutPut.vouchers.indexOf(value);
                if (index !== -1) {
                    this.dataCheckoutPut.vouchers.splice(index, 1);

                    apiMarket.put(`/api/v1/market/checkout/${this.$cookies.get("idCart")}`, this.dataCheckoutPut)
                    .then(response => {
                        this.$toast.warning("Voucher removido")
                        bus.emit('updateMiniCart', response.data)
                        this.removeVoucher.show = false
                    })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("Houve um erro ao adicionar o voucher")
                        this.removeVoucher.show = false
                    })
                }
            }
        },


        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>


<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';

    .vouchers{
        margin-bottom: 30px;
        background: #eee;
        border: 1px solid #eee;
        border-radius: 12px;
        padding: 16px;
    }

    .vouchers .form-voucher h3{
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 8px;
    }

    .vouchers .form-voucher > div{
        display: flex;
        align-items: center;
    }

    .vouchers .form-voucher > div .form{
        border: 1px solid #ccc;
        border-right: none;
        background: $color-text-tertiary;
        border-radius: 12px 0 0 12px;
        margin: 0;
        height: 38px;
        line-height: 38px;
    }

    .vouchers .form-voucher > div .btn{
        padding: 0 1rem;
        font-size: 12px;
        line-height: 38px;
        height: 38px;
        background: $color-primary;
        color: $color-text-tertiary;
        border-radius: 0 12px 12px 0;
    }


    /* LIST */
    .vouchers .list-voucher{
        width: 100%;
        margin-top: 1.5rem;
        padding: 5px 10px;
        border: 1px solid rgba(74, 174, 87, 0.3);
        background: rgba(74, 174, 87, 0.1);
    }

    .vouchers .list-voucher .row-voucher{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .vouchers .list-voucher .row-voucher:not(:last-child){
        padding-bottom: 3px;
        margin-bottom: 3px;
        border-bottom: 1px solid rgba(74, 174, 87, 0.3);
    }

    .vouchers .list-voucher .row-voucher .item{
        font-size: 12px;
        line-height: 14px;
        font-weight: bold;
        text-transform: uppercase;
        white-space: nowrap;
        width: calc(100% - 40px);
    }

    .vouchers .list-voucher .row-voucher .del{
        width: 30px;
        height: 30px;
        border-radius: 6px;
        background: url('~@/assets/images/icons/remove.svg') no-repeat center rgba($color: $color-text-tertiary, $alpha: 0.3);
        background-size: 10px auto;
        
        @media (min-width: 992px){
            transition: .3s;
            cursor: pointer;

            &:hover{
                background-color: rgba($color: $color-text-tertiary, $alpha: 1.0);
            }
        }
    }


    /* REMOVE */
    .vouchers .remove-voucher{
        width: 100%;
        margin-top: 1.5rem;
        padding: 10px;
        text-align: center;
        /* border: 1px solid rgba(235, 104, 52, 0.3); */
        background: rgba(235, 104, 52, 0.1);
    }

    .vouchers .remove-voucher h4{
        padding: 5px 10px;
        background: rgba(255, 255, 255, 0.3);
        border: 2px dashed rgba(235, 104, 52, 0.6);
        font-size: 18px;
        line-height: 22px;
        color: $color-pending;
        text-transform: uppercase;
    }

    .vouchers .remove-voucher p{
        font-size: 16px;
        line-height: 20px;
        margin: 1.5rem 0;
    }

    .vouchers .remove-voucher .bts{
        display: flex;
        justify-content: center;
    }

    .vouchers .remove-voucher .bts .btn-primary{
        width: auto;
        height: 36px;
        padding: 0 1rem;
    }

    .vouchers .remove-voucher .bts .btn-primary:after{
        display: none;
    }

    .vouchers .remove-voucher .bts .btn-no{
        background: $color-cancel;
        margin-right: 1rem;
    }


    /* MESSAGE */
    .vouchers .msg{
        margin-top: .5rem;
        padding: .5rem 1rem;
        border-radius: 6px;
        font-size: 13px;
        line-height: 17px;
        text-align: center;
    }

    .vouchers .msg.accepted{
        border: 1px solid rgba(74, 174, 87, 0.5);
        background: rgba(74, 174, 87, 0.1);
        color: $color-secondary;
    }

    .vouchers .msg.denied{
        border: 1px solid rgba(235, 104, 52, 0.5);
        background: rgba(235, 104, 52, 0.1);
    }
</style>