<template>
  <div class="dne-market">
    <div id="dne"></div>
    <Header :dataSource="headerTemplate" />
    
    <!-- BANNER FULL -->
    <!-- <div class="banner-full">
        <div class="container-fluid">
            <img src="~@/assets/images/banners/sport-zone-full.jpg">
        </div>
    </div> -->


    <!-- BREADCRUMB -->
    <div class="breadcrumb">
        <div class="container-fluid">
            <ul>
                <li><router-link :to="{ name: 'Home' }">{{getMyLanguage("Breadcrumb","home")}}</router-link></li>
                <li>Sport Zone</li>
            </ul>
        </div>
    </div>

    
    <!-- CONTENT RESULTS -->
    <section class="content-results">
        <div class="container-fluid">
            <div class="row">
                <div class="sidebar">
                    <img src="~@/assets/images/brands/sport-zone.png" class="logo">

                    <div class="open-filters" v-if="!filters.opened" @click='showFilters'></div>
                    <div class="close-filters" v-if="filters.opened" @click='showFilters'></div>

                    <div class="content-filters" :class="{active: filters.opened}">
                        <ul class="categories" id="brandCategories">
                            <li>
                                <a href="javascript:;" class="has-child" @click='subcategories.sub01 = true' v-if="!subcategories.sub01">Categoria 01</a>
                                <a href="javascript:;" class="has-child close" @click='subcategories.sub01 = false' v-if="subcategories.sub01">Categoria 01</a>
                                <ul class="subcategories" v-if="subcategories.sub01">
                                    <li><a href="">Subcategoria 01</a></li>
                                    <li><a href="">Subcategoria 02</a></li>
                                    <li><a href="">Subcategoria 03</a></li>
                                    <li><a href="">Subcategoria 04</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="javascript:;" class="has-child" @click='subcategories.sub02 = true' v-if="!subcategories.sub02">Categoria 02</a>
                                <a href="javascript:;" class="has-child close" @click='subcategories.sub02 = false' v-if="subcategories.sub02">Categoria 02</a>
                                <ul class="subcategories" v-if="subcategories.sub02">
                                    <li><a href="">Subcategoria 01</a></li>
                                    <li><a href="">Subcategoria 02</a></li>
                                    <li><a href="">Subcategoria 03</a></li>
                                    <li><a href="">Subcategoria 04</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="javascript:;" class="has-child" @click='subcategories.sub03 = true' v-if="!subcategories.sub03">Categoria 03</a>
                                <a href="javascript:;" class="has-child close" @click='subcategories.sub03 = false' v-if="subcategories.sub03">Categoria 03</a>
                                <ul class="subcategories" v-if="subcategories.sub03">
                                    <li><a href="">Subcategoria 01</a></li>
                                    <li><a href="">Subcategoria 02</a></li>
                                    <li><a href="">Subcategoria 03</a></li>
                                    <li><a href="">Subcategoria 04</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="javascript:;" class="has-child" @click='subcategories.sub04 = true' v-if="!subcategories.sub04">Categoria 04</a>
                                <a href="javascript:;" class="has-child close" @click='subcategories.sub04 = false' v-if="subcategories.sub04">Categoria 04</a>
                                <ul class="subcategories" v-if="subcategories.sub04">
                                    <li><a href="">Subcategoria 01</a></li>
                                    <li><a href="">Subcategoria 02</a></li>
                                    <li><a href="">Subcategoria 03</a></li>
                                    <li><a href="">Subcategoria 04</a></li>
                                </ul>
                            </li>
                            <li><a href="#">Categoria 05</a></li>
                            <li><a href="#">Categoria 06</a></li>
                            <li><a href="#">Categoria 07</a></li>
                        </ul>


                        <ul class="filters" id="result-filters">
                            <!-- <h2>{{getMyLanguage("Filter","sidebar.title")}}</h2>

                            <li class="search-text">
                                <h3>{{getMyLanguage("Filter","sidebar.search-text")}}</h3>
                                <p>Informática</p>
                            </li> -->

                            <li class="filter-type">
                                <h3>{{getMyLanguage("Filter","sidebar.brands")}}</h3>
                                <ul>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeAll" class="form-check" v-model="filters.brands.all">
                                            <label for="typeAll" class="form-check-label"><span>Todos as marcas</span><small class="qtd">1279</small></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeProduct" class="form-check" v-model="filters.brands.product" checked>
                                            <label for="typeProduct" class="form-check-label"><span>Nike</span><small class="qtd">698</small></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeService" class="form-check" v-model="filters.brands.service">
                                            <label for="typeService" class="form-check-label"><span>Adidas</span><small class="qtd">126</small></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeClassified" class="form-check" v-model="filters.brands.classified">
                                            <label for="typeClassified" class="form-check-label"><span>Eko</span><small class="qtd">455</small></label>
                                        </div>
                                    </li>
                                </ul>
                            </li>


                            <li class="filter-type">
                                <h3>{{getMyLanguage("Filter","sidebar.type")}}</h3>
                                <ul>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeAll" class="form-check" v-model="filters.type.all" checked>
                                            <label for="typeAll" class="form-check-label"><span>Todos os tipos</span><small class="qtd">1279</small></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeProduct" class="form-check" v-model="filters.type.product">
                                            <label for="typeProduct" class="form-check-label"><span>Produtos</span><small class="qtd">698</small></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeService" class="form-check" v-model="filters.type.service">
                                            <label for="typeService" class="form-check-label"><span>Serviços</span><small class="qtd">126</small></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeClassified" class="form-check" v-model="filters.type.classified">
                                            <label for="typeClassified" class="form-check-label"><span>Classificados</span><small class="qtd">455</small></label>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li class="filter-region">
                                <h3>Paises/regiões</h3>
                                <ul>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="countryRegionPortugal" class="form-check" v-model="filters.countryRegion.Portugal" checked>
                                            <label for="countryRegionPortugal" class="form-check-label"><span>Portugal</span><small class="qtd">325</small></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="countryRegionEspanha" class="form-check" v-model="filters.countryRegion.Espanha">
                                            <label for="countryRegionEspanha" class="form-check-label"><span>Espanha</span><small class="qtd">480</small></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="countryRegionFranca" class="form-check" v-model="filters.countryRegion.Franca">
                                            <label for="countryRegionFranca" class="form-check-label"><span>França</span><small class="qtd">382</small></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="countryRegionSuíca" class="form-check" v-model="filters.countryRegion.Suíca">
                                            <label for="countryRegionSuíca" class="form-check-label"><span>Suíca</span><small class="qtd">265</small></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="countryRegionItalia" class="form-check" v-model="filters.countryRegion.Italia">
                                            <label for="countryRegionItalia" class="form-check-label"><span>Itália</span><small class="qtd">276</small></label>
                                        </div>
                                    </li>

                                    <!-- MORE FILTER -->
                                    <div class="btn-more-filter" v-if="!filters.countryRegionMore" @click="filters.countryRegionMore = true"><span>ver mais</span></div>

                                    <div v-if="filters.countryRegionMore">
                                        <li>
                                            <div class="form-group-check">
                                                <input type="checkbox" id="countryRegionAlemanha" class="form-check" v-model="filters.countryRegion.Alemanha">
                                                <label for="countryRegionAlemanha" class="form-check-label"><span>Alemanha</span><small class="qtd">526</small></label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-group-check">
                                                <input type="checkbox" id="countryRegionLuxemburgo" class="form-check" v-model="filters.countryRegion.Luxemburgo">
                                                <label for="countryRegionLuxemburgo" class="form-check-label"><span>Luxemburgo</span><small class="qtd">146</small></label>
                                            </div>
                                        </li>
                                    </div>
                                </ul>
                            </li>

                            <li class="filter-price">
                                <h3>Preço</h3>
                                
                                <p class="price-min-max">
                                    <span>19</span>
                                    <span>1738</span>
                                </p>

                                <input type="range" :min="19" :max="1738" step="1" v-model="filters.rangePrice" @touchend="upSummary(dataPurchase.quantity)">

                                <p class="up-to">Até {{filters.rangePrice}}</p>
                            </li>
                        </ul>
                    </div>
                </div>


                <div class="results">
                    <div class="top-banner">
                        <img src="~@/assets/images/banners/sport-zone-full.jpg">
                    </div>

                    <!-- <h2 class="title-result"><strong>Sport Zone</strong></h2> -->
                    <div class="active-filters">
                        <div class="filter"><span>Nike</span></div>
                        <div class="filter"><span>Casacos</span></div>
                    </div>

                    <!-- PRODUCTS -->
                    <div class="products">
                        <a :href='"/" + flagSelected + "/store/1000049406/apple-iphone-13-pro-white-512-gb/product/1000053176/variant/1000053191"' class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist selected"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/sweat-preto.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Sweat - Preto
                                    <small>Nike</small>
                                </h3>

                                <div class="store">
                                    <div class="price"><span>69</span></div>
                                </div>
                            </div>
                        </a>
                    
                        <a :href='"/" + flagSelected + "/store/1000049406/apple-iphone-13-pro-white-512-gb/product/1000053176/variant/1000053191"' class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/blusao-com-capuz.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Blusão com capuz Therma-FIT Repel Windrunner - Bege
                                    <small>Nike</small>
                                </h3>

                                <div class="store">
                                    <div class="price"><span>179</span></div>
                                </div>
                            </div>
                        </a>

                        <a :href='"/" + flagSelected + "/store/1000049406/apple-iphone-13-pro-white-512-gb/product/1000053176/variant/1000053191"' class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/sweat-preto.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Sweat - Preto
                                    <small>Nike</small>
                                </h3>

                                <div class="store">
                                    <div class="price"><span>69</span></div>
                                </div>
                            </div>
                        </a>
                    
                        <a :href='"/" + flagSelected + "/store/1000049406/apple-iphone-13-pro-white-512-gb/product/1000053176/variant/1000053191"' class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/blusao-com-capuz.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Blusão com capuz Therma-FIT Repel Windrunner - Bege
                                    <small>Nike</small>
                                </h3>

                                <div class="store">
                                    <div class="price"><span>179</span></div>
                                </div>
                            </div>
                        </a>

                        <a :href='"/" + flagSelected + "/store/1000049406/apple-iphone-13-pro-white-512-gb/product/1000053176/variant/1000053191"' class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/sweat-preto.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Sweat - Preto
                                    <small>Nike</small>
                                </h3>

                                <div class="store">
                                    <div class="price"><span>69</span></div>
                                </div>
                            </div>
                        </a>
                    
                        <a :href='"/" + flagSelected + "/store/1000049406/apple-iphone-13-pro-white-512-gb/product/1000053176/variant/1000053191"' class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/blusao-com-capuz.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Blusão com capuz Therma-FIT Repel Windrunner - Bege
                                    <small>Nike</small>
                                </h3>

                                <div class="store">
                                    <div class="price"><span>179</span></div>
                                </div>
                            </div>
                        </a>

                        <a :href='"/" + flagSelected + "/store/1000049406/apple-iphone-13-pro-white-512-gb/product/1000053176/variant/1000053191"' class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/sweat-preto.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Sweat - Preto
                                    <small>Nike</small>
                                </h3>

                                <div class="store">
                                    <div class="price"><span>69</span></div>
                                </div>
                            </div>
                        </a>
                    
                        <a :href='"/" + flagSelected + "/store/1000049406/apple-iphone-13-pro-white-512-gb/product/1000053176/variant/1000053191"' class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/blusao-com-capuz.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Blusão com capuz Therma-FIT Repel Windrunner - Bege
                                    <small>Nike</small>
                                </h3>

                                <div class="store">
                                    <div class="price"><span>179</span></div>
                                </div>
                            </div>
                        </a>

                        <a :href='"/" + flagSelected + "/store/1000049406/apple-iphone-13-pro-white-512-gb/product/1000053176/variant/1000053191"' class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/sweat-preto.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Sweat - Preto
                                    <small>Nike</small>
                                </h3>

                                <div class="store">
                                    <div class="price"><span>69</span></div>
                                </div>
                            </div>
                        </a>
                    
                        <a :href='"/" + flagSelected + "/store/1000049406/apple-iphone-13-pro-white-512-gb/product/1000053176/variant/1000053191"' class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/blusao-com-capuz.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Blusão com capuz Therma-FIT Repel Windrunner - Bege
                                    <small>Nike</small>
                                </h3>

                                <div class="store">
                                    <div class="price"><span>179</span></div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <!-- HALF BANNERS -->
    <section class="half-banners">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <router-link :to="{ name: 'ProductTemp' }">
                        <img src="~@/assets/images/banners/sport-zone-banner01.jpg">
                    </router-link>
                </div>

                <div class="col-12 col-sm-6">
                    <router-link :to="{ name: 'ProductTemp' }">
                        <img src="~@/assets/images/banners/sport-zone-banner02.jpg">
                    </router-link>
                </div>
                
                <div class="col-12 col-sm-6">
                    <router-link :to="{ name: 'ProductTemp' }">
                        <img src="~@/assets/images/banners/sport-zone-banner03.jpg">
                    </router-link>
                </div>

                <div class="col-12 col-sm-6">
                    <router-link :to="{ name: 'ProductTemp' }">
                        <img src="~@/assets/images/banners/sport-zone-banner04.jpg">
                    </router-link>
                </div>
            </div>
        </div>
    </section>
    

    <LegalPolicies />
  </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import LegalPolicies from "@/components/Essentials/LegalPolicies.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            Header,
            LegalPolicies,
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "search",

                openCategories: false,
                subcategories: {},

                filters: {
                    opened: false,

                    brands: {},

                    type: {},
                    typeMore: false,

                    countryRegion: {},
                    countryRegionMore: false,

                    rangePrice: 1738
                }
            };
        },

        async mounted() {
            this.languageSelected()
            this.goTop()
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            showFilters(){
                if ( this.filters.opened ){
                    this.filters.opened = false
                } else {
                    this.filters.opened = true
                }
            }

            // categories(action){
            //     var element = document.getElementById("brandCategories");

            //     if ( action === "open" ){
            //         this.openCategories = true

            //         element.classList.add("active");
            //     }
                
            //     if ( action === "close" ){
            //         this.openCategories = false

            //         element.classList.remove("active");
            //     }
            // }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            // document.title = this.getMyLanguage("seo", "title.home");
            // document.head.querySelector("meta[name=description]").content = this.getMyLanguage("seo", "title.description.home");
        },
    };
</script>

<style src="@/assets/scss/_filter_TEMP.scss" lang="scss" scoped />