<template>
    <div v-if="!globalPosition.active">
        <div class="modal modal-confirmation" v-if="modal.question && !modal.select">
            <div class="modal-cont">
                <!-- <div class="modal-close" @click='modal = false'></div> -->
                <div class="box box-confirmation">
                    <p class="looks">
                        {{getMyLanguage("ConfirmLocation","confirmation.looks")}}
                    </p>

                    <p class="location">
                        <span>Portugal</span>
                    </p>
                    
                    <div class="confirm">
                        <p>{{getMyLanguage("ConfirmLocation","confirm.question")}}</p>

                        <div class="btn-primary" @click="confirmation(true)">
                            {{getMyLanguage("ConfirmLocation","confirm.answer.yes")}}
                        </div>

                        <div class="btn-continue" @click="confirmation(true)">
                            {{getMyLanguage("ConfirmLocation","confirm.answer.continue")}}&nbsp;
                            <strong>"Brasil"</strong>
                        </div>
                    </div>

                    <div class="no-confirm">
                        <p class="or">
                            <span>{{getMyLanguage("ConfirmLocation","no-confirm.or")}}</span>
                        </p>

                        <div class="btn-secondary" @click="modal.question = false, modal.select = true">
                            {{getMyLanguage("ConfirmLocation","no-confirm.cta-select")}}
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- MODAL COUNTRY SELECTION -->
        <div class="modal modal-country-selection" v-if="!modal.question && modal.select">
            <div class="modal-cont">
                <!-- <div class="modal-close" @click='modal.select = false, errorsPost.withdraw = ""'></div> -->
                <div class="box">
                    <h3>{{getMyLanguage("ConfirmLocation","modal-country-selection.title")}}</h3>

                    <div class="list-country">
                        <div class="form-group-check">
                            <input type="checkbox" id="Alemanha" class="form-check">

                            <label for="Alemanha" class="form-check-label">
                                <span>Alemanha</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Argentina" class="form-check">

                            <label for="Argentina" class="form-check-label">
                                <span>Argentina</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Brasil" class="form-check">

                            <label for="Brasil" class="form-check-label">
                                <span>Brasil</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Espanha" class="form-check">

                            <label for="Espanha" class="form-check-label">
                                <span>Espanha</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Franca" class="form-check">

                            <label for="Franca" class="form-check-label">
                                <span>França</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Inglaterra" class="form-check">

                            <label for="Inglaterra" class="form-check-label">
                                <span>Inglaterra</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Italia" class="form-check">

                            <label for="Italia" class="form-check-label">
                                <span>Itália</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="kuwait" class="form-check">

                            <label for="kuwait" class="form-check-label">
                                <span>kuwait</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Portugal" class="form-check" checked>

                            <label for="Portugal" class="form-check-label">
                                <span>Portugal</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Russia" class="form-check">

                            <label for="Russia" class="form-check-label">
                                <span>Rússia</span>
                            </label>
                        </div>
                        
                        <div class="form-group-check">
                            <input type="checkbox" id="Alemanha2" class="form-check">

                            <label for="Alemanha2" class="form-check-label">
                                <span>Alemanha</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Argentina2" class="form-check">

                            <label for="Argentina2" class="form-check-label">
                                <span>Argentina</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Brasil2" class="form-check">

                            <label for="Brasil2" class="form-check-label">
                                <span>Brasil</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Espanha2" class="form-check">

                            <label for="Espanha2" class="form-check-label">
                                <span>Espanha</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Franca2" class="form-check">

                            <label for="Franca2" class="form-check-label">
                                <span>França</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Inglaterra2" class="form-check">

                            <label for="Inglaterra2" class="form-check-label">
                                <span>Inglaterra</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Italia2" class="form-check">

                            <label for="Italia2" class="form-check-label">
                                <span>Itália</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="kuwait2" class="form-check">

                            <label for="kuwait2" class="form-check-label">
                                <span>kuwait</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Portugal2" class="form-check">

                            <label for="Portugal2" class="form-check-label">
                                <span>Portugal</span>
                            </label>
                        </div>

                        <div class="form-group-check">
                            <input type="checkbox" id="Russia2" class="form-check">

                            <label for="Russia2" class="form-check-label">
                                <span>Rússia</span>
                            </label>
                        </div>
                    </div>

                    <div class="actions">
                        <div class="btn-primary" @click='confirmation(false)'>
                            {{getMyLanguage("ConfirmLocation","modal-country-selection.conclude")}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        data(){
            return {
                modal: {
                    question: true,
                    select: false
                },

                globalPosition: {
                    active: false,
                    latitude: "",
                    longitude: ""
                }
            }
        },

        async mounted() {
            this.getLocator()

            // TEMP
            this.globalPosition.active = localStorage.getItem("confirmLocation")
            console.log(this.globalPosition.active)
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            confirmation(value){
                if ( value ){
                    // ...

                    this.modal = false
                    
                    // TEMP
                    localStorage.setItem("confirmLocation",true)
                } else{
                    // ...

                    this.modal = false
                }


            },

            getLocator(){
                if (navigator.geolocation){
                    navigator.geolocation.getCurrentPosition(position => {
                        // this.globalPosition.active = true
                        this.globalPosition.latitude = position.coords.latitude
                        this.globalPosition.longitude = position.coords.longitude
                    })
                }
            }
        }
    }
</script>


<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';
    @import '@/assets/scss/_dneElements.scss';
    @import '@/assets/scss/_buttons.scss';
    @import '@/assets/scss/_modal.scss';
    @import '@/assets/scss/_search-filters.scss';

    .modal-confirmation{
        .modal-cont{
            max-width: 400px;
            border-radius: 12px;
            text-align: center;
            border: none;
            padding: 30px 0;

            .box-confirmation{
                .looks{
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                .location{
                    margin-bottom: 15px;

                    span{
                        display: inline-block;
                        font-size: 20px;
                        font-weight: bold;
                        color: $color-secondary;
                    }
                }

                .confirm{
                    background: #eee;
                    padding: 15px 15px 20px 15px;
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 600;
                    margin-bottom: 25px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    p{
                        margin-bottom: 10px;
                    }

                    .btn-primary{
                        height: 42px;
                        line-height: 42px;
                        margin-bottom: 20px;
                    }

                    .btn-continue{
                        display: inline-flex;
                        line-height: 32px;
                        font-size: 14px;
                        padding: 0 15px;
                        border: 2px solid #ddd;
                        border-radius: 16px;
                        transition: .3s;
                        cursor: pointer;

                        strong{
                            padding-left: 5px;
                            color: $color-secondary;
                        }

                        @media (min-width: 992px){
                            &:hover{
                                background: $color-text-tertiary;
                                padding: 0 20px;
                            }
                        }
                    }
                }

                .no-confirm{
                    .or{
                        position: relative;
                        font-size: 18px;
                        line-height: 18px;

                        &:after{
                            content: '';
                            display: block;
                            width: 100%;
                            border-bottom: 1px solid #ddd;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(1px)
                        }

                        span{
                            position: relative;
                            z-index: 10;
                            display: inline-block;
                            padding: 0 10px;
                            background: $color-text-tertiary;
                        }
                    }
                }
            }
        }
    }
</style>