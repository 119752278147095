<template>
  <div class="dne-market">
    <div id="dne"></div>
    <Header :dataSource="headerTemplate" />

    <!-- BREADCRUMB -->
    <div class="breadcrumb">
        <div class="container-fluid">
            <ul>
                <li>
                    <router-link :to="{ name: 'Home' }">
                        {{getMyLanguage("Breadcrumb","home")}}
                    </router-link>
                </li>

                <li>iPhone</li>
            </ul>
        </div>
    </div>

    
    <!-- CONTENT RESULTS -->
    <section class="content-results">
        <div class="container-fluid">
            <div class="row">
                <div class="sidebar">
                    <div class="open-filters" v-if="!filters.opened" @click='showFilters'></div>
                    <div class="close-filters" v-if="filters.opened" @click='showFilters'></div>

                    <div class="content-filters" :class="{active: filters.opened}">
                        <ul class="filters" id="result-filters">
                            <h2>{{getMyLanguage("Filter","sidebar.title")}}</h2>

                            <li class="search-text">
                                <h3>{{getMyLanguage("Filter","sidebar.search-text")}}</h3>
                                <p>Informática</p>
                            </li>

                            <li class="filter-type">
                                <h3>{{getMyLanguage("Filter","sidebar.brands")}}</h3>
                                <ul>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeAll" class="form-check" v-model="filters.brands.all">
                                            
                                            <label for="typeAll" class="form-check-label">
                                                <span>Todos as marcas</span>
                                                <small class="qtd">1279</small>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeProduct" class="form-check" v-model="filters.brands.product" checked>
                                            
                                            <label for="typeProduct" class="form-check-label">
                                                <span>Nike</span>
                                                <small class="qtd">698</small>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeService" class="form-check" v-model="filters.brands.service">
                                            
                                            <label for="typeService" class="form-check-label">
                                                <span>Adidas</span>
                                                <small class="qtd">126</small>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeClassified" class="form-check" v-model="filters.brands.classified">
                                            
                                            <label for="typeClassified" class="form-check-label">
                                                <span>Eko</span>
                                                <small class="qtd">455</small>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </li>


                            <li class="filter-type">
                                <h3>{{getMyLanguage("Filter","sidebar.type")}}</h3>

                                <ul>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeAll" class="form-check" v-model="filters.type.all" checked>
                                            
                                            <label for="typeAll" class="form-check-label">
                                                <span>Todos os tipos</span>
                                                <small class="qtd">1279</small>
                                            </label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeProduct" class="form-check" v-model="filters.type.product">
                                            
                                            <label for="typeProduct" class="form-check-label">
                                                <span>Produtos</span>
                                                <small class="qtd">698</small>
                                            </label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeService" class="form-check" v-model="filters.type.service">

                                            <label for="typeService" class="form-check-label">
                                                <span>Serviços</span>
                                                <small class="qtd">126</small>
                                            </label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="typeClassified" class="form-check" v-model="filters.type.classified">

                                            <label for="typeClassified" class="form-check-label">
                                                <span>Classificados</span>
                                                <small class="qtd">455</small>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li class="filter-region">
                                <h3>{{getMyLanguage("Filter","sidebar.country-region")}}</h3>

                                <ul>
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="countryRegionPortugal" class="form-check" v-model="filters.countryRegion.Portugal" checked>
                                            
                                            <label for="countryRegionPortugal" class="form-check-label">
                                                <span>Portugal</span>
                                                <small class="qtd">325</small>
                                            </label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="countryRegionEspanha" class="form-check" v-model="filters.countryRegion.Espanha">

                                            <label for="countryRegionEspanha" class="form-check-label">
                                                <span>Espanha</span>
                                                <small class="qtd">480</small>
                                            </label>
                                        </div>
                                    </li>
                                    
                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="countryRegionFranca" class="form-check" v-model="filters.countryRegion.Franca">
                                            
                                            <label for="countryRegionFranca" class="form-check-label">
                                                <span>França</span>
                                                <small class="qtd">382</small>
                                            </label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="countryRegionSuíca" class="form-check" v-model="filters.countryRegion.Suíca">
                                            
                                            <label for="countryRegionSuíca" class="form-check-label">
                                                <span>Suíca</span>
                                                <small class="qtd">265</small>
                                            </label>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="form-group-check">
                                            <input type="checkbox" id="countryRegionItalia" class="form-check" v-model="filters.countryRegion.Italia">
                                            
                                            <label for="countryRegionItalia" class="form-check-label">
                                                <span>Itália</span>
                                                <small class="qtd">276</small>
                                            </label>
                                        </div>
                                    </li>


                                    <!-- MORE FILTER -->
                                    <div class="btn-more-filter" v-if="!filters.countryRegionMore" @click="filters.countryRegionMore = true"><span>ver mais</span></div>

                                    <div v-if="filters.countryRegionMore">
                                        <li>
                                            <div class="form-group-check">
                                                <input type="checkbox" id="countryRegionAlemanha" class="form-check" v-model="filters.countryRegion.Alemanha">
                                                
                                                <label for="countryRegionAlemanha" class="form-check-label">
                                                    <span>Alemanha</span>
                                                    <small class="qtd">526</small>
                                                </label>
                                            </div>
                                        </li>

                                        <li>
                                            <div class="form-group-check">
                                                <input type="checkbox" id="countryRegionLuxemburgo" class="form-check" v-model="filters.countryRegion.Luxemburgo">
                                                
                                                <label for="countryRegionLuxemburgo" class="form-check-label">
                                                    <span>Luxemburgo</span>
                                                    <small class="qtd">146</small>
                                                </label>
                                            </div>
                                        </li>
                                    </div>
                                </ul>
                            </li>

                            <li class="filter-price">
                                <h3>{{getMyLanguage("Filter","sidebar.price")}}</h3>
                                
                                <p class="price-min-max">
                                    <span>19</span>
                                    <span>1738</span>
                                </p>

                                <input type="range" step="1"
                                :min="19"
                                :max="1738"
                                v-model="filters.rangePrice"
                                @touchend="upSummary(dataPurchase.quantity)">

                                <p class="up-to">
                                    {{getMyLanguage("Filter","sidebar.price.until")}}&nbsp;
                                    {{filters.rangePrice}}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>


                <div class="results">
                    <!-- PRODUCTS -->
                    <h2 class="title-result">
                        <strong>{{getMyLanguage("Result","products-found.title")}}</strong>
                    </h2>

                    <div class="products">
                        <router-link :to="{ name: 'ProductTemp' }" class="item ad" data-aos="fade-up" data-aos-offset="-100">
                            <div class="ad">
                                {{getMyLanguage("Result","shelf.highlights.ad")}}
                            </div>

                            <div class="wishlist"></div>
                            
                            <div class="highlights">
                                <div class="discount">
                                    -11%
                                </div>

                                <div class="free-shipping">
                                    {{getMyLanguage("Result","shelf.highlights.free-shipping")}}
                                </div>
                            </div>

                            <div class="photo">
                                <img src="~@/assets/images/items/iphone-13.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    iPhone 14 Plus 6.7 125Gb Midnight
                                    <small>Apple</small>
                                </h3>

                                <div class="store">
                                    <div class="brand" title="Apple Store">
                                        <div><img src="~@/assets/images/brands/nike.svg"></div>
                                    </div>

                                    <div class="price">
                                        <small>Apple Store</small>
                                        <span>1659</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    
                        <router-link :to="{ name: 'ProductTemp' }" class="item ad" data-aos="fade-up" data-aos-offset="-100">
                            <div class="ad">{{getMyLanguage("Result","shelf.highlights.ad")}}</div>
                            
                            <div class="wishlist"></div>

                            <div class="highlights">
                                <div class="discount">
                                    -11%
                                </div>
                            </div>

                            <div class="photo">
                                <img src="~@/assets/images/items/iphone-13.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    iPhone 13 recondicionado - Lumière Stellaire - 128 GB
                                    <small>Worten</small>
                                </h3>

                                <div class="store">
                                    <div class="price"><span>989</span></div>
                                </div>
                            </div>
                        </router-link>
                    
                        <router-link :to="{ name: 'ProductTemp' }" class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist selected"></div>

                            <div class="highlights">
                                <div class="discount">
                                    -11%
                                </div>

                                <div class="free-shipping">
                                    {{getMyLanguage("Result","shelf.highlights.free-shipping")}}
                                </div>
                            </div>

                            <div class="photo">
                                <img src="~@/assets/images/items/casaco-inverno-tommy-jeans.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Casaco de inverno <small>Tommy Jeans</small>
                                </h3>

                                <div class="store">
                                    <!-- <div class="brand" title="Apple Store">
                                        <div><img src="~@/assets/images/brands/nike.svg"></div>
                                    </div> -->

                                    <div class="price">
                                        <small>Sport Zone</small>
                                        <span>298</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    
                        <router-link :to="{ name: 'ProductTemp' }" class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="highlights">
                                <div class="discount">
                                    -11%
                                </div>
                            </div>

                            <div class="photo">
                                <img src="~@/assets/images/items/sweat-preto.jpg">
                            </div>

                            <div class="details">
                                <div class="store-name">
                                    Sport Zone
                                </div>

                                <h3 class="name">
                                    Sweat - Preto
                                    <small>Nike</small>
                                </h3>

                                <div class="store">
                                    <div class="price">
                                        <span>69</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    
                        <router-link :to="{ name: 'ProductTemp' }" class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="highlights">
                                <div class="free-shipping">
                                    {{getMyLanguage("Result","shelf.highlights.free-shipping")}}
                                </div>
                            </div>

                            <div class="photo">
                                <img src="~@/assets/images/items/blusao-com-capuz.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Blusão com capuz Therma-FIT Repel Windrunner - Bege
                                    <small>Nike</small>
                                </h3>

                                <div class="store">
                                    <div class="price">
                                        <span>179</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    
                        <router-link :to="{ name: 'ProductTemp' }" class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/iphone-13.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    iPhone 13 - Lumière Stellaire - 128 GB
                                    <small>Apple Store</small>
                                </h3>

                                <div class="store">
                                    <div class="price">
                                        <span>1138</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    
                        <router-link :to="{ name: 'ProductTemp' }" class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/pendente-struve.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Pendente Struve - Preto e dourado
                                    <small>Police</small>
                                </h3>

                                <div class="store">
                                    <div class="price">
                                        <span>98</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    
                        <router-link :to="{ name: 'ProductTemp' }" class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/brincos-cambria.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Brincos Cambria - Prateado
                                    <small>Police</small>
                                </h3>

                                <div class="store">
                                    <div class="price">
                                        <span>19</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    
                        <router-link :to="{ name: 'ProductTemp' }" class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/mochila-couro.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Mochila em couro - Castanho-escuro - Firenze Artegiani
                                    <small>Winter Iconic Bags</small>
                                </h3>

                                <div class="store">
                                    <div class="price">
                                        <span>149</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    
                        <router-link :to="{ name: 'ProductTemp' }" class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="photo">
                                <img src="~@/assets/images/items/relogio-feel.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Relógio conectado Feel - Compatível com Android e iOS
                                    <small>Eko</small>
                                </h3>

                                <div class="store">
                                    <div class="price">
                                        <span>59</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>

                        <router-link :to="{ name: 'ProductTemp' }" class="item to-order" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <div class="highlights">
                                <div class="to-order">Sob encomenda</div>
                            </div>

                            <div class="photo">
                                <img src="~@/assets/images/items/blusao-com-capuz.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    Blusão com capuz Therma-FIT Repel Windrunner - Bege
                                    <small>Nike</small>
                                </h3>

                                <div class="store">
                                    <div class="price">
                                        <span>179</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    
                        <router-link :to="{ name: 'ProductTemp' }" class="item unavailable" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>
                            
                            <div class="highlights">
                                <div class="unavailable">
                                    {{getMyLanguage("Result","shelf.highlights.unavailable")}}
                                </div>
                            </div>

                            <div class="photo">
                                <img src="~@/assets/images/items/iphone-13.jpg">
                            </div>

                            <div class="details">
                                <h3 class="name">
                                    iPhone 13 - Lumière Stellaire - 128 GB
                                    <small>Apple Store</small>
                                </h3>

                                <div class="store">
                                    <div class="price">
                                        <span>1138</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>


                    <!-- SERVICES -->
                    <h2 class="title-result"><strong>{{getMyLanguage("Result","services-found.title")}}</strong></h2>

                    <div class="services">
                        <div class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <router-link :to="{ name: 'Service' }">
                                <div class="photo">
                                    <img src="~@/assets/images/items/servico-exemplo.jpg">
                                </div>
                                
                                <div class="details">
                                    <h3 class="name">Formatação</h3>
                                    <div class="short">Computador, notebook, tablet android e ios e manutenção em geral.</div>
                                    <div class="price">
                                        <span>80</span>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        
                        <div class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist selected"></div>

                            <router-link :to="{ name: 'Service' }">
                                <div class="photo">
                                    <img src="~@/assets/images/items/servico-comtabilista.jpg">
                                </div>

                                <div class="details">
                                    <h3 class="name">Contabilista</h3>
                                    <div class="short">Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde quisquam neque.</div>
                                    <div class="price">
                                        <span>228</span>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        
                        <div class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist selected"></div>

                            <router-link :to="{ name: 'Service' }">
                                <div class="photo">
                                    <img src="~@/assets/images/items/servico-exemplo.jpg">
                                </div>
                                
                                <div class="details">
                                    <h3 class="name">Formatação</h3>
                                    <div class="short">Computador, notebook, tablet android e ios e manutenção em geral.</div>
                                    <div class="price">
                                        <span>80</span>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        
                        <div class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <router-link :to="{ name: 'Service' }">
                                <div class="photo">
                                    <img src="~@/assets/images/items/servico-comtabilista.jpg">
                                </div>

                                <div class="details">
                                    <h3 class="name">Contabilista</h3>
                                    <div class="short">Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde quisquam neque.</div>
                                    <div class="price">
                                        <span>228</span>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        
                        <div class="item" data-aos="fade-up" data-aos-offset="-100">
                            <div class="wishlist"></div>

                            <router-link :to="{ name: 'Service' }">
                                <div class="photo">
                                    <img src="~@/assets/images/items/servico-exemplo.jpg">
                                </div>
                                
                                <div class="details">
                                    <h3 class="name">Formatação</h3>
                                    <div class="short">Computador, notebook, tablet android e ios e manutenção em geral.</div>
                                    <div class="price">
                                        <span>80</span>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>



                    <!-- CLASSIFIED -->
                    <h2 class="title-result">
                        <strong>{{getMyLanguage("Result","classifieds-found.title")}}</strong>
                    </h2>

                    <div class="classified">
                        <div class="main">
                            <router-link :to="{ name: 'ProductTemp' }" class="item" data-aos="fade-up" data-aos-offset="-100">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-lg-7 col-xl-8 cont">
                                        <h3 class="name">Manutenção de computadores</h3>
                                        <p class="short">Computador, notebook, tablet e telemóvel.</p>

                                        <div class="btn-more"></div>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-5 col-xl-4 photo">
                                        <img src="~@/assets/images/banners/informatica-main.jpg">
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="row classifieds">
                            <div class="col-12 col-sm-6 col-lg-4 item">
                                <router-link :to="{ name: 'ProductTemp' }" data-aos="fade-up" data-aos-offset="-100">
                                    <div class="photo">
                                        <img src="~@/assets/images/banners/informatica-01.jpg">
                                    </div>
                                </router-link>
                            </div>

                            <div class="col-12 col-sm-6 col-lg-4 item">
                                <router-link :to="{ name: 'ProductTemp' }" data-aos="fade-up" data-aos-offset="-100">
                                    <div class="photo">
                                        <img src="~@/assets/images/banners/informatica-02.jpg">
                                    </div>
                                </router-link>
                            </div>
                            
                            <div class="col-12 col-sm-6 col-lg-4 item">
                                <router-link :to="{ name: 'ProductTemp' }" data-aos="fade-up" data-aos-offset="-100">
                                    <div class="photo">
                                        <img src="~@/assets/images/banners/informatica-03.jpg">
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    

    <LegalPolicies />
  </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import LegalPolicies from "@/components/Essentials/LegalPolicies.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            Header,
            LegalPolicies,
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "search",

                openCategories: false,

                filters: {
                    opened: false,

                    brands: {},

                    type: {},
                    typeMore: false,

                    countryRegion: {},
                    countryRegionMore: false,

                    rangePrice: 1738
                }
            };
        },

        async mounted() {
            this.languageSelected()
            this.goTop()
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            showFilters(){
                if ( this.filters.opened ){
                    this.filters.opened = false
                } else {
                    this.filters.opened = true
                }
            }

            // categories(action){
            //     var element = document.getElementById("result-filters");

            //     if ( action === "open" ){
            //         this.openCategories = true

            //         element.classList.add("active");
            //     }
                
            //     if ( action === "close" ){
            //         this.openCategories = false

            //         element.classList.remove("active");
            //     }
            // }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            // document.title = this.getMyLanguage("seo", "title.home");
            // document.head.querySelector("meta[name=description]").content = this.getMyLanguage("seo", "title.description.home");
        },
    };
</script>

<style src="@/assets/scss/_filter_TEMP.scss" lang="scss" scoped />