<template>
    <section class="half-banners" v-if="dataSource">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-6" v-for="(halfBanners, index) in dataSource" :key="index">
                    <a :href="halfBanners.link" :target="halfBanners.target">
                        <img :src="`/images/banners/${halfBanners.image}`" :alt="halfbanners.title">
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    export default{
        props: {
            dataSource: {}
        }
    }
</script>

<style lang="scss" scoped>
    .half-banners{
        .col-12{
            @media (max-width: 576px){
                margin-bottom: 1rem;
            }

            @media (min-width: 576px){
                margin-bottom: 2rem;
            }
        }
        img{
            width: 100%;
        }
    }
</style>