<template>
    <div class="video-home">
        <button id="playbutton"></button>
        <video id="dneVideo" class="bg-video" autoplay="autoplay" loop="true" muted defaultmuted playsinline>
            <source src="/home/home-dne-market.mp4" type="video/mp4">
            <!-- <source src="@/assets/images/home/video02.webm" type="video/webm"> -->
        </video>
    </div>
</template>

<script>
    export default {
        async mounted() {
            this.videoHome()
        },

        methods: {
            videoHome(){
                const video = document.getElementById("dneVideo");
                video.playbackRate = 0.75;

                const playButton = document.getElementById("playbutton");

                playButton.addEventListener("click", handlePlayButton, false);
                playVideo();

                async function playVideo() {
                try {
                    await video.play();
                    playButton.className = "playing";
                } catch(err) {
                    playButton.className = "";
                }
                }

                function handlePlayButton() {
                if (video.paused) {
                    playVideo();
                } else {
                    video.pause();
                    playButton.className = "";
                }
                }
            },
        }
    }
</script>


<style lang="scss" scoped>
    #playbutton{
        display: none;
    }
    
    .video-home{
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background: #1f3561;
        
        @media (max-width: 576px){
            height: 300px;
        }
        
        @media (min-width: 576px) and (max-width: 1200px){
            height: 350px;
        }

        @media (min-width: 1200px){
            height: 400px;
        }

        .bg-video{
            display: block;
            min-width: 100%;
            min-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        
            @media (min-width: 992px){
                width: 100%;
            }
        }
    }
</style>