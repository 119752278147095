<template>
    <li>
        <!-- <div class="hasChild" v-if="category.categories.length > 0">{{ category.name }}</div>
        <a :href="category.slug" v-else>{{ category.name }}</a> -->
        
        <a :href="`/${category.slug}`" :class="{active: category.reference == $route.params.category_reference}">{{ category.name }}</a>

        <ul v-if="category.categories && category.categories.length">
            <CategoryItem
                v-for="subCategory in category.categories"
                :key="subCategory.id"
                :category="subCategory"
            />
        </ul>
    </li>
</template>

<script>
    export default {
        name: 'CategoryItem',
        props: {
            category: {
                type: Object,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_dneElements.scss';

    .categories{
        > li{
            > a{
                font-family: $font-secondary-bold;
                font-size: 16px;
                line-height: 18px;
                color: $color-primary;
                border-bottom: 2px solid #eee;
                padding: 8px 0;
                cursor: pointer;

                &.active{
                    color: $color-secondary;
                }

                &.hasChild{
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    
                    &:after{
                        content: '';
                        display: block;
                        width: 10px;
                        height: 18px;
                        background: url('~@/assets/images/icons/arrow-subcategories.svg') no-repeat center;
                        background-size: 100% auto;
                    }

                    &.active{
                        color: $color-secondary;

                        &:after{
                            transform: rotate(180deg);
                        }
                    }
                }

                @media (min-width: 992px){
                    padding-right: 3px;

                    &:hover{
                        padding: 8px 0 8px 3px;
                    }
                }
            }


            > ul{
                padding: 5px 0;
                position: relative;
                margin-bottom: 20px;
                animation: subcategories .6s alternate;

                li{
                    a{
                        display: flex;
                        font-family: $font-primary-regular;  
                        font-size: 14px;
                        line-height: 18px;
                        color: #777;
                        border-bottom: none;
                        padding: 7px;
                        margin-bottom: 0;

                        &:before{
                            content: '> ';
                            font-size: 10px;
                            margin-right: 5px;
                            transition: .3s;
                        }

                        &.active{
                            color: $color-secondary;
                        }

                        @media (min-width: 992px){
                            padding: 7px 5px 7px 7px;
                            color: $color-primary;

                            &:hover{
                                &:before{
                                    margin-right: 7px;
                                }
                            }
                        }
                    }

                    // > ul {
                    //     display: none !important;
                    // }

                    > ul{
                        padding-left: 7px;
                    }
                }
            }

            @keyframes subcategories {
                0% {top: -20px; opacity: 0; margin-bottom: 0;}
                100% {top: 0; opacity: 1; margin-bottom: 20px;}
            }
        }
    }
</style>
