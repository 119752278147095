<template>
    <section class="benefits">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6 col-lg-3">
                    <div class="benefit">
                        <img src="~@/assets/images/icons/lnk-menu-distribution.svg">

                        <p>
                            <strong v-html='getMyLanguage("Benefits","benefit01.title")'></strong>
                            <small v-html='getMyLanguage("Benefits","benefit01.msg")'></small>
                        </p>
                    </div>
                </div>

                <div class="col-6 col-lg-3">
                    <div class="benefit">
                        <img src="~@/assets/images/icons/lnk-menu-distribution.svg">

                        <p>
                            <strong v-html='getMyLanguage("Benefits","benefit02.title")'></strong>
                            <small v-html='getMyLanguage("Benefits","benefit02.msg")'></small>
                        </p>
                    </div>
                </div>

                <div class="col-6 col-lg-3">
                    <div class="benefit">
                        <img src="~@/assets/images/icons/lnk-menu-distribution.svg">

                        <p>
                            <strong v-html='getMyLanguage("Benefits","benefit03.title")'></strong>
                            <small v-html='getMyLanguage("Benefits","benefit03.msg")'></small>
                        </p>
                    </div>
                </div>

                <div class="col-6 col-lg-3">
                    <div class="benefit">
                        <img src="~@/assets/images/icons/lnk-menu-distribution.svg">
                        
                        <p>
                            <strong v-html='getMyLanguage("Benefits","benefit04.title")'></strong>
                            <small v-html='getMyLanguage("Benefits","benefit04.msg")'></small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        }
    }
</script>


<style>
    .benefits .col-6 .benefit p small span{font-weight: 600; color: #4aae57;}
</style>

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';

    .benefits{
        display: flex;
        justify-content: center;
        background: #eee;
        padding: 15px 0;

        .col-6{
            padding: 15px;

            @media (max-width: 992px){

                &:nth-of-type(1){
                    border-right: 1px solid #ddd;
                    border-bottom: 1px solid #ddd;
                }
                
                &:nth-of-type(2){
                    border-bottom: 1px solid #ddd;
                }

                &:nth-of-type(3){
                    border-right: 1px solid #ddd;
                }
            }
        }

        .benefit{
            display: flex;
            align-items: flex-start;

            @media (min-width: 992px){
                margin-right: 30px;

                &:last-child{
                    margin-right: 0;
                }
            }

            img{
                width: 35px;
                height: auto;
                margin-right: 10px;

                @media (max-width: 576px){
                    display: none;
                }
            }

            p{
                margin: 0;

                @media (max-width: 576px){
                    font-size: 12px;
                    line-height: 16px;
                }
                
                @media (min-width: 576px){
                    font-size: 14px;
                    line-height: 18px;
                }

                strong{
                    display: block;
                    color: $color-primary;
                    margin-bottom: 5px;
                }
                
                small{
                    display: block;
                    font-size: 13px;
                }
            }
        }
    }
</style>