import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import Toaster from '@meforma/vue-toaster'
import VueMask from '@devindex/vue-mask'
import AOS from 'aos'
import 'aos/dist/aos.css'
// import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf"
import VueSocialSharing from 'vue-social-sharing'

import moment from 'moment-timezone'
moment.tz.setDefault('Europe/Lisbon')

createApp(App)
.use(store)
.use(router)
.use(VueCookies)
.use(Toaster)
.use(VueMask)
// .use(Vue3SimpleHtml2pdf)
.use(VueSocialSharing)
.mount('#app')
AOS.init()