<template>
    <div class="banner-slider">
        <Carousel :wrap-around="true" :autoplay="5000" :transition="1000" class="pagination">
            <Slide v-for="(banners, index) in dataSource" :key="index">
                <div class="carousel__item">
                    <a :href="banners.link" :target="banners.target">
                        <img :src="`/images/banners/${banners.image}`" :alt="banners.title">
                    </a>
                </div>
            </Slide>

            <template #addons>
                <Navigation />
                <Pagination />
            </template>
        </Carousel>
    </div>
</template>


<script>
    import { defineComponent } from 'vue'
    import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
    

    export default defineComponent({
        props: {
            dataSource: {}
        },

        name: 'Basic',

        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation
        }
    })
</script>

<style src="@/assets/scss/_carousel.scss" lang="scss" />

<style lang="scss" scoped>
    .banner-slider{
        margin-bottom: 40px;

        .carousel{
            .carousel__item{
                width: 100%;
                
                img{
                    width: 100%;
                }
            }   
        }
    }
</style>