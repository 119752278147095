<template>
      <footer class="footer">
         <div class="container-fluid">
            <div class="row">
               <div class="col-12 col-sm-4 company">
                  <p v-html='getMyLanguage("Footer","company")'></p>
               </div>

               <div class="col-12 col-sm-4 social">
                  <div class="social-cont">
                     <div class="links">
                        <a href="https://www.facebook.com/dneplatform" class="facebook" target="_blank"></a>
                        <a href="https://www.instagram.com/dneplatform/" class="instagram" target="_blank"></a>
                        <a href="https://www.linkedin.com/company/dneplatform/about/" class="linkedin" target="_blank"></a>
                        <a href="https://twitter.com/dneplatform" class="twitter" target="_blank"></a>
                        <a href="https://www.youtube.com/@dneplatform" class="youtube" target="_blank"></a>
                     </div>

                     <img src="@/assets/images/header/logo-dne.svg" alt="DNE Digital New Economy" class="logo">
                  </div>
               </div>
               
               <div class="col-12 col-sm-4 copyright">
                  <p v-html='getMyLanguage("Footer","copyright")'></p>
               </div>
            </div>
         </div>
      </footer>
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
        }
    }
</script>

<style src="@/assets/scss/_footer.scss" lang="scss" scoped />