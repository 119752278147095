<template>
    <section class="brands">
        <!-- <h2 class="title-secondary" data-aos="fade-up" data-aos-offset="-100">{{dataSource.placeholder_name}}</h2> -->
        <h2 class="title-secondary" data-aos="fade-up" data-aos-offset="-100">
            {{getMyLanguage("Brands","brands.title")}}
        </h2>

        <div class="container-fluid">
            <!-- <a :href="brands.slug" class="brand" data-aos="zoom-in" data-aos-offset="-100" v-for="(brands, index) in dataSource.data" :key="index"> -->
            
            <a class="brand" data-aos="zoom-in" data-aos-offset="-100" :data-aos-delay='(index * 50)'
            :href="`/${flagSelected}/brand/1000053175/warner-bros-games`"
            v-for="(brands, index) in dataSource.data"
            :key="index">

                <figure>
                    <!-- <img :src="brands.attachment" :alt="brands.name"> -->
                    <img :src="`/images/brands/${brands.attachment}`" :alt="brands.name">
                </figure>
            </a>
        </div>
    </section>
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        props: {
            dataSource: {}
        },

        data (){
            return {
                flagSelected: "en",
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>



<style lang="scss" scoped>
    .brands{
        .container-fluid{
            display: flex;
            flex-wrap: wrap;

            @media (max-width: 1200px){
                padding-left: 10px;
                padding-right: 10px;
            }

            @media (min-width: 1200px){
                padding-left: 50px;
                padding-right: 50px;
            }
        }

        a{
            display: flex;
            justify-content: center;
            align-items: center;
            background: #eee;
            margin: 5px;
            
            @media (max-width: 576px){
                width: calc(25% - 10px);
                padding: 15px;

                &:nth-of-type(13), &:nth-of-type(14), &:nth-of-type(15), &:nth-of-type(16){
                    display: none;
                }
            }
            
            @media (min-width: 576px) and (max-width: 992px){
                width: calc(16.6666% - 10px);
                padding: 20px;

                &:nth-of-type(13), &:nth-of-type(14), &:nth-of-type(15), &:nth-of-type(16){
                    display: none;
                }
            }
            
            @media (min-width: 992px) and (max-width: 1600px){
                width: calc(12.5% - 10px);
                padding: 25px;
            }
            
            @media (min-width: 1600px){
                width: calc(6.25% - 10px);
                padding: 15px;
            }

            figure{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;

                img{
                    opacity: .8;
                }
            }

            @media (min-width: 992px){
                figure{
                    img{
                        opacity: .7;
                        transition: .6s;
                    }
                }

                &:hover{
                    figure{
                        img{
                            opacity: 1;
                            transform: scale(1.015);
                        }
                    }
                }
            }
        }
    }
</style>