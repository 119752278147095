<template>
<div class="main">
    <router-view/>

    <Footer />
    <CookieConsent />
</div>

</template>

<script>
    import langsList from "@/services/listLanguages.js";
    import Footer from '@/components/Essentials/Footer.vue';
    import CookieConsent from '@/components/Essentials/CookieConsent.vue';

    // import bus from '@/eventBus';
    
    import apiPlatform from '@/config/apiPlatform.js'
    import apiPay from '@/config/apiPay.js'

    export default {
        components: {
            Footer,
            CookieConsent
        },

        data () {
            return {
                dataLanguages: [],
                dataCountries: [],
                dataCurrencies: [],
                
                dataPhaseCurrent: [],
                flagSelected: 'en',
                flagImg: '51021d86-49e9-4beb-804b-dfadf7a51300',

                statusLogin: {
                    logged: false
                },

                loginErrors: ""
            }
        },

        beforeMount(){
            this.loginStatus();
            this.flagUrl()

            if ( !this.$cookies.get("idCart") ){
                this.$cookies.set("idCart","00000000-0000-0000-0000-000000000000")  
            }

            // temp
            // this.$cookies.set("idCart","7e587443-1cc2-4cd0-ab36-662e7c227328")
        },

        async mounted(){
            // LANGUAGES
            const resumeLanguages = await apiPlatform.get('api/v1/platform/language/dne_market')
            this.dataLanguages = resumeLanguages.data
            this.$store.commit('allLanguages',this.dataLanguages)

            this.flagImg = this.dataLanguages.filter(lang => lang.code === this.flagSelected)[0].flag
            this.$store.commit('preLanguageFlag',this.flagImg)

            localStorage.setItem("Language",this.languagesList(this.flagSelected))


            // COUNTRIES
            const resumeCountries = await apiPlatform.get('api/v1/platform/country')
            this.dataCountries = resumeCountries.data
            this.$store.commit("allCountries",this.dataCountries)

            // GEOLOCATION SHIP
            if ( !localStorage.getItem("shippingCountry") ){
                localStorage.setItem("shippingCountry","Portugal")
                localStorage.setItem("IsoCode","PT")
                localStorage.setItem("shippingFlag","58bfc699-7e06-4362-3445-c598e9232900")
            }

            // CURRENCIES
            const resumeCurrencies = await apiPay.get('api/v1/pay/instrument/prices?trade_asset_short=DNE&quote_asset_type=fiat')
            this.dataCurrencies = resumeCurrencies.data
            this.$store.commit("allCurrencies",this.dataCurrencies)

            if ( !localStorage.getItem("instrumentTicker") ){
                localStorage.setItem("instrumentTicker","DNEEUR")
                localStorage.setItem("instrumentTickerShort","EUR")
            }
        },

        methods: {
            languagesList(code){
                return langsList(code,code)
            },

            loginStatus(){
                const tokenValue = localStorage.getItem('accessToken')

                if ( tokenValue != undefined || tokenValue != null ){
                    var base64Url = localStorage.getItem("accessToken").split('.')[1]
                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
                    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    }).join(''));
    
                    this.token = JSON.parse(jsonPayload)

                    const currentTimestamp = Math.floor(Date.now() / 1000);
                    if (this.token.exp && this.token.exp > currentTimestamp) {
                        apiPlatform.get('/api/v1/platform/account/profile/')
                        .then(response => {
                            this.$store.commit('profile',response.data)
                            this.$store.commit('loginStatus', true)

                            // this.$cookies.set("idCart",id_card_do_login)
                        })
                        .catch(error => {
                            let errorsLogin = JSON.stringify(error.response.status)
                            this.loginErrors = errorsLogin

                            // if ( errorsLogin == 401 || errorsLogin == 403 ){
                            //     bus.emit('open-login',"401");
                            // }
                        });
                    } else {
                        console.log("Expired token");
                        
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem("storeReference")
                        localStorage.removeItem("storeName")
                        localStorage.removeItem("isMerchant")
                        this.statusUserLogin.logged = false
                        this.$store.commit('loginStatus', false)
                    }
                }

                this.statusLogin.logged = false
                this.$store.commit('loginStatus', false)
            },

            flagUrl(){
                let code = String(window.location.pathname).split("/")[1];
                let flag = '51021d86-49e9-4beb-804b-dfadf7a51300'
                let language = "English"

                if ( !code ) {
                    code = 'en',
                    localStorage.setItem('codeLanguage',code);
                    localStorage.setItem('flagLanguage',flag);
                    this.$store.commit('preLanguageFlag',flag)
                } else{
                    this.flagSelected = code
                    localStorage.setItem('codeLanguage',code);
                    this.$store.commit('preLanguage',code)
                    this.$store.commit('preLanguageName',language)
                }

                if (this.flagSelected === "ar"){
                    document.body.classList.add('ar');
                }
            }
        }
    }
</script>

<style src="@/assets/scss/_custom-app.scss" lang="scss" />