<template>
    <section class="top10">
        <div class="container-fluid">
            <h2 class="title-secondary" data-aos="fade-up" data-aos-offset="-100">{{getMyLanguage("Top10","top-10.title")}}</h2>

            <div class="scroll-horizontal scrollHorizontalMouse" ref="scrollContainer" @mousedown="startDrag">
                <div class="cont" ref="scrollContent">
                    <a :href="category.slug" data-aos="zoom-in" data-aos-offset="-100" :data-aos-delay='(index * 50)' v-for="(category, index) in dataSource.data" :key="index">
                        <figure>
                            <img :src="`/images/top10/${category.image}`" :alt='getMyLanguage("Categories","menu." + category.name)' />    
                        </figure>
                        
                        <!-- <h4>{{getMyLanguage("Categories","menu." + category.name)}} </h4> -->
                        <h4>{{category.name}} </h4>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        props: {
            dataSource: {}
        },

        data (){
            return {
                isDragging: false,
                startX: 0,
                scrollLeft: 0,
            }
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            startDrag(event) {
                this.isDragging = true;
                this.startX = event.pageX - this.$refs.scrollContainer.offsetLeft;
                this.scrollLeft = this.$refs.scrollContainer.scrollLeft;

                document.addEventListener('mousemove', this.handleDrag);
                document.addEventListener('mouseup', this.stopDrag);
            },

            handleDrag(event) {
                if (!this.isDragging) return;
                event.preventDefault();

                const x = event.pageX - this.$refs.scrollContainer.offsetLeft;
                const walk = (x - this.startX) * 2;
                this.$refs.scrollContainer.scrollLeft = this.scrollLeft - walk;
            },

            stopDrag() {
                this.isDragging = false;
                document.removeEventListener('mousemove', this.handleDrag);
                document.removeEventListener('mouseup', this.stopDrag);
            }
        }
    }
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';
    @import '@/assets/scss/_dneElements.scss';
    @import '@/assets/scss/_buttons.scss';

    .top10{
        .container-fluid{
            .scroll-horizontal{
                @include scrollbars(16px, $color-secondary, #ddd);
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                }            
                &::-webkit-scrollbar-track {
                    border-radius: 8px;
                }
                
                overflow-y: hidden;
                overflow-x: auto;
                
                width: 100%;
        
                .cont{
                    display: flex;
                    flex-wrap: nowrap;
        
                    a{
                        display: block;
                        margin: 0 10px 10px 0;
                        cursor: pointer;
        
                        &:last-child{
                            margin-right: 0;
                        }
        
                        @media (max-width: 992px){
                            flex: 0 0 240px;
                        }
                        
                        @media (min-width: 992px){
                            flex: 0 0 300px;
                        }
            
                        figure{
                            position: relative;
                            overflow: hidden;

        
                            &:before{
                                content: '';
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                                z-index: 20;
                                background: rgba($color: #000, $alpha: 0.2);
                                transition: 1s;
                            }
        
                            img{
                                position: relative;
                                z-index: 10;
                                left: 50%;
                                transform: translateX(-50%);
                                max-width: initial;
                                transition: 4s;
                                
                                @media (max-width: 992px){
                                    width: 240px;
                                }
                                
                                @media (min-width: 992px){
                                    width: 300px;
                                }
                            }
        
                            @media (min-width: 992px){
                                &:hover{
                                    &:before{
                                        background: rgba($color: #000, $alpha: 0.6);
                                    }
                    
                                    img{
                                        transform: translateX(-50%) scale(1.1);
                                    }
                                }
                            }
                        }
                        
                        h4{
                            text-align: center;
                            font-family: $font-primary-semibold;
                            color: $color-text-primary;
                            padding-top: 10px;

                            @media (max-width: 992px){
                                font-size: 13px;
                                line-height: 17px;
                            }
                            
                            @media (min-width: 992px){
                                font-size: 15px;
                                line-height: 19px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>