<template>
    <section class="featured">
        <div class="container-fluid">
            <a :href="`/${flagSelected}/product/jogo-hogwarts-legacy-stardard-playstation-5/store/1000049406/product/1000053176`">
                <div class="row">
                    <div class="col-12 col-sm-6 col-xl-5 cont">
                        <h3>Rawdacious</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        <div class="btn-more"></div>
                    </div>

                    <div class="col-12 col-sm-6">
                        <figure>
                            <img src="~@/assets/images/banners/nike-featured.jpg">
                        </figure>
                    </div>
                </div>
            </a>
        </div>
    </section>
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        data (){
            return {
                flagSelected: "en",
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';
    @import '@/assets/scss/_dneElements.scss';
    @import '@/assets/scss/_buttons.scss';

    .featured{
        position: relative;

        .row{
            justify-content: flex-end;
            align-items: center;

            @media (max-width: 992px){
                padding-top: 15px;
                padding-bottom: 15px;
            }
            
            @media (min-width: 992px){
                padding-top: 30px;
                padding-bottom: 30px;
            }


            .cont{
                @media (max-width: 576px){
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    order: 2;
                    margin-top: 30px;
                }

                h3{
                    @media (max-width: 576px){
                        font-size: 26px;
                        line-height: 26px;
                        margin-bottom: 16px;
                    }
            
                    @media (min-width: 576px){
                        font-size: 40px;
                        line-height: 40px;
                        margin-bottom: 20px;
                    }
                }
            
                .btn-more{
                    margin-top: 1rem;
                }
            }
        }
        
        &:before{
            content: '';
            display: block;
            height: 100%;
            background: #eee;
            position: absolute;
            top: 0;
            left: 0;

            @media (max-width: 992px){
                width: 100%;
            }
            
            @media (min-width: 992px){
                width: 80%;
            }
        }


        img{
            width: 100%;
        }
    }
</style>