const $ = require('jquery')
window.$ = $

export default {
    data(){
        return {
            errorForm: {
                // companyProfile: {},
                // merchantProfile: {},
                // nationalityProfile: {},
                contactProfile01: {},
                // contactProfile02: {},
                // dockProfile: {},
                // acceptsInvite: {
                //     acceptTerms: false,
                //     acceptNotify: false,
                //     acceptGdpr: false
                // },

                company: {
                    legal_name: false,
                    fantasy_name: false,
                    type: false,
                    industry: false,
                    asset_id: false,
                    language_id: false,
                    tax_number: false,
                    country_id: false,
                    phone_number: false,
                    address: {
                        address_1: false,
                        number: false,
                        postal_code: false,
                        state: false,
                        city: false,
                    },
                    emailPhoneExists: false
                },

                partner: {
                    full_name: false,
                    email: false,
                    nationality_id: false,
                    type: false,
                    dob: false,
                    gender_id: false,
                    tax_number: false,
                    asset_id: false,
                    language_id: false,
                    country_id: false,
                    phone_number: false,
                    address: {
                        address_1: false,
                        number: false,
                        postal_code: false,
                        state: false,
                        city: false,
                    },

                    emailPhoneExists: false
                },

                store: {
                    name: false,
                    slug: false,
                },

                addAddress: {
                    country_id: false,
                    state: false,
                    city: false,
                    address_1: false,
                    number: false,
                    address_2: false,
                    postal_code: false
                },



                // seller: false,
                contact01: false,
                contact02: false,
                dock: false,
                accepts: false,

            },

            flowValidation: {
                load: false
            },
        }
    },

    methods: {
        
        // VALIDATE FORM
        validateForm(value){
            this.flowValidation.load = true
            this.flowSave.load = true

            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const year = today.getFullYear();

            const todayFormated = `${day}/${month}/${year}`;

            
            if ( value == "stp01" ){
                // if ( !this.dataMerchant.legal_name ){
                //     this.errorForm.company.legal_name = "empty"
                // } else {
                //     var companyLegalName = this.dataMerchant.legal_name
                //     companyLegalName = companyLegalName.trim().split(' ').length
                    
                //     if ( companyLegalName < 2 ){
                //         this.errorForm.company.legal_name = "incomplete"
                //     } else {
                //         this.errorForm.company.legal_name = false
                //     }
                // }

                console.log(this.errorForm.company)

                
                
                if ( !this.dataMerchant.legal_name ){ this.errorForm.company.legal_name = true } else { this.errorForm.company.legal_name = false }
                if ( !this.dataMerchant.fantasy_name ){ this.errorForm.company.fantasy_name = true } else { this.errorForm.company.fantasy_name = false }
                if ( !this.dataMerchant.type ){ this.errorForm.company.type = true } else { this.errorForm.company.type = false }
                if ( !this.dataMerchant.industry ){ this.errorForm.company.industry = true } else { this.errorForm.company.industry = false }
                if ( !this.dataMerchant.asset_id ){ this.errorForm.company.asset_id = true } else { this.errorForm.company.asset_id = false }
                if ( !this.dataMerchant.language_id ){ this.errorForm.company.language_id = true } else { this.errorForm.company.language_id = false }
                if ( !this.dataMerchant.tax_number ){ this.errorForm.company.tax_number = true } else { this.errorForm.company.tax_number = false }
                if ( !this.dataMerchant.email ){ this.errorForm.company.email = true } else { this.errorForm.company.email = false }
                if ( !this.validateEmail.company.regex ){ this.errorForm.company.email = true }
                if ( !this.dataMerchant.phone_number ){ this.errorForm.company.phone_number = true } else { this.errorForm.company.phone_number = false }
                if ( !this.validatePhone.company.regex ){ this.errorForm.company.phone_number = true }
                if ( !this.dataMerchant.address.address_1 ){ this.errorForm.company.address.address_1 = true } else { this.errorForm.company.address.address_1 = false }
                if ( !this.dataMerchant.address.number ){ this.errorForm.company.address.number = true } else { this.errorForm.company.address.number = false }
                if ( !this.dataMerchant.address.postal_code ){ this.errorForm.company.address.postal_code = true } else { this.errorForm.company.address.postal_code = false }
                if ( !this.dataMerchant.address.state ){ this.errorForm.company.address.state = true } else { this.errorForm.company.address.state = false }
                if ( !this.dataMerchant.address.city ){ this.errorForm.company.address.city = true } else { this.errorForm.company.address.city = false }
                
                if (
                    !this.errorForm.company.legal_name &&
                    !this.errorForm.company.fantasy_name &&
                    !this.errorForm.company.type &&
                    !this.errorForm.company.industry &&
                    !this.errorForm.company.asset_id &&
                    !this.errorForm.company.language_id &&
                    !this.errorForm.company.tax_number &&
                    !this.errorForm.company.email &&
                    !this.errorForm.company.phone_number &&
                    !this.errorForm.company.address.address_1 &&
                    !this.errorForm.company.address.number &&
                    !this.errorForm.company.address.postal_code &&
                    !this.errorForm.company.address.state &&
                    !this.errorForm.company.address.city
                ) {
                    this.saveStep("stp01")
                    this.flowSave.load = false
                    
                } else {
                    this.flowSave.load = false
                
                    setTimeout(() => {
                        this.scrollToFirstError();
                        this.$toast.error("Houve um erro")
                        this.flowValidation.load = false
                    }, 600);
                }
            }

            if ( value == "partner" ){

                if ( !this.modalPartner.partner.full_name ){ this.errorForm.partner.full_name = true } else { this.errorForm.partner.full_name = false }
                if ( !this.modalPartner.partner.language_id ){ this.errorForm.partner.language_id = true } else { this.errorForm.partner.language_id = false }
                if ( !this.modalPartner.partner.asset_id ){ this.errorForm.partner.asset_id = true } else { this.errorForm.partner.asset_id = false }
                if ( !this.modalPartner.partner.company_member_type_id || this.modalPartner.partner.company_member_type_id == "00000000-0000-0000-0000-000000000000" ){ this.errorForm.partner.company_member_type_id = true } else { this.errorForm.partner.company_member_type_id = false }
                if ( !this.modalPartner.partner.country_id ){ this.errorForm.partner.country_id = true } else { this.errorForm.partner.country_id = false }
                if ( !this.modalPartner.partner.dob || this.modalPartner.partner.dob == todayFormated ){ this.errorForm.partner.dob = true } else { this.errorForm.partner.dob = false }
                if ( !this.modalPartner.partner.gender_id ){ this.errorForm.partner.gender_id = true } else { this.errorForm.partner.gender_id = false }
                if ( !this.modalPartner.partner.email ){ this.errorForm.partner.email = true } else { this.errorForm.partner.email = false }
                if ( !this.modalPartner.partner.phone_number ){ this.errorForm.partner.phone_number = true } else { this.errorForm.partner.phone_number = false }
                if ( !this.modalPartner.partner.address.country_id ){ this.errorForm.partner.address.country_id = true } else { this.errorForm.partner.address.country_id = false }
                if ( !this.modalPartner.partner.address.state ){ this.errorForm.partner.address.state = true } else { this.errorForm.partner.address.state = false }
                if ( !this.modalPartner.partner.address.city ){ this.errorForm.partner.address.city = true } else { this.errorForm.partner.address.city = false }
                if ( !this.modalPartner.partner.address.postal_code ){ this.errorForm.partner.address.postal_code = true } else { this.errorForm.partner.address.postal_code = false }
                if ( !this.modalPartner.partner.address.address_1 ){ this.errorForm.partner.address.address_1 = true } else { this.errorForm.partner.address.address_1 = false }
                if ( !this.modalPartner.partner.address.number ){ this.errorForm.partner.address.number = true } else { this.errorForm.partner.address.number = false }


                if (
                    !this.errorForm.partner.full_name &&
                    !this.errorForm.partner.language_id &&
                    !this.errorForm.partner.asset_id &&
                    !this.errorForm.partner.company_member_type_id &&
                    !this.errorForm.partner.nationality_id &&
                    !this.errorForm.partner.dob &&
                    !this.errorForm.partner.gender_id &&
                    !this.errorForm.partner.email &&
                    !this.errorForm.partner.phone_number &&
                    !this.errorForm.partner.address.country_id &&
                    !this.errorForm.partner.address.state &&
                    !this.errorForm.partner.address.city &&
                    !this.errorForm.partner.address.postal_code &&
                    !this.errorForm.partner.address.address_1 &&
                    !this.errorForm.partner.address.number
                ){
                    this.savePartner()
                    this.flowStep.load = false

                } else {
                    this.flowStep.load = false
                    
                    setTimeout(() => {
                        this.scrollToFirstError("#modalPartner");
                        this.flowValidation.load = false
                    }, 600);
                }


                // if (
                //     this.modalPartner.type == "new" &&
                //     !this.errorForm.partner.full_name &&
                //     !this.errorForm.partner.language_id &&
                //     !this.errorForm.partner.asset_id &&
                //     !this.errorForm.partner.company_member_type_id &&
                //     !this.errorForm.partner.nationality_id &&
                //     !this.errorForm.partner.dob &&
                //     !this.errorForm.partner.gender_id &&
                //     !this.errorForm.partner.email &&
                //     !this.errorForm.partner.phone_number &&
                //     !this.errorForm.partner.address.country_id &&
                //     !this.errorForm.partner.address.state &&
                //     !this.errorForm.partner.address.city &&
                //     !this.errorForm.partner.address.postal_code &&
                //     !this.errorForm.partner.address.address_1 &&
                //     !this.errorForm.partner.address.number
                // ){
                //     this.savePartner()
                //     this.flowStep.load = false

                // } else if (
                //     this.modalPartner.type == "edt" && 
                //     !this.errorForm.partner.language_id &&
                //     !this.errorForm.partner.asset_id &&
                //     !this.errorForm.partner.company_member_type_id
                // ) {
                //     this.savePartner()
                //     this.flowStep.load = false
                // } else {
                //     this.flowStep.load = false

                //     console.log(this.errorForm.partner)
                    
                //     setTimeout(() => {
                //         this.scrollToFirstError("#modalPartner");
                //         this.$toast.error("Houve um erro")
                //         this.flowValidation.load = false
                //     }, 600);
                // }
            }
            
            if ( value == "stp02" ){
                // validações ....

                this.saveStep("stp02")
            }
            
            if ( value == "stp03" ){
                // validações ....

                this.goTop()

                setTimeout(() => {
                    this.flowStep.load = false
                    this.flowStep.statusStep03 = true
                }, 500);
            }
        },


        // validateNewAddress(){
        //     if ( !this.flowAddressAdd.address.country_id ){ this.errorForm.addAddress.country_id = true } else { this.errorForm.addAddress.country_id = false }
        //     if ( !this.flowAddressAdd.address.state ){ this.errorForm.addAddress.state = true } else { this.errorForm.addAddress.state = false }
        //     if ( !this.flowAddressAdd.address.city ){ this.errorForm.addAddress.city = true } else { this.errorForm.addAddress.city = false }
        //     if ( !this.flowAddressAdd.address.address_1 ){ this.errorForm.addAddress.address_1 = true } else { this.errorForm.addAddress.address_1 = false }
        //     if ( !this.flowAddressAdd.address.number ){ this.errorForm.addAddress.number = true } else { this.errorForm.addAddress.number = false }
        //     if ( !this.flowAddressAdd.address.address_2 ){ this.errorForm.addAddress.address_2 = true } else { this.errorForm.addAddress.address_2 = false }
        //     if ( !this.flowAddressAdd.address.postal_code ){ this.errorForm.addAddress.postal_code = true } else { this.errorForm.addAddress.postal_code = false }

        //     if ( !this.errorForm.addAddress.country_id && !this.errorForm.addAddress.state && !this.errorForm.addAddress.city && !this.errorForm.addAddress.address_1 && !this.errorForm.addAddress.number && !this.errorForm.addAddress.address_2 && !this.errorForm.addAddress.postal_code ){
        //         this.saveAddress()                
        //     }
        // },


        // SCROLL TO FIRST ERROR
        scrollToFirstError(modalSelector = null) {
            if (modalSelector) {
                const modal = document.querySelector(modalSelector);
                if (modal) {
                    const errorField = modal.querySelector('.error');
                    if (errorField) {
                        const modalContent = modal.querySelector('.modal-scroll');
                        if (modalContent) {
                            const modalContentPosition = modalContent.getBoundingClientRect().top;
                            const errorFieldPosition = errorField.getBoundingClientRect().top;
        
                            const scrollPosition = modalContent.scrollTop + (errorFieldPosition - modalContentPosition) - 50;
        
                            modalContent.scrollTo({ top: scrollPosition, behavior: 'smooth' });
                        }
                    }
                }
            } else {
                const errorField = document.querySelector('.error');
                if (errorField) {
                    const headerPage = document.querySelector('header');
                    const headerHeight = headerPage ? headerPage.offsetHeight : 0;
        
                    const errorFieldPosition = errorField.getBoundingClientRect().top;
                    const scrollPosition = window.scrollY + errorFieldPosition - headerHeight - 50;
        
                    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
                }
            }
        },
        


        // validationFormInvite(){

        //     // VALIDATION COMPLIANCE
        //     if ( this.dataMerchant.AccountType === "company_invite" ){

        //         // COMPANY NAME
        //         if ( this.dataMerchant.companyProfile.fullName != "" ){
        //             var companyName = this.dataMerchant.companyProfile.fullName
        //             companyName = companyName.trim().split(' ').length
                    
        //             if ( companyName < 2 ){
        //                 this.errorForm.companyProfile.fullName = true
        //             } else {
        //                 this.errorForm.companyProfile.fullName = false
        //             }
        //         } else {
        //             this.errorForm.companyProfile.fullName = true
        //         }


        //         // COMPANY EMAIL
        //         if ( this.dataMerchant.companyProfile.email === "" ){
        //             this.errorForm.companyProfile.email = true
        //         } else {
        //             this.errorForm.companyProfile.email = false
        //         }


        //         // COMPANY LANGUAGE
        //         if ( this.dataMerchant.companyProfile.languageId === "" ){
        //             this.errorForm.companyProfile.languageId = true
        //         } else {
        //             this.errorForm.companyProfile.languageId = false
        //         }


        //         // COMPANY COUNTRY
        //         if ( this.dataMerchant.companyProfile.countryId === "" ){
        //             this.errorForm.companyProfile.countryId = true
        //         } else {
        //             this.errorForm.companyProfile.countryId = false
        //         }


        //         // COMPANY PHONE
        //         if ( this.validatePhone.company.val === "" ){
        //             this.errorForm.companyProfile.phoneNumber = true
        //         } else if ( this.validatePhone.company.val.length < this.selectedCountryDDICompany.phoneMask.length ) {
        //             this.errorForm.companyProfile.phoneNumber = true
        //         } else {
        //             this.errorForm.companyProfile.phoneNumber = false
        //         }

        //         // COMPANY TAXNUMBER
        //         if ( this.dataMerchant.companyProfile.taxNumber ==="" ){
        //             this.errorForm.companyProfile.taxNumber = true
        //         } else {
        //             this.errorForm.companyProfile.taxNumber = false
        //         }


        //         // CKECK VALIDATION COMPLIANCE
        //         if ( this.errorForm.companyProfile.fullName || this.errorForm.companyProfile.email || this.errorForm.companyProfile.languageId || this.errorForm.companyProfile.countryId || this.errorForm.companyProfile.phoneNumber || this.errorForm.companyProfile.taxNumber ){
        //             window.location.href = "#!company"
        //             this.errorForm.company = true
        //         } else {
        //             this.errorForm.company = false
        //         }
        //     }



            
        //     // PERSON NAME
        //     if ( this.dataMerchant.fullName != "" ){
        //         var personName = this.dataMerchant.fullName
        //         personName = personName.trim().split(' ').length
                
        //         if ( personName < 2 ){
        //             this.errorForm.partnerProfile.fullName = true
        //         } else {
        //             this.errorForm.partnerProfile.fullName = false
        //         }
        //     } else {
        //         this.errorForm.partnerProfile.fullName = true
        //     }


        //     // EMAIL
        //     if ( this.dataMerchant.email === "" ){
        //         this.errorForm.partnerProfile.email = true
        //     } else {
        //         this.errorForm.partnerProfile.email = false
        //     }


        //     // LANGUAGE
        //     if ( this.dataMerchant.languageId === "" ){
        //         this.errorForm.partnerProfile.languageId = true
        //     } else {
        //         this.errorForm.partnerProfile.languageId = false
        //     }


        //     // COUNTRY
        //     if ( this.dataMerchant.countryId === "" ){
        //         this.errorForm.partnerProfile.countryId = true
        //     } else {
        //         this.errorForm.partnerProfile.countryId = false
        //     }


        //     // PHONE
        //     if ( this.validatePhone.person.val === "" ){
        //         this.errorForm.partnerProfile.phoneNumber = true
        //     } else if ( this.validatePhone.person.val.length < this.selectedCountryDDI.phoneMask.length ) {
        //         this.errorForm.partnerProfile.phoneNumber = true
        //     } else {
        //         this.errorForm.partnerProfile.phoneNumber = false
        //     }


        //     // ACCEPTS
        //     if ( !this.accept.terms ){
        //         this.errorForm.acceptsInvite.acceptTerms = true
        //     } else{
        //         this.errorForm.acceptsInvite.acceptTerms = false
        //     }
            
        //     if ( !this.accept.notify ){
        //         this.errorForm.acceptsInvite.acceptNotify = true
        //     } else{
        //         this.errorForm.acceptsInvite.acceptNotify = false
        //     }
            
        //     if ( !this.accept.gdpr ){
        //         this.errorForm.acceptsInvite.acceptGdpr = true
        //     } else{
        //         this.errorForm.acceptsInvite.acceptGdpr = false
        //     }

        //     if ( this.errorForm.acceptsInvite.acceptTerms || this.errorForm.acceptsInvite.acceptNotify || this.errorForm.acceptsInvite.acceptGdpr ){
        //         this.errorForm.accepts = true
        //     } else {
        //         this.errorForm.accepts = false
        //     }


        //     // CKECK VALIDATION COMPLIANCE
        //     if ( !this.errorForm.company && (this.errorForm.partnerProfile.fullName || this.errorForm.partnerProfile.email || this.errorForm.partnerProfile.languageId || this.errorForm.partnerProfile.countryId || this.errorForm.partnerProfile.phoneNumber) ){
        //         window.location.href = "#!person"
        //         this.errorForm.seller = true
        //     } else {
        //         this.errorForm.seller = false
        //     }


        //     // LAST CKECK
        //     if ( !this.errorForm.company && !this.errorForm.accepts && !this.errorForm.seller ){
        //         // this.saveStep()
        //     }
        // },
    }
}